/* eslint-disable react/sort-comp */
import {
    BodyText,
    Checkbox,
    Flex,
    Headline,
    IconButton,
    IconV2,
    Label,
    LayoutBox,
    Popover,
    Select,
} from '@bamboohr/fabric';
import { InfoCircle15x15, Question14x14 } from '@bamboohr/grim';
import c from 'classnames';
import { Component, createRef, Fragment } from 'react';
/* @startCleanup encore */
import {
    ifFeature,
} from '@utils/feature';
/* @endCleanup encore */

import { isEnabled } from 'FeatureToggle.util';

import '../state-withholding.react/state-withholding.styl';
import { AdditionalWithholding } from '../additional-withholding.react';
import { LinkedSelects } from '../linked-selects.react';
import { OptionalTextInput } from '../optional-text-input.react';
import { OptionalCheckbox } from '../optional-checkbox.react';
import { OptionalSelect } from '../optional-select.react';
import { OptionalUsdInput } from '../optional-usd-input/optional-usd-input';
import { SectionStyledBox } from '../section-styled-box';
import { SectionStyledHeader } from '../section-styled-header';

const feAccessLevelsQuickForms = isEnabled('PAY_INFO_TAB_ACCESS_LEVELS');

const OPTIONAL_FIELDS_MAP = {
    text: OptionalTextInput,
    checkbox: OptionalCheckbox,
    select: OptionalSelect,
    linkedSelects: LinkedSelects,
    usd: OptionalUsdInput,
};

export class IowaStateWithholding extends Component {
    constructor(props) {
        super(props);
        this.iconBtnRef = createRef();
        let filingStatus = null;
        const helpPopover = false;
        let ignore = false;
        let selectedFilingStatus = null;
        let stateAbbreviation = null;
        const activeLocation = props.selectedLocation ? props.locations.byId[props.selectedLocation] : null;

        if (activeLocation) {
            selectedFilingStatus = activeLocation.newFields.filingStatus ? activeLocation.newFields.filingStatus.find(status => status.selected === true) : null;
            filingStatus = selectedFilingStatus ? selectedFilingStatus.val : null;
            ignore = activeLocation.newFields.ignoreStatusExemptions ? activeLocation.newFields.ignoreStatusExemptions.checked : false;
            stateAbbreviation = activeLocation.state;
        }

        // eslint-disable-next-line react/state-in-constructor
        this.state = {
            filingStatus,
            helpPopover,
            ignore,
            selectedLocation: props.selectedLocation,
            stateAbbreviation
        };
    }

    render() {
        const { ignore, selectedLocation } = this.state;
        const {
            accessLevel,
            editPermission,
            isAdminUser,
            isPayrollAdminUser,
            locations,
            sectionNote,
            showWithholding,
            viewOnly,
        } = this.props;
        const activeLocation = locations.byId[selectedLocation];

        let optionalFields = null;
        if (activeLocation && activeLocation.newFields.optionalFields) {
            optionalFields = activeLocation.newFields.optionalFields.allIds.map((id) => {
                const field = activeLocation.newFields.optionalFields.byId[id];
                const FieldComponent = OPTIONAL_FIELDS_MAP[field.type];

                if (field.type === "usd" && field.data.label === "Total Allowances") { // *NOTE: Handle as an "Exemption" field.
                    return (
                        <FieldComponent
                            data={ field.data }
                            help={ field.help }
                            id={ id }
                            isExemption={ true }
                            key={ id }
                            viewOnly={ viewOnly || ignore }
                        />
                    );
                }

                return (
                    <FieldComponent
                        data={ field.data }
                        help={ field.help }
                        id={ id }
                        key={ id }
                        viewOnly={ viewOnly }
                    />
                );
            });
        }

        const fieldSetClasses = ifFeature('encore', '', c(
            { 'fab-FormSection StateTax': true },
            { 'fab-FormSection--hidden': showWithholding === false },
        ));

        return ifFeature('encore',
            (
                <SectionStyledBox
                    hidden={ showWithholding === false }
                    paddingTop={ 2 }
                >
                    <fieldset className={ fieldSetClasses }>
                        { ifFeature('encore',
                            (
                                <SectionStyledHeader
                                    hasPopover={ true }
                                    popoverContent={ $.__('This determines how much you will withhold from your paycheck for state taxes.')
                                    }
                                    popoverIcon="circle-question-regular"
                                    popoverPlacement="bottom"
                                    popoverTitle={ $.__('State Tax Withholding') }
                                >
                                    { $.__('State Tax Withholding') }
                                </SectionStyledHeader>
                            ),
                            (
                                <h3
                                    color="primary"
                                    size="medium"
                                >
                                    <Flex
                                        alignItems="center"
                                        flexDirection="row"
                                    >
                                        { $.__('State Tax Withholding') }
                                        { this._renderHelpPopover() }
                                    </Flex>
                                </h3>
                            )
                        ) }

                        { sectionNote ? ifFeature('encore',
                            (
                                <Flex
                                    alignItems="center"
                                    flexDirection="row"
                                    gap={ 1 }
                                >
                                    <IconV2
                                        color="info-medium"
                                        name="circle-info-solid"
                                        size={ 16 }
                                    />
                                    <BodyText
                                        color="info-medium"
										size='small'
										weight="semibold"
                                    >
                                        { sectionNote }
                                    </BodyText>
                                </Flex>
                            ),
                            (
                                <div className='StateWithholding__sectionNoteContainer'>
                                    <span className='StateWithholding__sectionNoteIcon'><InfoCircle15x15 /></span>
                                    <p className='StateWithholding__sectionNoteText'>
                                        { sectionNote }
                                    </p>
                                </div>
                            )
                        ) : null }

                        { locations && this._renderLocationsSelect() }

                        { activeLocation && (
                            <Fragment>
                                <Flex alignItems="center">
                                    { activeLocation.newFields.filingStatus && this._renderFilingStatusSelect(activeLocation.newFields.filingStatus) }
                                    { feAccessLevelsQuickForms && accessLevel !== undefined ? (
                                        <>
                                            { ((editPermission || viewOnly) && activeLocation.newFields.ignoreStatusExemptions) && this._renderIgnoreCheckbox(activeLocation.newFields) }
                                        </>
                                    ) : (
                                        <>
                                            { ((isAdminUser || isPayrollAdminUser || editPermission) && activeLocation.newFields.ignoreStatusExemptions) && this._renderIgnoreCheckbox(activeLocation.newFields) }
                                        </>
                                    ) }
                                </Flex>
                                { optionalFields }
                                { activeLocation.newFields.additionalWithholding && <AdditionalWithholding data={ activeLocation.newFields.additionalWithholding } type="state" viewOnly={ viewOnly } /> }
                            </Fragment>
                        ) }

                    </fieldset>
                </SectionStyledBox>
            ),
            (
                <fieldset className={ fieldSetClasses }>
                    <Headline color="neutral-strong" component="legend" size="extra-small">
                        { $.__('State Tax Withholding') }
                        { this._renderHelpPopover() }
                    </Headline>

                    { sectionNote && (
                        <div className='StateWithholding__sectionNoteContainer'>
                            <span className='StateWithholding__sectionNoteIcon'><InfoCircle15x15 /></span>
                            <p className='StateWithholding__sectionNoteText'>
                                { sectionNote }
                            </p>
                        </div>
                    ) }

                    { locations && this._renderLocationsSelect() }

                    { activeLocation && (
                        <Fragment>
                            <Flex alignItems="center">
                                { activeLocation.newFields.filingStatus && this._renderFilingStatusSelect(activeLocation.newFields.filingStatus) }
                                { feAccessLevelsQuickForms && accessLevel !== undefined ? (
                                    <>
                                        { ((editPermission || viewOnly) && activeLocation.newFields.ignoreStatusExemptions) && this._renderIgnoreCheckbox(activeLocation.newFields) }
                                    </>
                                ) : (
                                    <>
                                        { ((isAdminUser || isPayrollAdminUser || editPermission) && activeLocation.newFields.ignoreStatusExemptions) && this._renderIgnoreCheckbox(activeLocation.newFields) }
                                    </>
                                ) }
                            </Flex>
                            { optionalFields }
                            { activeLocation.newFields.additionalWithholding && <AdditionalWithholding data={ activeLocation.newFields.additionalWithholding } type="state" viewOnly={ viewOnly } /> }
                        </Fragment>
                    ) }

                </fieldset>
            )
        );
    }

    // _handleUSDBlur = (e) => { Note: Not used unless we use the new additional withholding field.
    //     const inputValue = e.target.value;

    //     if (!inputValue.trim()) {
    //         this.setState({
    //             additionalWithholding: 0,
    //         })
    //         return;
    //     }

    //     this.setState({
    //         additionalWithholding: formatUsdInputValue(inputValue, 0, 9999999),
    //     })
    // }

    _handleLocationChange = (selectedValues) => {
        const {
            locations,
            onLocationChange,
            setSelectedStateLocation,
            setNewIowaStateForm,
        } = this.props;

        const [selectedValue] = selectedValues;
        this.setState({
            stateAbbreviation: selectedValue ? locations.byId[selectedValue].state : null,
        });
        setSelectedStateLocation(selectedValue);
        if (locations.byId[selectedValue].state !== "IA") {
            setNewIowaStateForm(false);
        }
        if (onLocationChange) {
            onLocationChange({
                val: selectedValue,
            });
        }
    };

    _renderLocationsSelect = () => {
        const {
            locations,
            newHirePacket,
            showLocationRequiredAsterisk,
            viewOnly,
        } = this.props;
        const { stateAbbreviation, selectedLocation } = this.state;

        return (
            <Flex alignItems="center" marginTop={ 2 }>
                <Flex flexDirection="column" marginBottom={ 2 }>
                    <Label disabled={ viewOnly || newHirePacket } htmlFor="stateLocation" require={ showLocationRequiredAsterisk }>{ $.__('Location') }</Label>
                    <Select
                        id="iowaStateLocation"
                        isClearable={ true }
                        isDisabled={ viewOnly || newHirePacket }
                        items={
                            locations.allIds.map((location) => {
                                return {
                                    text: locations.byId[location].displayText,
                                    value: locations.byId[location].val,
                                };
                            })
                        }
                        name="state[location]"
                        onChange={ this._handleLocationChange }
                        required={ showLocationRequiredAsterisk }
                        selectedValues={ [selectedLocation] }
                        width="5"
                    />
                </Flex>
                { stateAbbreviation && (
                    <LayoutBox marginLeft={ 2 }>
                        <Label >{ $.__('State: %1$s', stateAbbreviation) }</Label>
                    </LayoutBox>
                ) }
            </Flex>
        );
    };

    _renderFilingStatusSelect = (filingStatus) => {
        const { viewOnly } = this.props;
        const {
            filingStatus: selectedFilingStatus,
            ignore,
        } = this.state;

        return (
            <Flex flexDirection="column" marginBottom={ 2 }>
                <Label disabled={ ignore || viewOnly } htmlFor="stateStatus" required={ !ignore } >{ $.__('Filing Status') }</Label>
                <Select
                    id="iowaStateStatus"
                    isClearable={ true }
                    isDisabled={ ignore || viewOnly }
                    items={
                        filingStatus.map((status) => {
                            return {
                                text: ignore ? 'NA' : status.displayText,
                                value: status.val,
                            };
                        })
                    }
                    name="state[status]"
                    onChange={ (value) => {
                        this.setState({
                            filingStatus: value[0],
                        });
                    } }
                    placeholder={ ignore ? 'NA' : undefined }
                    required={ !ignore }
                    selectedValues={ [selectedFilingStatus] }
                    width="5"
                />
            </Flex>
        );
    };

    _renderIgnoreCheckbox = () => {
        const {
            ignore,
            viewOnly
        } = this.state;
        const labelText = $.__('Ignore filing status/other exemptions');

        return (
            <Flex alignItems="center" marginLeft={ 2 }>
                <Checkbox
                    checked={ ignore }
                    disabled={ viewOnly }
                    htmlFor="iowaStateIgnoreCheckbox"
                    id="iowaStateIgnoreCheckbox"
                    label={ labelText }
                    name="state[taxExempt]"
                    onChange={ (event) => this.setState({
                        ignore: event.checked
                    })
                    }
                    value={ ignore ? 'on' : 'off' }
                />
            </Flex>
        );
    };

    // _renderAdditionalWithholding = () => { // Note: Add this back in if we ever want to address the feature of just a dollar amount in the future.
    //  const { viewOnly } = this.props;
    //  const { additionalWithholding } = this.state;

    //  return (
    //      <Flex alignItems="center">
    //          <Flex flexDirection="column"  marginBottom={ 2 }>
    //              <Label disabled={ viewOnly } htmlFor="stateAdditional">{ $.__('Additional Withholding Per Pay Period') }</Label>
    //              <TextField
    //                  id="iowaStateAdditional"
    //                  name="state[withholdExtraValue]"
    //                  onBlur={ this._handleUSDBlur }
    //                  onChange={ (e) => {
    //                      this.setState({
    //                          additionalWithholding: e.target.value,
    //                      });
    //                  } }
    //                  value={ additionalWithholding }
    //                  width={ 5 }
    //              />
    //          </Flex>
    //      </Flex>
    //  );
    // }

    /* @startCleanup encore */
    _renderHelpPopover = () => {
        const { helpPopover } = this.state;

        return (
            <Flex display="inline-flex" marginLeft="10px">
                <IconButton
                    color="secondary"
                    icon={ ifFeature('encore', 'circle-question-regular', <BodyText
                        color="neutral-weak"
                        icon={ <Question14x14 /> }
                        size="medium"
                        weight="regular" />) }
                    noBoundingBox="true"
                    onClick={ () => {
                        this.setState({
                            helpPopover: true,
                        });
                    } }
                    ref={ this.iconBtnRef }
                    type="button"
                />

                <Popover
                    anchorEl={ this.iconBtnRef.current }
                    onClose={ () => {
                        this.setState({
                            helpPopover: false,
                        });
                    } }
                    open={ helpPopover }
                    placement='bottom'
                    title={ $.__('State Tax Withholding') }
                >
                    { $.__('This determines how much you will withhold from your paycheck for state taxes.') }
                </Popover>
            </Flex>
        );
    };
    /* @endCleanup encore */
}
