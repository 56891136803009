import html from './clocked-out-error.micro.html';
import {INCORRECT_TIME_ENTRIES_MSG} from 'time-tracking/constants';
import './styles.styl';

export default function (generalError, callback) {
	window.SimpleModal.openModal({
		callbacks: {
			close: {
				after: function() {
					if (generalError) {
						window.setMessage(INCORRECT_TIME_ENTRIES_MSG, 'error');
					}
				}
			}
		},
		html: window.microTemplate(html, {}),
		title: $.__('Whoa, something changed'),
		footer: {
			buttons: {
				primary: {
					text: $.__('Got it'),
					action: () => {
						window.SimpleModal.closeModal();
						callback();
					}
				},
				cancel: {
					show: false
				}
			}
		}
	});
}
