import { Fragment, useState, useEffect } from 'react';

import { useCountrySwitcher } from 'country-switcher.hook';
import { Icon } from '@bamboohr/fabric';
import Tooltip from 'tooltip.react';

import { CustomQuestionTypes } from '../../types';
import {
	CandidateField,
	CandidateFieldRow,
	CandidateFieldSection,
	CandidateVeteranStatus,
} from '../form-fields';

const CandidateForm = (data) => {
	const {
		countryId,
		postCodeLabel,
		subEntityLabel,
		subEntityOptions,
		switchCountry,
	} = useCountrySwitcher({
		initialCountryId: (data.countryId && data.countryId.value !== '') ? data.countryId.value : '1',
		initialSubEntityOptions: data.state ? data.state.options : [],
	});

	const [stateData, setStateData] = useState({ ...data.state });

	useEffect(() => {
		if (data.countryId && countryId === data.countryId.value) {
			setStateData({
				...data.state,
				options: subEntityOptions,
			});
		} else {
			setStateData({
				...data.state,
				value: '',
				options: subEntityOptions,
			});
		}
	}, [countryId]);

	const sourceOptions = {
		perms: data.sourceId ? data.sourceId.hasSettingsPerms : false,
		url: '/hiring/jobs/sources',
		label: $.__('Source'),
		name: $.__('Add New Source'),
	};

	return (
		<Fragment>
			{ /* Candidate Personal Information */ }
			<CandidateFieldSection shouldShow={ true }>
				<CandidateFieldRow shouldShow={ true }>
					<CandidateField data={ data.firstName } label={ $.__('First Name') } name="firstName" type="TYPE_TEXT" />
					<CandidateField data={ data.lastName } label={ $.__('Last Name') } name="lastName" type="TYPE_TEXT" />
				</CandidateFieldRow>
				<CandidateFieldRow shouldShow={ true }>
					<CandidateField
						data={ data.email }
						label={ $.__('Email') }
						name="email"
						rules={ { email: { message: () => $.__('Invalid email address.') } } }
						showErrorMessage={ true }
						type="TYPE_TEXT"
					/>
				</CandidateFieldRow>
				<CandidateFieldRow shouldShow={ Boolean(data.phone) }>
					<CandidateField data={ data.phone } label={ $.__('Phone') } name="phone" rules={ { maxLength: 20 } } type="TYPE_TEXT" />
				</CandidateFieldRow>
				<CandidateFieldRow shouldShow={ Boolean(data.streetAddress) }>
					<CandidateField data={ data.streetAddress } label={ $.__('Address') } name="streetAddress" type="TYPE_TEXT" />
				</CandidateFieldRow>
				<CandidateFieldRow shouldShow={ Boolean(data.city || data.stateId || data.zip) }>
					<CandidateField data={ data.city } label={ $.__('City') } name="city" type="TYPE_TEXT" />
					{ subEntityOptions.length ? (
						<CandidateField data={ stateData } label={ subEntityLabel } name="state" type="TYPE_SELECT" />
					) : (
						<CandidateField data={ stateData } label={ subEntityLabel } name="state" type="TYPE_TEXT" />
					) }
					<CandidateField data={ data.zip } label={ postCodeLabel } name="zip" type="TYPE_TEXT" />
				</CandidateFieldRow>
				<CandidateFieldRow shouldShow={ Boolean(data.countryId) }>
					<CandidateField data={ { ...data.countryId, value: countryId } } label={ $.__('Country') } name="countryId" type="TYPE_SELECT" typeProps={ { onSelect: selected => switchCountry(selected[0]) } } />
				</CandidateFieldRow>
			</CandidateFieldSection>

			{ /* Candidate Source/Resume  */ }
			<CandidateFieldSection shouldShow={ Boolean(data.sourceId || data.coverLetterFileId || data.resumeFileId || data.dateAvailable || data.desiredPay) }>
				<CandidateFieldRow shouldShow={ Boolean(typeof data.sourceId === "object") }>
					<CandidateField data={ data.sourceId } label={ $.__('Source') } name="sourceId" type="TYPE_SELECT" typeProps={ sourceOptions } />
				</CandidateFieldRow>
				{ typeof data.sourceId === "string" && (
					<input name="source" type="hidden" value={ data.sourceId } />
				)}
				<CandidateFieldRow shouldShow={ Boolean(data.coverLetterFileId) }>
					<CandidateField data={ data.coverLetterFileId } label={ $.__('Cover Letter') } name="coverLetterFileId" type="TYPE_FILE_UPLOAD" />
				</CandidateFieldRow>
				<CandidateFieldRow shouldShow={ Boolean(data.resumeFileId) }>
					<CandidateField data={ data.resumeFileId } label={ $.__('Resume') } name="resumeFileId" type="TYPE_FILE_UPLOAD" />
				</CandidateFieldRow>
				<CandidateFieldRow shouldShow={ Boolean(data.dateAvailable) }>
					<CandidateField data={ data.dateAvailable } label={ $.__('Date Available') } name="dateAvailable" type="TYPE_DATE" />
				</CandidateFieldRow>
				<CandidateFieldRow shouldShow={ Boolean(data.desiredPay) }>
					<CandidateField data={ data.desiredPay } label={ $.__('Desired Pay') } name="desiredPay" type="TYPE_TEXT" />
				</CandidateFieldRow>
			</CandidateFieldSection>

			{ /* Candidate Links  */ }
			<CandidateFieldSection shouldShow={ Boolean(data.websiteUrl || data.linkedinUrl || data.twitterUsername) }>
				<CandidateFieldRow shouldShow={ Boolean(data.websiteUrl) }>
					<CandidateField
						data={ data.websiteUrl }
						label={ $.__('Website, Blog, or Portfolio') }
						name="websiteUrl"
						rules={ { url: { message: () => $.__('Invalid website URL.') } } }
						showErrorMessage={ true }
						type="TYPE_TEXT"
						typeProps={ { hasCustomUrlHandling: true } }
					/>
				</CandidateFieldRow>
				<CandidateFieldRow shouldShow={ Boolean(data.linkedinUrl) }>
					<CandidateField
						data={ data.linkedinUrl }
						label={ $.__('LinkedIn Profile URL') }
						name="linkedinUrl"
						rules={ { url: { message: () => $.__('Invalid LinkedIn profile URL.') } } }
						showErrorMessage={ true }
						type="TYPE_TEXT"
						typeProps={ { hasCustomUrlHandling: true, nubIconName: 'fab-linkedin-12x11' } }
					/>
				</CandidateFieldRow>
				<CandidateFieldRow shouldShow={ Boolean(data.twitterUsername) }>
					<CandidateField data={ data.twitterUsername } label={ $.__('Twitter Username') } name="twitterUsername" type="TYPE_TEXT" typeProps={ { nubIconName: 'fab-twitter-14x11' } } />
				</CandidateFieldRow>
			</CandidateFieldSection>

			{ /* Candidate References  */ }
			<CandidateFieldSection shouldShow={ Boolean(data.educationLevelId || data.educationInstitutionName) }>
				<CandidateFieldRow shouldShow={ Boolean(data.educationLevelId || data.educationInstitutionName) }>
					<CandidateField data={ data.educationLevelId } label={ $.__('Highest Education Obtained') } name="educationLevelId" type="TYPE_SELECT" />
					<CandidateField data={ data.educationInstitutionName } label={ $.__('College/University') } name="educationInstitutionName" type="TYPE_TEXT" />
				</CandidateFieldRow>
			</CandidateFieldSection>

			{ /* Candidate Education  */ }
			<CandidateFieldSection shouldShow={ Boolean(data.referredBy || data.references) }>
				<CandidateFieldRow shouldShow={ Boolean(data.referredBy) }>
					<CandidateField data={ data.referredBy } label={ $.__('Who referred you for this position?') } name="referredBy" type="TYPE_TEXT" />
				</CandidateFieldRow>
				<CandidateFieldRow shouldShow={ Boolean(data.references) }>
					<CandidateField data={ data.references } label={ $.__('References (Name, Company, and Contact Info)') } name="references" type="TYPE_TEXTAREA" />
				</CandidateFieldRow>
			</CandidateFieldSection>

			{ /* Candidate Custom Questions  */ }
			<CandidateFieldSection shouldShow={  Boolean(data?.customQuestions?.length) }>
				{
					data.customQuestions && data.customQuestions.map((question) => {
						const typeProps = question.type === 'file' ? { canSelectMultiple: true } : {};
						const name = question.type === 'file' ? `customQuestions[${ question.id }][]` : `customQuestions[${ question.id }]`;
						return (
							<CandidateFieldRow key={ question.id } shouldShow={ true }>
								<CandidateField
									cqType={ question.type || CustomQuestionTypes.long }
									data={ question }
									label={ question.question }
									name={ name }
									typeProps={ typeProps }
								/>
							</CandidateFieldRow>
						);
					})
				}
			</CandidateFieldSection>

			{ /* Candidate EEO  */ }
			<CandidateFieldSection shouldShow={ Boolean(data.jobCategoryId) }>
				<CandidateFieldRow shouldShow={ true }>
					<div className="CandidateForm__EEO">
						<div className="CandidateForm__EEOHeading">
							{ $.__(`Your answers to the following four questions are entirely optional.`) }
							<Tooltip
								settings={
									{
										content: $.__('To comply with government Equal Employment Opportunity and Affirmative Action reporting regulations, we are requesting (but NOT requiring) that you provide this personal data.'),
										position: 'right',
									}
								}
								wrapperClassName="CandidateForm__EEOIcon"
							>
								<Icon name="fab-question-14x14" />
							</Tooltip>
						</div>
						<div className="CandidateForm__EEOParagraph">
							{ $.__(`This information will not be used as selection criteria. If you'd rather not share this information, feel free to select "Decline to Answer".`) }
						</div>
					</div>
				</CandidateFieldRow>
				<CandidateFieldRow shouldShow={ true }>
					<CandidateField data={ data.genderId } label={ $.__('Gender') } name="genderId" type="TYPE_SELECT" />
				</CandidateFieldRow>
				<CandidateFieldRow shouldShow={ true }>
					<CandidateField data={ data.ethnicityId } label={ $.__('Ethnicity') } name="ethnicityId" type="TYPE_SELECT" />
				</CandidateFieldRow>
				<CandidateFieldRow shouldShow={ true }>
					<CandidateField data={ data.disabilityId } label={ $.__('Disability') } name="disabilityId" type="TYPE_SELECT" />
				</CandidateFieldRow>
				<CandidateFieldRow shouldShow={ true }>
					<CandidateVeteranStatus data={ data.veteranStatusId } label={ $.__('Veteran Status') } name="veteranStatusId" />
				</CandidateFieldRow>
			</CandidateFieldSection>
		</Fragment>
	);
};

export default CandidateForm;
