import {
	BlankState,
	Button,
	IconButton,
} from '@bamboohr/fabric';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import ajax from '@utils/ajax';
import classnames from 'classnames';
/* @startCleanup encore */
import { ifFeature } from '@utils/feature';
import { BlankState as OldBlankState } from '@fabric/blank-state';
import { Button as OldButton, FloatingIconButton } from '@fabric/button';
/* @endCleanup encore */

import { Fields, getSelectedValue, getField, getError, customRules } from 'dynamic-form';

import { WithholdingWrapper } from '../../../../employees/pay-info/withholding-wrapper.react';
import './tax-information.styl';

const validationName = 'taxInformationGroup';

const TablesEmployeeTaxInformationElement: FunctionComponent<DynamicForm.CustomElement> = ({ context , props}) => {
	// Currently this is only shown for Admin or Payroll admin on New Employee -> Default to everything visible
	const {
		controls: { Legend },
		validation: { formState: { errors }, register },
		form: { isAddEmployeeForm },
		formOptions: { employeeId, showPayrollFederal, showPayrollState, isNHPPacket },
	} = context;
	const {
		disabled,
	} = props;
	const [taxData, setTaxData] = useState({});
	const [showBlankState, setShowBlankState] = useState(false);
	const [taxProcessing, setTaxProcessing] = useState(false);
	const taxInfoContainerRef = useRef<HTMLDivElement>();
	const isAdminUser = window.SESSION_USER && window.SESSION_USER.isAdmin;

	register(validationName, { validate: { manuallyRequired: () => customRules.validateTaxInformation(taxInfoContainerRef) } });
	const error = getError(errors, validationName);
	const hasError = !!error;

	const locationField = getField(context, Fields.location) as DynamicForm.SelectElement;
	const homeStateField = getField(context, Fields.employeeAddressState) as DynamicForm.SelectElement;
	const payrollHomeStateField = getField(context, Fields.payrollHomeStateField) as DynamicForm.SelectElement;
	const stateTaxWithholdingLocationField = getField(context, Fields.stateTaxWithholdingLocationField) as DynamicForm.SelectElement;
	const stateUiLocationField = getField(context, Fields.stateUiLocationField) as DynamicForm.SelectElement;
	const stateUiExemptField = getField(context, Fields.stateUiExemptField) as DynamicForm.CheckboxElement;

	const locationId = getSelectedValue(locationField);
	const homeStateId = getSelectedValue(homeStateField) || getSelectedValue(payrollHomeStateField);
	const stateTaxWithholdingLocation = getSelectedValue(stateTaxWithholdingLocationField);
	const stateUiLocation = getSelectedValue(stateUiLocationField);
	const stateUiExempt = !!stateUiExemptField?.props?.checked;

	const canShowWithholdingInformation = () => {
		if (locationField && (homeStateField || payrollHomeStateField) && stateTaxWithholdingLocationField && stateUiLocationField && stateUiExemptField) {
			if (locationId && homeStateId && stateTaxWithholdingLocation && stateUiLocation) {
				setShowBlankState(true);
			}
		}
	};

	const getWithholdingInformation = async () => {
		if (locationId && homeStateId && stateTaxWithholdingLocation && stateUiLocation) {
			setTaxProcessing(true);
			const response = await ajax.get('/payroll_ajax/new_employee_detailed_tax_information/', { locationId, homeStateId, stateTaxWithholdingLocation, stateUiLocation, stateUiExempt });
			setTaxProcessing(false);
			if (response.data && response.data.success) {
				setTaxData(response.data.employeeTaxInformation);
				setShowBlankState(false);
			}
		}
	};

	useEffect(() => {
		canShowWithholdingInformation();
	}, [locationId, homeStateId, stateTaxWithholdingLocation, stateUiLocation, stateUiExempt]);

	const className = classnames('DynamicForm--taxInformation', {
		'DynamicForm--error': hasError,
	});

	const {
		// @ts-ignore
		hasBhrPayroll,
		// @ts-ignore
		hasTempTraxFeature,
		hasTrax,
		// @ts-ignore
		withHoldings,
	} = window;

	const employeeTaxDataEl = document.getElementById('nhpTaxInformation');

	const withHoldingProps = {
		canAccessStateUiFields: true,
		canManageBhrPayroll: false,
		companyTaxStates: [],
		employeeId: 0,
		federalTaxDeductionsPermissions: 3,
		federalTaxDeductionToggle: true,
		hasBhrPayroll: false,
		hasTempTraxFeature: true,
		hasTrax: true,
		isAdminUser,
		isFullPage: true,
		isNewHirePacket: true,
		isPayrollAdminUser: true,
		newTraxEEData: taxData,
		residentTaxState: null,
		showResidentTaxState: null,
		stateTaxDeductionsPermissions: 3,
		unemploymentTaxState: null,
		withholdings: {},
	};

	if (!isAddEmployeeForm) {
		withHoldingProps.canAccessStateUiFields = false;
		withHoldingProps.companyTaxStates = null;
		withHoldingProps.employeeId = employeeId as number;
		withHoldingProps.federalTaxDeductionsPermissions = showPayrollFederal ? 2 : 0;
		withHoldingProps.hasBhrPayroll = hasBhrPayroll;
		withHoldingProps.hasTempTraxFeature = hasTempTraxFeature;
		withHoldingProps.hasTrax = hasTrax || undefined;
		withHoldingProps.isAdminUser = false;
		withHoldingProps.isNewHirePacket = isNHPPacket;
		withHoldingProps.isPayrollAdminUser = false;
		withHoldingProps.newTraxEEData = employeeTaxDataEl ? JSON.parse(employeeTaxDataEl.innerHTML) : null;
		withHoldingProps.stateTaxDeductionsPermissions = showPayrollState ? 2 : 0;
		withHoldingProps.withholdings = withHoldings || undefined;
	}

	return (
		<div className={ className } ref={ taxInfoContainerRef }>
			<Legend props={ {} } settings={ { label: $.__('Tax Withholding'), icon: 'taxWithholding' } } />
			{ hasError && (
				<span className="fab-FormNote fab-FormNote--error">{ error.message }</span>
			) }
			{ !showBlankState && (
				<>
					{ !!Object.keys(taxData).length && (
						<div className="DynamicForm--taxInformation__delete">
							{ ifFeature('encore',
								<IconButton
									floatingIcon={ true }
									icon="trash-solid"
									onClick={ () => setShowBlankState(true) }
									secondary={ true }
									type="button"
								/>
								,
								<FloatingIconButton
									clickAction={ () => setShowBlankState(true) }
									icon="fab-trash-can-14x16"
									secondary={ true }
									type="button"
								/>
							) }
						</div>
					) }
					<WithholdingWrapper { ...withHoldingProps } />
				</>
			) }
			{ showBlankState ? ifFeature('encore',
				(
					<BlankState
						actions={ [
							<Button
								isProcessing={ taxProcessing }
								key="add-withholding"
								onClick={ getWithholdingInformation }
								outline={ true }
								type="button"
							>
								{ $.__('Add Withholding') }
							</Button>
						] }
						icon="landmark-dome-solid"
						subtitle={ $.__('Federal and State Tax withholding information can be added for this employee.') }
						title=""
					/>
				),
				(
					<OldBlankState
						icon="fab-gov-building-66x72"
						subtitle={ $.__('Federal and State Tax withholding information can be added for this employee.') }
						title=""

					>
						<OldButton
							clickAction={ getWithholdingInformation }
							isProcessing={ taxProcessing }
							outline={ true }
							text={ `+ ${ $.__('Add Withholding') }` }
							type="button"
						/>
					</OldBlankState>
				)
			) : null }
		</div>
	);
};

export default TablesEmployeeTaxInformationElement;
