import { ColumnsRes, EmployeeResult } from "./types";
import { TableColumn } from "@bamboohr/fabric";

export function buildColumns(res: ColumnsRes[]): TableColumn<EmployeeResult>[] {
    return res.map(e => ({
            header: e.name,
            fieldId: e.fieldId,
            key: e.fieldId,
            sortBy: cell => cell[e.fieldId].value?.toString()?.toLowerCase() ?? '',
            cell: (cell) => {
                let ret = <span>{cell[e.fieldId].value}</span>;
                if(cell[e.fieldId].type === 'name') {
                    ret = <a href={`/employees/employee.php?id=${cell.id.value}`}>{cell[e.fieldId].value}</a>
                }
                return ret;
            }
        }
    ))
}
