import React from 'react';
import { formatTime } from 'time-tracking/utils';
import { BodyText, Flex } from '@bamboohr/fabric';
/* @startCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';
/* @endCleanup encore */

export default function SummaryBreakdown(props) {
	const { periodOvertime, periodTimeOff, periodHoliday } = props;
	const showMultiplier = periodOvertime.length > 1;

	return ifFeature(
		'encore',
		<Flex flexDirection='column' gap={0.5} alignItems="center">
			{!!periodOvertime.length &&
				periodOvertime.map((item) => (
					<BodyText color='warning-strong' key={item.multiplier}>
						{formatTime(item.hours, true)} {$.__('Overtime')} {showMultiplier && `(${item.multiplier}x)`}
					</BodyText>
				))}

			{!!periodTimeOff && (
				<BodyText color='neutral-medium'>
					{formatTime(periodTimeOff, true)} {$.__('Time Off')}
				</BodyText>
			)}

			{!!periodHoliday && (
				<BodyText color='neutral-medium'>
					{formatTime(periodHoliday, true)} {$.__('Holiday')}
				</BodyText>
			)}
		</Flex>,
		<>
			{!!periodOvertime.length &&
				periodOvertime.map((item) => (
					<div className='TimesheetSummary__breakdownItem TimesheetSummary__breakdownItem--warning' key={item.multiplier}>
						{formatTime(item.hours, true)} {$.__('Overtime')} {showMultiplier && `(${item.multiplier}x)`}
					</div>
				))}

			{!!periodTimeOff && (
				<div className='TimesheetSummary__breakdownItem'>
					{formatTime(periodTimeOff, true)} {$.__('Time Off')}
				</div>
			)}

			{!!periodHoliday && (
				<div className='TimesheetSummary__breakdownItem'>
					{formatTime(periodHoliday, true)} {$.__('Holiday')}
				</div>
			)}
		</>
	);
}
