import {
	BlankState,
	LayoutBox,
} from '@bamboohr/fabric';
/* @startCleanup encore */
import {
	ifFeature,
} from '@utils/feature';

import OldBlankState from 'blank-state.react';
import './withholding-blank-state.styl';
/* @endCleanup encore */
import { SectionStyledBox } from '../section-styled-box';
import { SectionStyledHeader } from '../section-styled-header';

export function WithholdingBlankState() {
	return (
		<>
			{ ifFeature('encore',
				<fieldset>
					<SectionStyledBox
						paddingTop={ 2 }
						>
						<SectionStyledHeader>
							{ $.__('Tax Withholding') }
						</SectionStyledHeader>
						<LayoutBox padding={ 4 }>
							<BlankState
								icon="landmark-dome-regular"
								level="widget"
								subtitle={ $.__('Check to make sure your home and job location addresses are valid.') }
								title={ $.__('Federal and State Tax withholding information will appear here when all of your data is in order.') }
							/>
						</LayoutBox>
					</SectionStyledBox>
				</fieldset>
				,
				<fieldset>
					<legend>
						{ $.__('Tax Withholding') }
					</legend>
					<OldBlankState
							className="WithholdingBlankState"
							icon="capitol-39x43"
							subtitle={ $.__('Check to make sure your home and job location addresses are valid.') }
							title={ $.__('Federal and State Tax withholding information will appear here when all of your data is in order.') }
						/>
				</fieldset>
			) }
		</>
	);
}
