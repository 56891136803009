import {Component} from 'react';
import { Avatar, Divider, Flex, LayoutBox, Section, StyledBox } from '@bamboohr/fabric';
import {consume} from '../../store/context';
import {getPayPeriodText, getSummaryRangeText, sumAllEarnableHoursInDays} from '../../utils';
import {formatTime} from 'time-tracking/utils';
/* @startCleanup encore */
import { classNameFromObject } from '@utils/dom';
import { ifFeature } from '@bamboohr/utils/lib/feature';
/* @endCleanup encore */
import './styles.styl';
import SummaryApproval from './summary-approval.react';
import SummaryWeek from './summary-week.react';
import SummaryPayPeriod from './summary-pay-period.react';
import ClockInOut from './clock-in-out';

class TimesheetSummary extends Component {
	_defaultAnimationState = {
		focusedDaysUpdated: false,
		lastWeekDaysUpdated: false,
		thisWeekDaysUpdated: false,
	};

	state = {
		...this._defaultAnimationState,
		focusedDays: this.props.getFocusedDays(),
		lastWeekDays: this.props.getLastWeekDays(),
		thisWeekDays: this.props.getThisWeekDays(),
	};

	_weekTitleText = $.__('This Week');
	_weekRangeText = getSummaryRangeText(this.props.thisWeek.start, this.props.thisWeek.end, this.props.serverTime);
	_payPeriodTitleText = getPayPeriodText(this.props.focusedTimesheet.id, this.props.latestTimesheetId, this.props.latestPreviousTimesheetId);
	_payPeriodRangeText = getSummaryRangeText(this.props.focusedTimesheet.start, this.props.focusedTimesheet.end, this.props.serverTime);

	_renderApproval() {
		const approvalSummaryProps = {
			employeeId: this.props.employee.id,
			employeeName: this.props.employee.name,
			employeePhotoUrl: this.props.employee.photoUrl,
			payPeriodTitleText: this._payPeriodTitleText,
			payPeriodRangeText: this._payPeriodRangeText,
		};

		return (
			<SummaryApproval { ...approvalSummaryProps } />
		);
	}

	_renderSummary() {
		const {
			isOwnTimesheet,
			employee,
			workflow,
			serverTime,
			timesheetOptions,
			clock,
			summaryTotals,
		} = this.props;

		const {
			focusedDaysUpdated,
			lastWeekDaysUpdated,
			thisWeekDaysUpdated,
			focusedDays,
			lastWeekDays,
			thisWeekDays,
		} = this.state;

		const {weekTotal, periodTotal, periodOvertime, periodTimeOff, periodHoliday} = summaryTotals;
		const earned = clock ? clock.earned : 0;

		const weekSummaryProps = {
			fadeGraph: (thisWeekDaysUpdated || lastWeekDaysUpdated),
			fadeWeekTotal: thisWeekDaysUpdated,
			hideWeekends: (isOwnTimesheet && !timesheetOptions.showWeekends),
			lightTheme: !!clock,
			lastWeekDays,
			thisWeekDays,
			serverTime,
			weekTitleText: this._weekTitleText,
			weekRangeText: this._weekRangeText,
			weekTotal: formatTime(weekTotal + earned),
		};

		const payPeriodSummaryProps = {
			approvalMonthAndDayStr: workflow.approvableMoment.format('MMM D'),
			fadePayPeriod: focusedDaysUpdated,
			focusedDays,
			payPeriodTitleText: this._payPeriodTitleText,
			payPeriodRangeText: this._payPeriodRangeText,
			payPeriodTotal: formatTime(periodTotal + earned),
			periodOvertime,
			periodTimeOff,
			periodHoliday,
		};

		const summary = (
			<>
				{ifFeature(
					'encore',
					<Flex justifyContent='center' marginBottom={1.25} textAlign='center'>
						<Avatar alt={$.__('employee avatar')} src={employee.photoUrl} />
					</Flex>,
					<img alt={$.__('employee avatar')} className='TimesheetSummary__photo' src={employee.photoUrl} />
				)}
				{ !!clock && <ClockInOut /> }
				{ifFeature('encore', !!clock && <Divider color='neutral-extra-weak' marginBottom={3} marginTop={2} />)}
				<SummaryWeek { ...weekSummaryProps } />
				{ifFeature('encore', <Divider color='neutral-extra-weak' marginBottom={3} marginTop={2} />)}
				<SummaryPayPeriod { ...payPeriodSummaryProps } />
			</>
		);

		return summary;
	}

	componentWillReceiveProps(nextProps) {
		const updates = {
			focusedDays: this.props.getFocusedDays(),
			lastWeekDays: this.props.getLastWeekDays(),
			thisWeekDays: this.props.getThisWeekDays(),
		};

		const clockedInChanged = this.props.clock && nextProps.clock.clockedIn !== this.props.clock.clockedIn;
		const clockedInEarnedTimeChanged = this.props.clock && nextProps.clock.clockedIn && nextProps.clock.earned !== this.props.clock.earned;

		if (clockedInChanged || clockedInEarnedTimeChanged) {
			this.setState(updates); // no ui flash!
		} else {
			const entryChanges = {
				focusedDaysUpdated: sumAllEarnableHoursInDays(this.state.focusedDays) !== sumAllEarnableHoursInDays(updates.focusedDays),
				lastWeekDaysUpdated: sumAllEarnableHoursInDays(this.state.lastWeekDays) !== sumAllEarnableHoursInDays(updates.lastWeekDays),
				thisWeekDaysUpdated: sumAllEarnableHoursInDays(this.state.thisWeekDays) !== sumAllEarnableHoursInDays(updates.thisWeekDays),
			};

			if (entryChanges.focusedDaysUpdated || entryChanges.lastWeekDaysUpdated || entryChanges.thisWeekDaysUpdated) {
				this.setState(entryChanges);
				setTimeout(() => this.setState({
					...this._defaultAnimationState,
					...updates
				}), 300);
			}
		}
	}

	render() {
		const { isApprovalShowing, isViewOnly } = this.props;

		return ifFeature(
			'encore',
			<LayoutBox position='sticky' top={isViewOnly ? 32 : 216} width={336}>
				<Section>
					{isApprovalShowing || isViewOnly ? this._renderApproval() : this._renderSummary()}
				</Section>
			</LayoutBox>,
			<div className='TimesheetSummaryContainer'>
				<div
					className={classNameFromObject({
						TimesheetSummary: true,
						'TimesheetSummary--viewOnly': isViewOnly,
					})}
					ref={(node) => {
						this._timesheetSummaryRef = node;
					}}
				>
					{isApprovalShowing || isViewOnly ? this._renderApproval() : this._renderSummary()}
				</div>
			</div>
		);
	}
}

export default consume([
	// Properties
	'isApprovalShowing',
	'isOwnTimesheet',
	'serverTime',
	'timesheetOptions',
	'employee',
	'workflow',
	'focusedTimesheet',
	'latestTimesheetId',
	'latestPreviousTimesheetId',
	'thisWeek',
	'clock',
	'summaryTotals',
	'showHeader',
	'isViewOnly',
	// Queries
	'getFocusedDays',
	'getLastWeekDays',
	'getThisWeekDays',
], TimesheetSummary);
