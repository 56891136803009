export const COMPLETE_LATER_SUCCESS_MESSAGE = $.__(`Sounds Good! We'll ask again in a week.`);

export const DPA_HEADLINE_ICON = 'fab-eu-globe-58x58';

export const DpaStatus = Object.seal({
	COMPLETE_LATER: 'complete_later',
	DECLINED: 'declined',
	NEEDS_PROMPT: 'needs_prompt',
	NEEDS_TO_SIGN: 'needs_to_sign',
	SIGNED: 'signed',
	UPDATE_PENDING: 'update_pending',
});

export const IS_ADMIN = !!window.SESSION_USER?.isAdmin;

export const DPA_UPDATE_POSTPONED_ON = 'dpa_update_postponed_on';
