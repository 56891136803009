import { useState } from 'react';
import { DatePicker, DatePickerProps } from '@bamboohr/fabric';
import { NoFacadeComponent } from './no-facade-component';

import { convertDateToIsoFormatString } from './utils';

type SingleDateComponentProps = {
	name: string;
	value?: string;
	noFacade?: boolean;
} & DatePickerProps

export function SingleDateComponent(props: SingleDateComponentProps) {
	const { name, value = '', noFacade, ...rest } = props;

	const [selectedDate, setSelectedDate] = useState(convertDateToIsoFormatString(value));
	return (
		<>
			<DatePicker
				{...rest}
				onChange={({ value: newSelectedDate }) => {
					setSelectedDate(newSelectedDate);
				}}
				value={selectedDate}
			/>
			{noFacade ? (
					<NoFacadeComponent dateString={selectedDate} name={name} />
			) : (
				''
			)}
		</>
	);
}
