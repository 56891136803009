import {
	Flex,
	IconButton,
	IconV2,
	LayoutBox,
	Popover,
} from '@bamboohr/fabric';
/* @startCleanup encore */
import { InfoCircle15x15 } from '@bamboohr/grim';
import { ExternalLink, Message } from '@utils/message';
import { ifFeature } from '@utils/feature';
/* @endCleanup encore */
import { Component, createRef } from 'react';
import c from 'classnames';

import Select from 'select.react';

import { HelpPopover } from '../help-popover.react';
import { OptionalTextInput } from '../optional-text-input.react';
import { OptionalCheckbox } from '../optional-checkbox.react';
import { OptionalSelect } from '../optional-select.react';
import { LinkedSelects } from '../linked-selects.react';
import { SectionStyledBox } from '../section-styled-box';
import { SectionStyledHeader } from '../section-styled-header';

import './state-unemployment.styl';
import { isEnabled } from 'FeatureToggle.util';

const feAccessLevelsQuickForms = isEnabled('PAY_INFO_TAB_ACCESS_LEVELS');

const OPTIONAL_FIELDS_MAP = {
	text: OptionalTextInput,
	checkbox: OptionalCheckbox,
	select: OptionalSelect,
	linkedSelects: LinkedSelects,
};

export class StateUnemployment extends Component {
	constructor(props) {
		super(props);
		this._helpPopoverRef = createRef();
		const { fields } = props;
		const selectedLocation = fields.locations ? fields.locations.find(option => option.selected) : null;
		const exempt = fields.exempt ? fields.exempt.checked : null;

		this.state = {
			location: selectedLocation ? selectedLocation.val : null,
			exempt,
			showHelpPopover: false,
			stateAbbreviation: selectedLocation ? selectedLocation.state : null
		};
	}

	/* @startCleanup encore */
	/**
	 * Get data object to be passed to HelpPopover component
	 * @return {object}
	 */
	_getHelpPopoverData = () => {
		const contentOne = $.__('Rule of thumb?');
		const contentTwo = $.__(`The state you select for this option should generally be paid to the employee's work state, but there are exceptions!`);
		const contentThree = $.__(`If you're not sure what to choose, check out %1$sthis helpful guide%2$s from the Department of Labor.`, '<a href="https://workforcesecurity.doleta.gov/unemploy/uitaxtopic.asp" target="_blank" rel="noopener noreferrer">', '</a>');

		return {
			title: $.__('State Unemployment Insurance'),
			content: `${ contentOne } ${ contentTwo } ${ contentThree }`
		};
	};
	/* @endCleanup encore */

	/**
	 * Get data object to be passed to Select component
	 * @return {object}
	 */
	_getSelectData = () => {
		const {
			fields,
			viewOnly,
		} = this.props;
		const { location: currentLocation } = this.state;

		const options = fields.locations.map((location) => {
			const option = {};
			option.selected = location.val === currentLocation;
			option.value = location.val;
			option.state = location.state;
			option.displayText = location.displayText;
			return option;
		});

		return {
			data: {
				items: options,
			},
			onChange: this._handleLocationChange,
			settings: {
				name: 'uiState[location]',
				placeholder: `-- ${ $.__('Select') } --`,
				disabled: viewOnly,
				required: true
			},
			disabled: viewOnly,
			className: 'xlong StateUnemployment__location js-uiStateLocationSelect',
			width: 5
		};
	};

	// Event handlers
	_handleLocationChange = (event) => {
		const { onLocationChange } = this.props;
		$('.js-uiStateLocationFieldBox').removeClass('error');
		this.setState({
			location: event ? event.value : '',
			stateAbbreviation: event ? event.state : null
		});
		if (onLocationChange) {
			onLocationChange(event);
		}
	};

	_handleExemptChange = event => this.setState({
		exempt: event.target.checked
	});

	// Render different fieldboxes/rows
	_renderLocationSelect = () => {
		const {
			stateAbbreviation,
		} = this.state;
		const { showLocationRequiredAsterisk } = this.props;

		const noteClass = 'fab-FormNote';
		const labelClass = c('fab-Label', {
			// Making the required asterisk opt-in for now as there might be pre-existing usages that wouldn't want it to show?
			'fab-Label--required': !!showLocationRequiredAsterisk,
		});

		return (
			<div className="fieldBox js-uiStateLocationFieldBox required">
				<label className={ labelClass } htmlFor="uiStateLocation">{ $.__('Location') }</label>
				<div className="fieldDiv">
					<Select id="uiStateLocation" { ...this._getSelectData() } />
				</div>
				{ stateAbbreviation && (
					<span className={ noteClass }>
						{ $.__('State: %1$s', stateAbbreviation) }
					</span>
				) }
			</div>
		);
	};

	_renderExemptCheckbox = () => {
		const { exempt } = this.state;
		const { viewOnly } = this.props;

		return (
			<div className="fieldDiv StateUnemployment__check">
				<div className="fab-Checkbox">
					<input
						className="fab-Checkbox__input"
						defaultChecked={ exempt ? 'checked' : null }
						disabled={ viewOnly }
						id="uiExempt"
						name="uiState[taxExempt]"
						onChange={ this._handleExemptChange }
						type="checkbox"
					/>
					<span className="fab-Checkbox__box"></span>
					<label className="fab-Checkbox__label" htmlFor="uiExempt">{ $.__('State UI tax exempt') }</label>
				</div>
			</div>
		);
	};

	_renderOptionalFields = () => {
		const { fields } = this.props;
		const { location } = this.state;
		const selectedLocation = fields.locations ? fields.locations.find(option => option.val === location) : null;

		if (!(selectedLocation && selectedLocation.optionalFields)) {
			return null;
		}

		const {
			allIds: optionalFieldIds,
			byId: optionalFieldsById,
		} = selectedLocation.optionalFields;

		return optionalFieldIds.map((id) => {
			const field = optionalFieldsById[id];
			const FieldComponent = OPTIONAL_FIELDS_MAP[field.type];

			return (
				<FieldComponent
					data={ field.data }
					help={ field.help || null }
					id={ id }
					isUnemploymentField={ true }
					key={ id }
					required={ field.required }
					viewOnly={ false }
				/>
			);
		});
	};

	render() {
		const {
			accessLevel,
			editPermission,
			fields,
			isAdminUser,
			isPayrollAdminUser,
			sectionNote,
			showUnemployment,
			viewOnly,
		} = this.props;
		/* @startCleanup encore */
		const fieldSetClass = c(
			{ 'fab-FormSection': true },
			{ 'fab-FormSection--hidden': showUnemployment === false }
		);
		const legendClass = 'fab-FormSection__legend baseColor';
		/* @endCleanup encore */

		const contentOne = $.__('Rule of thumb?');
		const contentTwo = $.__(`The state you select for this option should generally be paid to the employee's work state, but there are exceptions!`);
		const contentThree = $.__(`If you're not sure what to choose, check out [this helpful guide] from the Department of Labor.`);

		const content = `${ contentOne } ${ contentTwo } ${ contentThree }`;

		return ifFeature('encore',
			(
				<SectionStyledBox
					hidden={ showUnemployment === false }
					paddingTop={ 2 }
				>
					<fieldset>
						<SectionStyledHeader
							hasPopover={ true }
							popoverContent={ (
								<Message
									// eslint-disable-next-line new-cap
									link={ ExternalLink('https://workforcesecurity.doleta.gov/unemploy/uitaxtopic.asp') }
									text={ content }
								/>
							) }
							popoverIcon="circle-question-regular"
							popoverPlacement="bottom"
							popoverTitle={ $.__('State Unemployment Insurance') }
						>
							{ $.__('State Unemployment Insurance') }
						</SectionStyledHeader>
						{ sectionNote && (
							<div className='StateUnemployment__sectionNoteContainer'>
								<span className='StateUnemployment__sectionNoteIcon'>
									<IconV2 name="circle-info-solid" size={ 16 } />
								</span>
								<p className='StateUnemployment__sectionNoteText'>
									{ sectionNote }
								</p>
							</div>
						) }
						<Flex alignItems="center" marginTop={ 2 }>
							<div className="fieldRow">
								{ fields.locations !== null && this._renderLocationSelect() }
								{ (feAccessLevelsQuickForms && accessLevel !== undefined) ? (
									<>
										{ ((editPermission || viewOnly) && fields.exempt) && this._renderExemptCheckbox() }
									</>
								) : (
									<>
										{ ((isAdminUser || isPayrollAdminUser) && fields.exempt) && this._renderExemptCheckbox() }
									</>
								) }
							</div>
						</Flex>

						{ this._renderOptionalFields() }
					</fieldset>
				</SectionStyledBox>
			),
			(
				<fieldset className={ fieldSetClass }>
					<legend className={ legendClass }>
						{ $.__('State Unemployment Insurance') }
						<HelpPopover { ...this._getHelpPopoverData() } />
					</legend>
					{ sectionNote && (
						<div className='StateUnemployment__sectionNoteContainer'>
							<span className='StateUnemployment__sectionNoteIcon'>
								<InfoCircle15x15 />
							</span>
							<p className='StateUnemployment__sectionNoteText'>
								{ sectionNote }
							</p>
						</div>
					) }
					<div className="fieldRow">
						{ fields.locations !== null && this._renderLocationSelect() }
						{ (feAccessLevelsQuickForms && accessLevel !== undefined) ? (
							<>
								{ ((editPermission || viewOnly) && fields.exempt) && this._renderExemptCheckbox() }
							</>
						) : (
							<>
								{ ((isAdminUser || isPayrollAdminUser) && fields.exempt) && this._renderExemptCheckbox() }
							</>
						) }
					</div>

					{ this._renderOptionalFields() }
				</fieldset>
			)
		);
	}
}
