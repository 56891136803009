import { useEffect, useMemo, useState, ReactElement } from 'react';
import moment from 'moment';
import { ItemType, MultiStepModal, StandardModal } from '@bamboohr/fabric';
import { getCancellationFeatureReasonOptionsAll, getNextBillingDates, submitCancellation } from 'in-app-cancellation.mod/services';
import { merge, noop } from 'lodash';
import { ConfirmationSheet, StandardCancellationForm, StandardCancellationFormProductKey } from './components';
import { CancellationItemsMap } from './constants';
import {
	CancellationContentProps,
	CancellationFormsData,
	CancellationModalProps,
	CancellationType,
	CancellationRequestData,
	CancellationReasons,
} from './types';

const BI_ID = 'in-app-cancellation-modal';

const getStepHeader = (title: string) => {
	return <StandardModal.Header title={title} />;
};

export const CancellationModal = ({ isOpen, onClose, onSubmitSuccess = noop, types }: CancellationModalProps) => {
	const [currentStep, setCurrentStep] = useState(0);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [formValues, setFormValues] = useState<CancellationFormsData>({});
	const [isProcessing, setIsProcessing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [reasons, setReasons] = useState<CancellationReasons>({});
	const [nextBillingDates, setNextBillingDates] = useState<ItemType[]>([]);
	const hasReasonsData = !!Object.keys(reasons).length;

	useEffect(() => {
		if (isOpen && !hasReasonsData) {
			setIsLoading(true);
			const reasonsPromise = getCancellationFeatureReasonOptionsAll();
			const nextBillingDatesPromise = getNextBillingDates();

			Promise.all([reasonsPromise, nextBillingDatesPromise])
				.then(([reasonsResp, nextBillingDatesResp]) => {
					const reasonItems = reasonsResp.data.reduce((acc, item) => {
						acc[item.feature] = item.options.map((option) => ({
							text: option.label,
							value: option.value,
						}));
						return acc;
					}, {});

					const billingDatesItems = nextBillingDatesResp.data.next_billing_dates.map((date) => {
						return {
							text: moment(date).format('DD MMM YYYY'),
							value: date,
						} as ItemType;
					});
					// adding the note at the begining of the list
					billingDatesItems.unshift({
						isDisabled: true,
						text: $.__('Cancellations can only be processed on billing dates.'),
						value: 'note',
					});

					setReasons(reasonItems);
					setNextBillingDates(billingDatesItems);
				})
				.catch(() => {
					window.setMessage($.__('Oops! Looks like we had some trouble loading the form. Refresh the page and try again!'), 'error');
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [isOpen]);

	const resetModalState = () => {
		setCurrentStep(0);
		setFormValues({});
	};

	const handleCloseModal = () => {
		onClose();
	};

	const handleSubmit = () => {
		if (!isProcessing) {
			setIsProcessing(true);

			const formattedData = Object.entries(formValues).reduce(
				(acc, [key, value]) => {
					if (Object.values(CancellationType).includes(key as CancellationType) && typeof value === 'object') {
						acc.cancellations.push(value);
					} else {
						acc[key] = value;
					}
					return acc;
				},
				{ cancellations: [] } as CancellationRequestData
			);
			const successMessage = `Your account cancellation request has been sent to our team! Further instructions will be emailed shortly.`;

			submitCancellation(formattedData)
				.then(() => {
					window.setMessage(successMessage, 'success');
					handleCloseConfrimation();
					handleCloseModal();
					onSubmitSuccess();
				})
				.catch(() => {
					window.setMessage(
						$.__('Oops! Looks like we had some trouble submitting your request. Refresh the page and try again!'),
						'error'
					);
				})
				.finally(() => {
					setIsProcessing(false);
				});
		}
	};

	const handleNextStep =
		(type: CancellationType | null, isLastStep: boolean): CancellationContentProps['onNext'] =>
		(data) => {
			if (data) {
				setFormValues((prevState) => {
					const newData = type
						? {
								[type]: {
									feature: type,
									...data,
								},
								...(data.cancellation_date ? { cancellation_date: data.cancellation_date } : {}),
						  }
						: data;

					return merge(prevState, newData);
				});
			}

			if (isLastStep) {
				handleOpenConfirmation();
			} else {
				setCurrentStep((prevStep) => prevStep + 1);
			}
		};

	const handleOpenConfirmation = () => {
		setConfirmationOpen(true);
	};

	const handleCloseConfrimation = () => {
		setConfirmationOpen(false);
	};

	const handlePrevStep = () => {
		setCurrentStep((prevStep) => prevStep - 1);
	};

	const steps = useMemo(() => {
		const [standardFormTypes, customFormTypes] = types.reduce(
			(acc, type) => {
				const itemData = CancellationItemsMap[type];
				acc[itemData?.content ? 1 : 0].push(type);
				return acc;
			},
			[[], []] as CancellationType[][]
		);

		const modalSteps: ReactElement[] = [];

		const sharedProps = {
			nextBillingDates,
			onClose: handleCloseModal,
			reasons,
			setIsLoading,
		};

		if (standardFormTypes.length) {
			const isLastStep = customFormTypes.length === 0;
			const key = `${BI_ID}-standard-form`;
			const title = standardFormTypes.includes(CancellationType.ACCOUNT)
				? CancellationItemsMap[CancellationType.ACCOUNT].title
				: $.__('Cancel BambooHR Products');

			modalSteps.push(
				<StandardCancellationForm
					biId={key}
					isLastStep={isLastStep}
					key={key}
					onNext={handleNextStep(null, isLastStep)}
					productKeys={standardFormTypes as StandardCancellationFormProductKey[]}
					renderHeader={getStepHeader(title)}
					{...sharedProps}
				/>
			);
		}

		if (customFormTypes.length) {
			const customFormSteps = customFormTypes.reduce((acc, type, i) => {
				const { content, title } = CancellationItemsMap[type];
				const isLastItem = i === customFormTypes.length - 1;

				const customFormComponents = content.map((Content, j) => {
					const isFirstStep = !modalSteps.length && i === 0 && j === 0;
					const isLastContent = j === content.length - 1;
					const isLastStep = isLastItem && isLastContent;
					const key = `in-app-cancellation-modal-${type}-${j}`;

					return (
						<Content
							biId={key}
							formValues={formValues[type] || {}}
							isLastStep={isLastStep}
							key={key}
							onNext={handleNextStep(type, isLastStep)}
							onPrevious={!isFirstStep ? handlePrevStep : undefined}
							renderHeader={getStepHeader(title)}
							types={types}
							{...sharedProps}
						/>
					);
				});
				acc.push(...customFormComponents);
				return acc;
			}, [] as ReactElement[]);

			modalSteps.push(...customFormSteps);
		}
		return modalSteps;
	}, [types, currentStep, reasons, nextBillingDates]);

	const productName = types.length === 1 ? CancellationItemsMap[types[0]]?.productName : undefined;

	const cancellationSheet = (
		<ConfirmationSheet
			isOpen={confirmationOpen}
			isProcessing={isProcessing}
			onRequestClose={handleCloseConfrimation}
			onSubmit={handleSubmit}
			productName={productName}
		/>
	);

	if (steps.length > 1) {
		return (
			<>
				<MultiStepModal
					biId={BI_ID}
					currentStep={currentStep}
					isLoading={isLoading}
					isOpen={isOpen}
					onCloseComplete={resetModalState}
					onRequestClose={handleCloseModal}
					steps={steps}
				/>
				{cancellationSheet}
			</>
		);
	}

	return (
		<>
			<StandardModal
				isLoading={isLoading}
				isOpen={isOpen}
				isProcessing={isProcessing}
				onCloseComplete={resetModalState}
				onRequestClose={handleCloseModal}
			>
				{steps[0]}
			</StandardModal>
			{cancellationSheet}
		</>
	);
};
