export default function loadPreview(loadingUrl, documentUrl, $container) {
	return Promise.all( [
		// Convert from jQuery 1.8 promise to ES6 promise.
		new Promise( (resolve, reject) => {
			$.get(loadingUrl).then(resolve, reject);
		}),
		import('./bambooHRPDFViewer.async.js' /* webpackChunkName: "pdfjs" */),
	]).then( ([html, bambooHRPdfViewerImport,])=> {
		const {
			bambooHRPdfViewer
		} = bambooHRPdfViewerImport;
		if (window.PDFViewer) {
			window.PDFViewer._initialize(html, documentUrl, $container);
		} else {
			window.PDFViewer = new bambooHRPdfViewer(html, documentUrl, $container);
		}
	});
}
