import { FunctionComponent, useEffect, useState } from 'react';
import { Fields, getField, getSelectedValue, isSelectedPSSyncing, forceFormRender } from 'dynamic-form';

const taxEmployeeTypeId1099 = 3;

const PayrollFieldSet: FunctionComponent<DynamicForm.FieldSetElementProps> = (fieldSetProps) => {
	const {
		props,
		children,
		context,
	} = fieldSetProps;
	const { className = '' } = props;
	const [show, setShow] = useState(false);

	const isSyncing = isSelectedPSSyncing(context);
	const taxTypeField = getField(context, Fields.employeeTaxType) as DynamicForm.SelectElement;
	const taxTypeValue = getSelectedValue(taxTypeField);

	useEffect(() => {
		// only show the payroll fields if syncing and TT isn't 1099
		if (isSyncing) {
			if (taxTypeValue && taxTypeValue !== taxEmployeeTypeId1099.toString()) {
				setShow(true);
				return;
			}
		}
		setShow(false);
	}, [isSyncing, taxTypeValue]);

	useEffect(() => {
		// we only need to force re-render if true (so children properly get set required)
		if (show) {
			setTimeout(() => {
				forceFormRender(context);
			}, 10);
		}
	}, [show]);

	return (
		<fieldset { ...props } className={ `fab-FormSection ${ className } ${ show ? '' : 'hidden' }` }>
			{ children }
		</fieldset>
	);
};

export default PayrollFieldSet;
