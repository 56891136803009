import {Fragment} from 'react';
import Ajax from '@utils/ajax';
import biWeeklySelected from 'BiWeekly.mod';
import {Message} from '@bamboohr/utils/lib/message';

import {createModifierFromDomData, prepareModifierDataForDom} from './modifier';

import './styles.styl';


/**
 * Get {numDays} of business days in the future
 *
 * @param {moment} originalDate Original date as a {moment} object
 * @param {Number} numDays      Number of days in the future to go
 * @param {Array}  bankHolidays Any bank holidays to also skip (as an array of date strings)
 * @returns moment
 */
function getBusinessDaysInTheFutureFromDate(originalDate, numDays, bankHolidays) {
	if (numDays <= 0) {
		return originalDate;
	}

	let count = 1;
	let date = originalDate.clone();
	while (count <= numDays) {
		date.add(1, 'days');
		const dayOfWeek = date.day();

		if (!(dayOfWeek === 0 || dayOfWeek === 6 || bankHolidays.indexOf(date.format('YYYY-MM-DD')) > -1)) {
			++count;
		}
	}

	return date;
}

function setupForm(modalData) {
	const {paySchedule} = modalData;
	if (!paySchedule.id) {
		return;
	}

	const $formSelects = $('.js-ps-add-edit-form select');
	const $frequencySelects = $('.js-no-edit-container select');
	const $frequencySelect = $('select.js-ps-frequency-dropdown');

	$frequencySelect.val(paySchedule.frequency);

	if (paySchedule.count > 0 || modalData.hasTimesheetsAssociatedToPaySchedule) {
		$frequencySelects.attr('disabled', 'disabled');
	}

	setFrequencyValues(paySchedule.frequency, paySchedule.modifier);

	$formSelects.trigger('liszt:updated');
}

function setFrequencyValues(frequency, modifier) {
	const modifierValue = prepareModifierDataForDom(frequency, modifier);

	if (frequency === 'bi-weekly') {
		$('[data-ps-section="bi-weekly"] .js-biweekly-start-input').val(modifierValue);
	} else {
		$(`[data-ps-section="${ frequency }"] select`)
			.each((i, select) => {
				select.value = modifierValue[i]
			});
	}
}

function launchAddEditModal(paySchedule = {}, saveCallback = Function.prototype, setCalendarState) {
	const urlEnding = paySchedule.id ? `${ paySchedule.id }/editDialog` : 'addDialog';

	window.SimpleModal.openModal({
		url: `/settings/pay_schedules/${ urlEnding }`,
		title: paySchedule.id ? $.__('Edit Pay Schedule') : $.__('Add Pay Schedule'),
		classes: 'jquery-padding no-chosen-breakout',
		footer: {
			buttons: {
				primary: {
					text: paySchedule.id ? $.__('Save') : $.__('Add'),
					action() {
						submitForm(saveCallback);
					}
				},
				secondary: {
					show: true,
					text: $.__('Preview'),
					action: () => showPayCalendar(getPayloadFromForm(), true, setCalendarState),
				}
			}
		},
		callbacks: {
			open: {
				after() {
					initialize();
				}
			}
		}
	});
}

function launchDeleteModal(paySchedule) {
	const modalTitle = $.__('Delete Pay Schedule');

	Ajax.get(`/settings/pay_schedules/${ paySchedule.id }/delete`)
		.then(({data}) => {
			const {count} = data;
			window.BambooHR.Modal.setState({
				isOpen: true,
				title: modalTitle,
				headline: (
					<Message
						params={ [paySchedule.name] }
						text={ $._('Are you sure you want to delete the **"{1}"** pay schedule?') }
					/>
				),
				type: 'aggressiveConfirmation',
				primaryActionText: $.__('Delete Pay Schedule'),
				primaryAction: () => {
					window.BambooHR.Modal.setState({isProcessing: true}, true);
					Ajax.delete(`/settings/pay_schedules/${ paySchedule.id }/delete`)
						.then(({ data: deleteData }) => {
							if (deleteData.ret === 'ERROR') {
								window.setMessage(deleteData.message, 'error');
								window.BambooHR.Modal.setState({isProcessing: false}, true);
							} else {
								window.location.reload(true);
							}
						})
						.catch(() => {
							window.errorFallBack();
							window.BambooHR.Modal.setState({isProcessing: false}, true);
						});
				},
				secondaryActionText: $.__('Keep Pay Schedule'),
				secondaryAction: () => {
					window.BambooHR.Modal.setState({isOpen: false});
				},
				alternativeAction: null,
				content: count > 0 && (
					<Fragment>
						{ count === 1 && (
							<Message
								key="first-part"
								params={ [count] }
								text={ $._('You have **{1} active employee** with this pay schedule.') }
							/>
						) }
						{ count !== 1 && (
							<Message
								key="first-part"
								params={ [count] }
								text={ $._('You have **{1} active employees** with this pay schedule.') }
							/>
						) }
						{ ' ' }
						<Message
							key="second-part"
							text={ $._('We suggest moving employees to a new pay schedule before deleting this one.') }
						/>
					</Fragment>
				),
			});
		})
		.catch(() => {
			window.setMessage($.__('We were unable to delete your pay schedule. Please try again.'), 'error');
		});
	
}

export function submitForm(saveCallback, failCallback) {
	const $AddEditPayScheduleForm = $('#payschedule_form');
	const inModal = !!window.SimpleModal.getTopModalSelector();

	if (!$AddEditPayScheduleForm.valid()) {
		return;
	}

	if (inModal) {
		window.SimpleModal.Footer.Buttons.Primary.startProcessing();
	}

	const payload = getPayloadFromForm();

	savePaySchedule(payload, saveCallback, failCallback);
}

export function savePaySchedule(payload, saveCallback, failCallback) {
	const inModal = !!window.SimpleModal.getTopModalSelector();
	$.post('/settings/pay_schedules/save', payload, (returnData) => {
		if (inModal) {
			window.SimpleModal.Footer.Buttons.Primary.stopProcessing();
		}
		if (returnData.ret === 'ERROR') {
			window.setMessage(returnData.message, "error");
			failCallback();
		} else {
			saveCallback(returnData);
			if (inModal) {
				window.SimpleModal.closeModal();
			}
		}
	}, 'json')
		.fail(() => {
			window.errorFallBack();
			failCallback();
		});
}

let isRequestingPayCalendar = false;
export function showPayCalendar(data, showAsPreview = false, setCalendarState) {
	if (isRequestingPayCalendar) {
		return;
	}
	isRequestingPayCalendar = true;

	Ajax
		.get(`/settings/pay_schedules/calendar-preview${ showAsPreview ? '' : 'ById' }`, data)
		.then(({data: {payCycles: payPeriods, bankHolidays = []}}) => {
			setCalendarState(data.name, bankHolidays.map(day => day.date), true, showAsPreview, payPeriods);
		})
		.catch(window.errorFallBack)
		.finally(() => {
			isRequestingPayCalendar = false;
		});
}

export function getPayloadFromForm() {
	const $AddEditPayScheduleForm = $('#payschedule_form');
	const frequency = $('select.js-ps-frequency-dropdown').val();

	return {
		frequency,
		id: $AddEditPayScheduleForm.find('[name=id]').val(),
		listValueId: $AddEditPayScheduleForm.find('[name=listValueId]').val(),
		name: $AddEditPayScheduleForm.find('[name=name]').val(),
		modifier: getModifier(frequency),
		payDateDays: $AddEditPayScheduleForm.find('[name=payDateDays]').val(),
		weekendAndHoliday: $AddEditPayScheduleForm.find('[name=weekendAndHoliday]').val(),
	};
}

function getModifier(frequency) {
	let data;

	if (frequency === 'bi-weekly') {
		data = $('[data-ps-section="bi-weekly"] .js-biweekly-start-input').val();
	} else {
		data = $(`[data-ps-section="${ frequency }"] select`)
			.map((i, select) => select.value)
			.toArray();
	}

	return createModifierFromDomData(frequency, data);
}

export function initialize() {
	const $jsonBlob = $('#js-ps-modal-data');
	const modalData = JSON.parse($jsonBlob.html());
	const $AddEditPayScheduleForm = $('#payschedule_form');

	setupForm(modalData);
	biWeeklySelected.setFields();

	const $frequencyDropdown = $('.js-ps-frequency-dropdown');
	$frequencyDropdown.change(function () {
		const $dropdown = $(this);
		const dataType = $dropdown.find('option:selected').val();
		const selector = `[data-ps-section="${ dataType }"]`;
		$('.js-ps-sub-field').hide().filter(selector)
			.show()
			.initializeElements();
	});

	const currentType = $frequencyDropdown.find('option:selected').val();
	$(`.js-ps-sub-field[data-ps-section="${ currentType }"]`)
		.show()
		.initializeElements();

	$AddEditPayScheduleForm.bhrValidate({
		rules: {
			'semimonthlyMod1[]': {sameSiblingValue: 'select.js-semimonthly'},
			'semimonthlyMod2[]': {sameSiblingValue: 'select.js-semimonthly'},
		},
	});

	if (window.SimpleModal.getTopModalSelector()) {
		window.SimpleModal.setPosition();
		// action tracking
		window.SimpleModal.Footer.Buttons.Primary.setAttr('data-bi-id', 'settings-add-pay-schedule-button');
	}
}

export function addModal(saveCallback, setCalendarState) {
	launchAddEditModal({}, saveCallback, setCalendarState);
}

export function editModal(paySchedule, saveCallback, setCalendarState) {
	launchAddEditModal(paySchedule, saveCallback, setCalendarState);
}

export function removeModal(paySchedule) {
	launchDeleteModal(paySchedule);
}

export function previewModal(paySchedule, setCalendarState) {
	showPayCalendar(paySchedule, false, setCalendarState);
}

export default {
	addModal,
	editModal,
	getBusinessDaysInTheFutureFromDate,
	removeModal,
	previewModal,
};
