import moment from 'moment.lib';
import {
	cloneDeep,
	flow,
	last,
	values,
} from 'lodash';
import { sortBy } from 'lodash/fp';
import { isEnabled } from 'FeatureToggle.util';

import {
	calculateTimeEarned,
	getDaysFromMap,
} from 'time-tracking/utils';

import {
	getIsApprovalShowing,
	getIsOwnTimesheet,
	makeWeekMetaObject,
	makeTimesheetMetaObject,
	addDataToDays,
} from './utils';

const multipleEntriesByHourEnabled = isEnabled('multipleEntriesByHour');

export default function normalizeData(data) {
	data = cloneDeep(data);

	const {
		employeeId,
		employeeName,
		employeePhotoUrl,
		employeeTimesheets,
		currentTimesheetId: latestTimesheetId,
		previousTimesheetId: latestPreviousTimesheetId,
		previousTimesheet,
		timesheet,
		nextTimesheet,
		lastWeek,
		thisWeek,
		timesheetOptions,
		today,
		thisWeekHours: weekTotal,
		projectsWithTasks,
	} = data;

	const serverTime = moment.tz(today.date, today.timezone);
	const focusedTimesheet = makeTimesheetMetaObject(timesheet);
	const isLatestTimesheet = focusedTimesheet.id === latestTimesheetId;
	const isOwnTimesheet = getIsOwnTimesheet();
	const isApprovalShowing = getIsApprovalShowing(timesheet, serverTime, focusedTimesheet.type, isLatestTimesheet, isOwnTimesheet);

	/**
	 * If there is no timesheet, we need to show a blank state.
	 */
	if (focusedTimesheet.type === 'none') {
		return {
			isOwnTimesheet,
			isApprovalShowing,
			employeeTimesheets,
			timesheetOptions,
			focusedTimesheet: {
				id: -1,
				type: 'none',
			},
		};
	}

	// We only show the last years worth of timesheets in the timesheet header dropdown.
	// However, a timesheet can still be accessed via and ID in the URL, if they land on a
	// timesheet that is not within the last year's timesheets, then add it to the dropdown
	if (!employeeTimesheets.find(sheet => Number(sheet.id) === Number(focusedTimesheet.id))) {
		employeeTimesheets.push(focusedTimesheet);
	}

	const days = addDataToDays({
		...(isLatestTimesheet ? lastWeek : {}),
		...(isLatestTimesheet ? thisWeek : {}),
		...(previousTimesheet ? previousTimesheet.dailyDetails : {}),
		...timesheet.dailyDetails,
		...(nextTimesheet ? nextTimesheet.dailyDetails : {}),
	}, serverTime, today.timezone);

	const _today = days[serverTime.format('YYYY-MM-DD')];

	let clock = null;

	if (timesheet.type === 'clock') {
		clock = {
			clockedIn: !!data.lastClockEntry && !data.lastClockEntry.end,
			latest: data.lastClockEntry,
			earned: 0,
			midnightTotals: 0,
		};

		if (clock.clockedIn) {
			const lastDayInViewMoment = flow(values, sortBy('date'), last)(days).moment;
			const latestClockInMoment = moment.tz(clock.latest.start, clock.latest.timezone);
			const isLastClockInDayVisible = lastDayInViewMoment.isSameOrAfter(latestClockInMoment, 'day');
			const isServerDaySameAsLastClockDay = serverTime.isSame(latestClockInMoment, 'day');

			if (isLastClockInDayVisible && !isServerDaySameAsLastClockDay) {
				const startDay = latestClockInMoment.format('YYYY-MM-DD');
				const endDay = serverTime.format('YYYY-MM-DD');
				// Add `hasMidnightEntry: true` to days with faked midnight entries
				getDaysFromMap(startDay, endDay, days).forEach((day) => {
					day.hasMidnightEntry = true;
				});
				clock.midnightTotals = calculateTimeEarned(latestClockInMoment, serverTime.clone().startOf('day'));
			}

			if (_today) {
				const lastClockEntry = last(_today.clockEntries);
				const startMoment = moment.tz(lastClockEntry.start, lastClockEntry.timezone);
				clock.earned = calculateTimeEarned(startMoment, serverTime);
				_today.hours = _today.clockEntries.reduce((a, b) => a + b.hours, clock.earned);
			}
		}
	}

	const summaryTotals = {
		weekTotal,
		periodTotal: timesheet.totalHours,
		periodOvertime: timesheet.overtimeSummary,
		periodTimeOff: timesheet.totalTimeOffHours,
		periodHoliday: timesheet.totalHolidayHours,
	};

	return {
		isApprovalShowing,
		isOwnTimesheet,
		isLatestTimesheet,
		serverTime,
		timesheetOptions,
		employeeTimesheets,
		employee: {
			id: employeeId,
			name: employeeName,
			photoUrl: employeePhotoUrl,
			timezone: today.timezone,
		},
		clock,
		workflow: {
			approvableDate: timesheet.approvableDate,
			approvableMoment: moment.unix(timesheet.approvableDate),
			approvalDate: timesheet.approvalDate,
			approvalMoment: moment.unix(timesheet.approvalDate),
			approverName: timesheet.approverName,
			dueDate: timesheet.dueDate,
			dueMoment: moment.unix(timesheet.dueDate),
			hoursLastChangedAt: timesheet.hoursLastChangedAt,
			hoursLastChangedAtMoment: moment.unix(timesheet.hoursLastChangedAt),
			status: timesheet.status,
			userCanApprove: timesheet.canApprove,
			userCanEdit: timesheet.showEditActions,
		},
		focusedTimesheet,
		summaryTotals,
		padStartTimesheet: previousTimesheet && makeTimesheetMetaObject(previousTimesheet),
		padEndTimesheet: nextTimesheet && makeTimesheetMetaObject(nextTimesheet),
		latestTimesheetId,
		latestPreviousTimesheetId,
		lastWeek: makeWeekMetaObject(lastWeek),
		thisWeek: makeWeekMetaObject(thisWeek),
		days,
		projectsWithTasks: projectsWithTasks || { allIds: [], byId: {} },
	};
}
