import { cloneDeep } from 'lodash';

import { PureComponent, ReactElement } from 'react';

import { BodyText, Checkbox, Flex, Icon, LayoutBox, Section, TextButton } from '@bamboohr/fabric';

import { Message } from '@bamboohr/utils/lib/message';

import { EmailFilterContainer } from '../../containers/email-filter';
import { GtkyQuestionList } from './gtky-question-list';

import { EmailFilterData, Question } from '../../Interfaces';

import './styles.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface StoreData {
	gtkyQuestions?: Array<Question>;
	sendGetToKnowYouEmail?: 'yes' | 'no' | '' | boolean;
}

interface State {
	currentQuestions: Array<string>;
	customQuestions: { [key: string]: Question };
	sendGetToKnowYouEmail: 'yes' | 'no' | '' | boolean;
}

export interface Props extends State {
	emailFilterData: EmailFilterData;
	employeeId?: number | string;
	employeeName: string;
	previewNewHireEmail: (id: number | string, questions: Array<string>) => void;
	storeUpdater?: (arg0: StoreData) => void;
}

export class TeamIntroduction extends PureComponent<Props, State> {
	static defaultProps = {
		customQuestions: {},
	};
	constructor(props: Props) {
		super(props);

		const { storeUpdater } = props;

		const clonedQuestions = cloneDeep(props.customQuestions);

		if (Object.keys(clonedQuestions).length < 1) {
			const question: Question = {
				archived: '',
				id: '0',
				is_required: '',
				question: '',
				sort_order: '0',
			};

			delete question.is_required;
			question.required = false;

			clonedQuestions[0] = question;
		}

		this.state = {
			currentQuestions: this.getQuestionArray(Object.values(clonedQuestions)),
			customQuestions: clonedQuestions,
			sendGetToKnowYouEmail: props.sendGetToKnowYouEmail,
		};

		if (typeof storeUpdater === 'function') {
			storeUpdater({
				gtkyQuestions: Object.values(clonedQuestions),
				sendGetToKnowYouEmail: props.sendGetToKnowYouEmail,
			});
		}

		this._displayEmailPreview = this._displayEmailPreview.bind(this);
		this._handleToggleGTKYQuestions = this._handleToggleGTKYQuestions.bind(this);
		this.updateCurrentQuestions = this.updateCurrentQuestions.bind(this);
	}

	_displayEmailPreview(id: number | string, questions: Array<string>): void {
		return this.props.previewNewHireEmail?.(id, questions);
	}

	_handleToggleGTKYQuestions(): void {
		const {
			storeUpdater = () => {
				return 0;
			},
		} = this.props;

		const { sendGetToKnowYouEmail } = this.state;

		// @ts-ignore - temporary fix, TODO: Remove with updated task save
		window.formChanged = true;
		this.setState(
			{
				sendGetToKnowYouEmail: sendGetToKnowYouEmail === 'yes' || sendGetToKnowYouEmail === true ? false : true,
			},
			() => {
				storeUpdater({ sendGetToKnowYouEmail: this.state.sendGetToKnowYouEmail });
			}
		);
	}

	getQuestionArray(questions: Array<Question>): Array<string> {
		return questions.map((question): string => {
			return question.question;
		});
	}

	updateCurrentQuestions(questions: Array<Question>): void {
		const {
			storeUpdater = () => {
				return 0;
			},
		} = this.props;

		// @ts-ignore - temporary fix, TODO: Remove with updated task save
		window.formChanged = true;
		this.setState(
			{
				currentQuestions: this.getQuestionArray(questions),
			},
			() => {
				storeUpdater({ gtkyQuestions: questions });
			}
		);
	}

	render(): ReactElement {
		const { emailFilterData, employeeId, employeeName } = this.props;

		const { currentQuestions, customQuestions, sendGetToKnowYouEmail } = this.state;

		const {
			selectData: emailSelectData,
			selectedName: selectedOptionName,
			selectedParentId,
			selectedValues: transformedSelectedValues,
		} = emailFilterData;

		return ifFeature(
			'encore',
			<Section paddingTop='24px'>
				<Section.Header
					description={$.__(`Add a few Get to Know You questions to help the team get to know ${employeeName} a little better.`)}
					icon='hand-wave-solid'
					size='large'
					title={$.__(`Introduce ${employeeName} to the Team`)}
				/>
				<Flex flexDirection='column' marginTop={3}>
					<Checkbox
						checked={sendGetToKnowYouEmail === 'yes' || sendGetToKnowYouEmail === true}
						label={
							<BodyText color='neutral-extra-strong' size='medium' weight='semibold'>
								{$.__('Send a "Get to Know You" email on the morning of their first day')}
							</BodyText>
						}
						onChange={this._handleToggleGTKYQuestions}
						value='sendGetToKnowYouEmail'
					/>
					{(sendGetToKnowYouEmail === 'yes' || sendGetToKnowYouEmail === true) && (
						<Flex flexDirection='column' gap={0.5} marginY={2.5}>
							<label className='fab-Label' htmlFor='EmailFilter'>
								{$.__('Send to')}
							</label>
							<EmailFilterContainer
								selectData={emailSelectData}
								selectedName={selectedOptionName}
								selectedParentId={selectedParentId}
								selectedValues={transformedSelectedValues}
							/>
						</Flex>
					)}
					{(sendGetToKnowYouEmail === 'yes' || sendGetToKnowYouEmail === true) && (
						<LayoutBox>
							<GtkyQuestionList customQuestions={customQuestions} updateQuestionsHandler={this.updateCurrentQuestions} />
						</LayoutBox>
					)}
				</Flex>
			</Section>,
			<div className='TeamIntroduction fab-FormSection'>
				<legend className='fab-FormSection__legend newHirePacketLegend'>
					<Icon brand={true} name='fab-hi-20x20' />
					<span className='fab-FormSection__legendText'>
						<Message params={[employeeName]} text={$._('Introduce {1} to the Team')} />
						<span className='fab-FormSection__legendNote'>
							<Message
								params={[employeeName]}
								text={$._('Add a few Get to Know You questions to help the team get to know {1} a little better.')}
							/>
						</span>
					</span>
				</legend>
				<div className='TeamIntroduction__FormSection--indent'>
					<div className='fab-FormRow fab-FormRow--tight'>
						<div className='TeamIntroduction__gtky'>
							<div className='fab-FormRow'>
								<div className='fab-InputWrapper '>
									<input
										checked={sendGetToKnowYouEmail === 'yes' || sendGetToKnowYouEmail === true}
										className='fab-Checkbox__input'
										id='customQuestions'
										name='newHirePackets[sendGetToKnowYouEmail]'
										onChange={this._handleToggleGTKYQuestions}
										type='checkbox'
									/>
									<label className='fab-Checkbox__label' htmlFor='customQuestions'>
										{$.__('Send a "Get to Know You" email on the morning of their first day')}
									</label>
								</div>
							</div>
							{(sendGetToKnowYouEmail === 'yes' || sendGetToKnowYouEmail === true) && (
								<div className='fab-FormRow fab-FormRow--tight'>
									<div className='TeamIntroductionPreviewEmail'>
										<label className='fab-Label' htmlFor='EmailFilter'>
											{$.__('Send to')}
										</label>
										<EmailFilterContainer
											selectData={emailSelectData}
											selectedName={selectedOptionName}
											selectedParentId={selectedParentId}
											selectedValues={transformedSelectedValues}
										/>
										<span className='TeamIntroductionPreviewEmail__button'>
											<TextButton
												clickAction={() => {
													this._displayEmailPreview(employeeId, currentQuestions);
												}}
												iconBefore='fab-envelope-14x12'
												muted={true}
												size='small'
												type='button'
											>
												{$.__('Preview Email')}
											</TextButton>
										</span>
									</div>
								</div>
							)}
						</div>
					</div>
					{(sendGetToKnowYouEmail === 'yes' || sendGetToKnowYouEmail === true) && (
						<div className='TeamIntroduction__gtkyWrapper' id='customQuestionsSection'>
							<GtkyQuestionList customQuestions={customQuestions} updateQuestionsHandler={this.updateCurrentQuestions} />
						</div>
					)}
				</div>
			</div>
		);
	}
}
