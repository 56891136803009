import {
	StyledBox,
} from '@bamboohr/fabric';
import {
	type ReactNode,
	useContext,
} from 'react';

import {
	WithholdingContext,
} from '../context';

import './section-styled-box.styl';

interface SectionStyledBoxProps {
	children: ReactNode;
	hidden?: boolean;
	paddingBottom: number | undefined;
	paddingLeft?: number;
	paddingTop: number | undefined;
}

export function SectionStyledBox(props: SectionStyledBoxProps) {
	const {
		children,
		hidden = false,
		paddingBottom = undefined,
		paddingLeft = 4,
		paddingTop = undefined,
	} = props;
	const {
		isFullPage,
	}= useContext(WithholdingContext);
	if (!isFullPage) {
		return (
			<div className="SectionStyledBox">
				{ children }
			</div>
		);
	}
	return (
		<StyledBox
			backgroundColor="neutral-forced-white"
			borderRadius="small"
			borderStyle="solid"
			boxShadow="large"
			hidden={ hidden }
			paddingBottom={ paddingBottom }
			paddingLeft={ paddingLeft }
			paddingTop={ paddingTop }
			v2={ true }
		>
			{ children }
		</StyledBox>
	);
}
