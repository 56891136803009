import moment from 'moment.lib';
import AccessLevelSelector from 'access-levels.mod/selector';
import VirtualPageView from 'VirtualPageLogger.service';

import { isEnabled } from 'FeatureToggle.util';
import Ajax from '@utils/ajax';
import { openMissingLocationInformation } from 'time-tracking/modals/missing-data';
import { highlightErrorFields } from 'Form.util';
import { openRegisteredModal } from '../global/forms/forms'

let temporaryExemptStatus = null;

$(function() {
	if (typeof window.registerModalLink === 'function') {
		window.registerModalLink(
			'a.history-modal',
			$.__('Title'),
			$.__('Save'),
			saveHandler,
			{
				open_callback: linkModalOpenHandler,
				privacy_link: true
			},
			preSaveHandler
		);
	}
	hideShowOvertimeRateColumn();

	setTimeout(setupSecureFields);
});

export function openHistoryModal(url, onSave, rowDisplayType, historyId, secondaryAction) {
	openRegisteredModal({
		settings: {
			cancel_message: $.__('Cancel'),
			open_callback: function() {},
			initCompensationModal: function() {
				if ($('#historyEdit.CompensationForm').length > 0) {
					window.Employees.Job.CompensationForm.initializeForm('updateCompensation');
				}
			},
		},
		url,
		chosenModalSize: 'medium',
		saveHandler: onSave,
		options: {
			open_callback: linkModalOpenHandler,
			privacy_link: true
		},
		presubmit: preSaveHandler,
		rowDisplayType,
		historyId,
		secondaryAction,
	});
}

function setupSecureFields() {
	const secureFields = $('tbody tr:not(.template):not(.empty) td[data-secure-field-type]');

	if (!secureFields) {
		return
	}

	secureFields.each((index, element) => {
		const {
			dataset: {
				rowId,
				secureFieldType,
				sensitiveDataVisible = false
			}
		} = element;
		$(element).addClass('fab-Table__cellWithButton')
		const hiddenText = $(element).find('.js-historyCellWrapper').text();

		if (!hiddenText || hiddenText.length === 0) {
			return;
		}

		$(element).click(function(e) {
			e.stopPropagation();

			if ($(element).attr('data-sensitive-data-visible') === 'true') {
				$(element).attr('data-sensitive-data-visible', false);
				$(element).find('.js-historyCellWrapper').text(hiddenText);
				$(element).find('.js-showHideButton').text($.__('Show'));
			} else {
				Ajax.get(`/employees/get_secure_table_field/${secureFieldType}`, { id: window.Employee.id, row: rowId })
					.then((response) => {
						if (response.data && !response.error) {
							$(element).attr('data-sensitive-data-visible', true);
							$(element).find('.js-historyCellWrapper').text(response.data);
							$(element).find('.js-showHideButton').text($.__('Hide'));
						}
					})
					.catch(() => {
						window.setMessage($.__('There was a problem getting the sensitive data'), 'error');
					});
			}
		});

		$(element).attr('data-sensitive-data-visible', sensitiveDataVisible);
		$(element).append(`<button class="fab-TextButton fab-TextButton--small fab-link showHideButton js-showHideButton" tabIndex="0" type="button">
		${ sensitiveDataVisible ? $.__('Hide') : $.__('Show') }
		</button>`);
	});
}

function hideShowOvertimeRateColumn() {
	let overtimeRateTableFields = $('.js-overtimeRateTableField');
	if (overtimeRateTableFields.length === 0) {
		return;
	}

	let hasOneValue = false;
	//Look for any values in the js-overtimeRateTableField history cell wrappers. If we have anything we want to show the column.
	$('td.js-overtimeRateTableField .js-historyCellWrapper').each(function(index, field) {
		if (field.innerHTML.trim() !== '') {
			hasOneValue = true;
			return false;
		}
	});

	//Show or hide all js-overtimeRateTableField elements depending on whether a value was found or not
	overtimeRateTableFields.toggleClass('hidden', !hasOneValue);
}

function preSaveHandler(form) {
	$(form).find('.error').removeClass('error');
	return true;
}

function saveHandler(response) {
	var reload = response.type === 'contacts' && ['OK', 'MIXED_OK', 'REQUEST_OK'].indexOf(response.result) > -1;
	reload = reload || response.result === 'RELOAD_OK';

	if (reload) {
		window.location.reload();
		return;
	}

	if (response.result === 'OK' || response.result === 'MIXED_OK') {
		var $tBody = $('.history-table.type-' + response.type.toLowerCase());
		var $table = $tBody.closest('table');
		var $row = $tBody.find('.id-' + response.data.id);

		var data = response.data;

		if (response.type === 'dependents' && response.result === 'OK') {
			document.location.reload();
		}

		if (!$row.length) {
			$row = addNewRow($table, response.data, response.perms);
			$row.find('a#ctx_new').attr('id', 'ctx' + response.data.id);
		}

		$.each(response.data, function(key, value) {
			$row.find('.field-' + key + ' .js-historyCellWrapper').html(value);
			$row.attr('data-effective-date', data.ymd);
		});

		if (typeof data.sortOrder !== 'undefined') {
			$.each(response.data, function (key, value) {
				$row.find('.field-' + key + ' .js-historyCellWrapper').html(value);
				$row.attr('data-order', data.sortOrder);
			});
		}

		if (typeof response.data2 !== 'undefined') {
			$row = $tBody.find('.id-' + response.data2.id);
			$.each(response.data2, function(key,value) {
				$row.find('.field-' + key + ' p').html(value);
			});
		}

			window.BambooHR.Modal.setState({ isOpen: false });

		// Dispatch custom event upon History Popup Modal save success
		document.dispatchEvent(new CustomEvent('historyModalSaveSuccess', {
			bubbles: true,
			detail: {
				data: response.data
			}
		}));

		if (response.data.stateFilingChange) {
			dispatchLaunchStateTaxModalEvent();
		}

		updateEmployeeInformation();
		updateEmployeeGearMenu();

		if (response.type === 'status') {
			if (typeof window.updateHireDates === 'function') {
				window.updateHireDates();
			}
		}

		refreshRows($table);

		if (response.type !== 'dependents') {
			if (response.result === 'OK') {
				setMessage($.__('Your changes have been saved'), 'success', '#forMessages', function() {});
			} else {
				setMessage($.__('Your changes have been saved, some of them will need to be approved.'), 'success', '#forMessages', function() {});
			}
		}
	} else if (response.result === 'REQUEST_OK') {
		setMessage($.__('A change request has been submitted for the changes entered.'), 'success', '#forMessages' ,function() {});
			window.BambooHR.Modal.setState({ isOpen: false });
	} else if (response.result === 'DUPLICATE_OK') {
		setMessage($.__('No new data was entered.'), 'info', '#forMessages', function() {});
			window.BambooHR.Modal.setState({ isOpen: false });
	} else {
		if (response.data.useErrorMessage !== true && typeof response.data.fields != 'undefined' && response.data.fields.length > 0) {
			setMessage($.__('Whoops... There were some errors with your form. Please fix the highlighted fields.'), 'error');
		} else {
			setMessage(response.data.error, 'error');
		}

		if (response.data.fields) {
			highlightErrorFields(response.data.fields, window.modalIdSelector);
		}
	}

	if (isEnabled('nonExemptWarningModals') && response.type.toLowerCase() === 'compensation') {
		checkMissingLocationInformation(response.data.exempt);
	}

}

export function checkMissingLocationInformation(exempt) {
	if (!(exempt === 'Non-exempt' && temporaryExemptStatus === 'Exempt')) {
		return;
	}
	if (!isEnabled('custom-overtime')) {
		Ajax.get(`/time_tracking/employee/${ window.Employee.id }/overtime_modal_data`)
		.then((res) => {
			if (res.data.isTimeTrackingEnabled) {
				if (res.data.showNoLocationModal) {
					openMissingLocationInformation('no-location', window.Employee.preferredName, false);
				} else if (res.data.showNoStateModal) {
					openMissingLocationInformation('no-state', window.Employee.preferredName, false);
				} else if (res.data.showNoStateRemoteModal) {
					openMissingLocationInformation('no-state-remote', window.Employee.preferredName, false);
				} else if (res.data.showNonOvertimeCountryModal) {
					openMissingLocationInformation('non-overtime-country', window.Employee.preferredName, false);
				}
			}
		});
	}
}

function linkModalOpenHandler(e) {
	$('.history-modal').removeAttr('modalClicked');

	$('.check-dropdown').multiselect({
		noneSelectedText: $.__('-- Select --'),
		header: false,
		height: 'auto'
	});

	window.registerRateChange();

	let $modal = window.SimpleModal.getTopModalSelector();
		$modal = $('.ReactModal__Content');

	const $form = $modal.find('form');
	$form.append('<input type="hidden" name="CSRFToken" value="' + CSRF_TOKEN + '">');
	if ($form.has('.CompensationForm')) {
		temporaryExemptStatus = $form.find('.js-overtimeStatus').val();
	}
}

function addNewRow($table, data, perms) {
	var $template = $table.find('.template');
	var $row = $template.clone();

	var type = $row.find('a.history-modal').attr('url');

	var attrKey = (typeof data.sortOrder !== 'undefined') ? 'data-order' : 'data-effective-date';
	var attrVal = (typeof data.sortOrder !== 'undefined') ? data.sortOrder : moment(data.ymd, 'YYYY-MM-DD');

	$row.addClass('id-' + data.id)
		.attr(attrKey, attrVal)
		.removeAttr('style')
		.removeClass('template');

	$table.find('tr.BhrTable__row.empty').hide();

	//Check edit perms
	if (!perms.edit) {
		$row.find('.inline-bicon-edit').parent().remove();
	}
	//Check delete perms
	if (!perms.delete) {
		$row.find('.inline-bicon-delete').parent().remove();
	}

	$row.insertAfter($template);

	$row.find('a.history-modal').attr('url', getEditUrl(data.id, type));

	return $row;
}

function getOrderedRows($table) {
	var effectiveDateAttribute = 'data-effective-date';
	var rows = $table.find('tbody tr:not(.template):not(.empty)').toArray();

	var rowsWithDates = rows
		.filter(function(elem) {
			var attribute = elem.getAttribute(effectiveDateAttribute);
			return attribute && attribute.length;
		})
		.map(function(row) {
			return {element: row, date: moment(row.getAttribute(effectiveDateAttribute), 'YYYY-MM-DD')};
		})
		.sort(function(item1, item2) {
			return moment(item2.date).diff(item1.date);
		});

	var rowsWithoutDates = rows
		.filter(function(elem) {
			var attribute = elem.getAttribute(effectiveDateAttribute);
			return !attribute || !attribute.length;
		})
		.map(function(row) {
			return {element: row, date: moment.invalid()};
		})
		.sort(function(item1, item2) {
			var item1Text = $(item1.element).find('td:nth-child(2)').text();
			var item2Text = $(item2.element).find('td:nth-child(2)').text();
			return item1Text - item2Text;
		});

	let rowsWithAttribute = rows
		.filter((elem) => {
			let attribute = elem.getAttribute('data-order');
			return attribute && attribute.length;
		})
		.map(row => ({element: row, order: moment(row.getAttribute('data-order'), 'YYYY-MM-DD')}))
		.sort((item1, item2) => moment(item1.order).diff(item2.order));

	return (rowsWithAttribute) ? rowsWithAttribute : rowsWithDates.concat(rowsWithoutDates);
}

function updateMostRecentRow($table) {
	var mostRecentClass = 'BhrTable__cell--most-recent baseBgColor-before';
	$table.find('.field-ymd').removeClass(mostRecentClass);

	var rows = getOrderedRows($table);
	var now = moment();

	var latest = rows.reduce(function(memo, row) {
		return !memo || now.isSameOrAfter(row.date) && now.isBefore(memo.date) ? row : memo;
	}, null);

	if (latest && now.isAfter(latest.date)) {
		$(latest.element).find('.field-ymd').addClass(mostRecentClass);
	}
}

function refreshRows($table) {
	var lastRow = $table.find('.template');
	var rows = getOrderedRows($table);

	rows.forEach(function(row) {
		lastRow.after(row.element);
		lastRow = $(row.element);
		if (typeof row.date !== 'undefined') {
			lastRow.toggleClass('BhrTable__row--future', moment().isBefore(row.date));
		}
	});

	updateMostRecentRow($table);
	hideShowOvertimeRateColumn();
}

function updateEmployeeInformation() {
	if (typeof window.updateInfoColumn === 'function') {
		window.updateInfoColumn();
	}
}

function updateEmployeeGearMenu() {
	$('#employeeGearMenu')
		.load('/ajax/employees/update_gear_menu.php?employeeId=' + window.currentlyEditingEmployeeId + ' #employeeGearMenu > *', function() {
			AccessLevelSelector.init(); // this init function runs on page load, which means it also needs to run after reloading the gear menu
		});
}

function getEditUrl(id, type) {
	const employeeId = (isNaN(window.currentlyEditingEmployeeId) || !window.currentlyEditingEmployeeId) ? -1 : window.currentlyEditingEmployeeId;
	id = (isNaN(id) || !id) ? -1 : id;
	return `/ajax/dialog/history_popup/${ type }/${ employeeId }/${ id }`;
}

function deleteRow(element, type) {
	var row = element.closest('tr');
	var l = row.attr('class').split('id-');
	var m = l[1].split(' ');
	var id = m[0];

	// DO NOT DO: `!id` -> 0 can be a valid ID!
	if (!id.length || id === null || id === undefined || id === 'null' || id === 'undefined') {
		// if id is empty, null, or undefined (or a string literal from the BE) show warning and exit the function
		window.setMessage($.__(`Oops! We can't process your request right now, please refresh the page and try again.`), 'error');
		return false;
	}

	row.addClass('selected');

	var href = this.href;
	var strings = {
		title: $.__('Delete Row'),
		msg: $.__('Are you sure you want to delete this row?'),
		accept: $.__('Yes'),
		error: $.__('Error'),
		errormsg: $.__('Sorry.  This row can not be deleted because there is a change request for it that is waiting for approval.'),
		erroraccept: $.__('Ok')
	};

		window.BambooHR.Modal.setState({
			content: strings.msg,
			isOpen: true,
			primaryAction: function() {
				if (window.GLOBAL_IS_ASSUMED_USER) {
					window.disabledForPermsTest();
				} else {
					var url = '/ajax/dialog/history_popup/' + type + '/' + window.currentlyEditingEmployeeId + '/' + id;
					$.post(url, {'CSRFToken': CSRF_TOKEN, '_method': 'DELETE' }).then(deleteSuccessHandler, deleteErrorHandler);
				}
			},
			onClose: function() { row.removeClass('selected'); },
			primaryActionText: strings.accept,
			title: strings.title
		});

	recordVirtualPageView(type);

	function deleteSuccessHandler(response) {
		window.BambooHR.Modal.setState({ isOpen: false });

		let responseStatus = '';
		let responseErrorMsg = '';

		// Old response
		if (typeof response === 'string') {
			responseStatus = response;
			responseErrorMsg = response;
		// New response
		} else if (response?.result && response?.data) {
			const {
				result,
				reload,
				errorMsg,
				data: {
					stateFilingChange
				}
			} = response;

			responseErrorMsg = errorMsg;

			if (result === 'OK' && reload) {
				responseStatus = 'RELOAD_OK';
			} else {
				responseStatus = result;
			}

			if (stateFilingChange) {
				dispatchLaunchStateTaxModalEvent();
			}
		}

		if (responseStatus === 'OK') {
			var $table = row.closest('table');
			var $tBody = row.closest('tbody');

			row.remove();

			updateEmployeeInformation();
			updateEmployeeGearMenu();
			updateMostRecentRow($table);
			hideShowOvertimeRateColumn();

			// do we need to add the blank state row
			if (!$tBody.find('tr:not(.template):not(.empty)').length) {
				$tBody.find('tr.BhrTable__row.empty').show();
			}
		} else if (responseStatus === 'PENDING_CHANGE') {
			window.setMessage(strings.errormsg, 'error');
		} else if (responseStatus === 'RELOAD_OK') {
			window.location.reload();
		} else {
			window.setMessage(responseErrorMsg, 'error');
		}
	}

	function deleteErrorHandler() {
		window.setMessage($.__('Whoops... we couldn\'t remove this item for you'));
	}
}

export function dispatchLaunchStateTaxModalEvent() {
	document.dispatchEvent(new CustomEvent('launchStateTaxModal', {
		bubbles: true,
		detail: {
			employeeId: window.Employee.id,
			employeeName: window.Employee.preferredName,
			stateTaxPermLevel: window.stateTaxPermLevel,
		},
	}));
}

function recordVirtualPageView(type) {
	switch (type) {
		case 'status':
			VirtualPageView.modal('deleteEmploymentStatus');
			break;
		case 'compensation':
			VirtualPageView.modal('deleteCompensation');
			break;
		case 'job':
			VirtualPageView.modal('deleteJobInformation');
			break;
		case 'bonus':
			VirtualPageView.modal('deleteBonus');
			break;
		case 'commission':
			VirtualPageView.modal('deleteCommission');
			break;
		case 'benefit_class':
			VirtualPageView.modal('deleteBenefitClass');
			break;
		case 'earnings':
			VirtualPageView.modal('deleteEarnings');
			break;
		case 'visa':
			VirtualPageView.modal('deleteVisa');
			break;
	}
}

export default {deleteRow, updateEmployeeInformation, updateEmployeeGearMenu, openMissingLocationInformation};
