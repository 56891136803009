import {NewHirePacketTask, Question, UpdatedFile} from 'NHPTemplates.mod/Form/Interfaces';

export function showQuestionsSection(sendGetToKnowYouEmail: 'no' | 'yes' | boolean, questions: Array<Record<string, any>>): boolean {
	return (sendGetToKnowYouEmail === 'yes' || sendGetToKnowYouEmail === true) &&
		questions.length > 0 &&
		(questions[0].question.length > 0 || questions.length > 1);
}

export function transformPacketTasks(newHirePacketTasks: Record<string, NewHirePacketTask>, files: Record<string, UpdatedFile>): Array<NewHirePacketTask> {
	const taskCategories = Object.values(newHirePacketTasks);
	const tasks = taskCategories.map((category: any) => {
		delete category.count;
		return Object.values(category);
	});

	const allTasks = tasks.flat(1) as unknown as Array<NewHirePacketTask>;
	allTasks.forEach((task) => {
		if (task.fileIds.length > 0) {
			const ids = Object.keys(task.files);
			ids.forEach(id => {
				task.files[id] = files[task.files[id].fileId];
			});
		}
	});

	return allTasks;
}

export function parseQuestions(questionsAndAnswers: Array<Question> = []): Array<Question> {
	const gtkyQuestions = [];

	questionsAndAnswers.map((questionData) => {
		if (questionData.question.trim().length > 0) {
			gtkyQuestions.push(questionData);
		}
		return questionData;
	});

	return gtkyQuestions;
}
