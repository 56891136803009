/* @startCleanup encore */
import { classNameFromObject } from '@utils/dom';
import SummaryBreakdown from './summary-breakdown.react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
/* @endCleanup encore */
import {
	BadgeV2,
	BodyText,
	Divider,
	Flex,
	/* @startCleanup encore */
	Icon,
	/* @endCleanup encore */
	IconTile,
	InlineMessage,
	LayoutBox,
} from '@bamboohr/fabric';

export default function SummaryPayPeriod(props) {
	const {
		approvalMonthAndDayStr,
		/* @startCleanup encore */
		fadePayPeriod,
		payPeriodTitleText,
		/* @endCleanup encore */
		payPeriodRangeText,
		payPeriodTotal,
		periodOvertime,
		periodTimeOff,
		periodHoliday,
	} = props;

	return ifFeature(
		'encore',
		<div className='TimesheetSummary__payPeriod js-timesheet-showPayPeriodTotal'>
			<Flex flexDirection='column' gap={1.5}>
				<BadgeV2
					color='neutral-extra-extra-strong'
					icon={<IconTile variant='secondary' icon='calendar-regular' size={40} />}
					size='small'
					title={payPeriodTotal}
					subtitle={
						<>
							<BodyText color='neutral-weak' size='small'>
								{$.__('Pay Period')}
							</BodyText>
							<BodyText color='neutral-weak' size='small'>
								{payPeriodRangeText}
							</BodyText>
						</>
					}
				/>
				{!!periodOvertime.length || !!periodTimeOff || !!periodHoliday ? (
					<>
						<LayoutBox width='100%'>
							<Divider color='neutral-extra-weak' marginTop={0.5} />
						</LayoutBox>
						<Flex alignItems='center' flexDirection='column' gap={1.5}>
							<SummaryBreakdown {...{ periodOvertime, periodTimeOff, periodHoliday }} />
						</Flex>
					</>
				) : null}
				<InlineMessage
					description={$.__('Will be sent for approval on %s', approvalMonthAndDayStr, { note: 'placeholder is a date/time' })}
					icon='circle-check-regular'
				/>
			</Flex>
		</div>,
		<div className='TimesheetSummary__payPeriod js-timesheet-showPayPeriodTotal'>
			<div className='TimesheetSummary__divider' />
			<div className='TimesheetSummary__title TimesheetSummary__title--payPeriod'>{payPeriodTitleText}</div>
			<div className='TimesheetSummary__text'>{payPeriodRangeText}</div>
			<div
				className={classNameFromObject({
					TimesheetSummary__payPeriodBreakdown: true,
					'TimesheetSummary__payPeriodBreakdown--fade': fadePayPeriod,
				})}
			>
				<div className='TimesheetSummary__payPeriodTotal'>
					<span className='TimesheetSummary__payPeriodClockIcon'>
						<Icon name='fab-clock-20x20' />
					</span>
					{payPeriodTotal}
				</div>
				{SummaryBreakdown({ periodOvertime, periodTimeOff, periodHoliday })}
			</div>
			<div className='TimesheetSummary__text TimesheetSummary__text--approval'>
				<span className='TimesheetSummary__approvalIcon'>
					<Icon name='fab-check-circle-12x12' />
				</span>
				{$.__('Will be sent for approval on %s', approvalMonthAndDayStr, { note: 'placeholder is a date/time' })}
			</div>
		</div>
	);
}
