import { PureComponent, ReactElement } from 'react';
import moment from 'moment.lib';
import TextareaAutosize from 'react-autosize-textarea';
import classNames from 'classnames';

import {
	DatePicker,
	Flex,
	Icon,
	Section,
	Select,
	SelectChangeArgs,
	SelectField,
	StyledBox,
	TextArea,
	TextField,
} from '@bamboohr/fabric/';

import { Message } from '@bamboohr/utils/lib/message';
import CalendarPicker from 'calendar-picker.react';

import './styles.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface State {
	hireDate?: string;
	location: string;
	otherInstructions: string;
	selectedArrivalTime: Array<string>;
	selectedContactOptions: Array<string>;
	selectedManagerOptions: Array<string>;
}

interface SelectOption {
	text: string;
	value: number | string;
}

export interface Props extends State {
	arrivalTimes: Array<SelectOption>;
	contactOptions: Array<SelectOption>;
	errorField: Record<string, boolean>;
	infoHighlightFields: Record<string, boolean>;
	managerOptions?: Array<SelectOption>;
	name: string;
	storeUpdater?: (state: State) => void;
	templateView?: boolean;
	updateHireDate?: (string) => void;
	updateSelectedManager?: (string) => void;
}

export class FirstDayInformation extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		const {
			hireDate,
			location,
			otherInstructions,
			selectedArrivalTime,
			selectedContactOptions,
			selectedManagerOptions,
			storeUpdater = () => {
				return 0;
			},
		} = props;

		this.state = {
			hireDate,
			location,
			otherInstructions,
			selectedArrivalTime,
			selectedContactOptions,
			selectedManagerOptions,
		};

		storeUpdater(this.state);

		this._handleChangeLocation = this._handleChangeLocation.bind(this);
		this._handleChangeInstructions = this._handleChangeInstructions.bind(this);
		this._handleContactChange = this._handleContactChange.bind(this);
		this._handleManagerChange = this._handleManagerChange.bind(this);
		this._handleTimeChange = this._handleTimeChange.bind(this);
	}

	_handleUpdateHireDate(date: string): void {
		const {
			storeUpdater = () => {
				return 0;
			},
			updateHireDate,
		} = this.props;

		const { hireDate } = this.state;

		if (hireDate !== date) {
			// TODO: Remove with updated task save - need to update how it all connects but use the formData object as the basic store
			if (typeof updateHireDate === 'function') {
				try {
					updateHireDate(date);
				} catch (error) {
					console.error(error);
				}
			}

			this.setState({ hireDate: date }, () => {
				storeUpdater(this.state);
			});
		}
	}

	_handleChangeInstructions(event): void {
		const {
			storeUpdater = () => {
				return 0;
			},
		} = this.props;

		const { value = '' } = event.target as HTMLInputElement;
		this.setState({ otherInstructions: value }, () => {
			storeUpdater(this.state);
		});
	}

	_handleChangeLocation(event): void {
		const {
			storeUpdater = () => {
				return 0;
			},
		} = this.props;

		const { value = '' } = event.target as HTMLInputElement;
		this.setState({ location: value }, () => {
			storeUpdater(this.state);
		});
	}

	_handleContactChange(selectedContact: Array<string>): void {
		const {
			storeUpdater = () => {
				return 0;
			},
		} = this.props;
		this.setState(
			{
				selectedContactOptions: selectedContact,
			},
			() => {
				storeUpdater(this.state);
			}
		);
	}

	_handleManagerChange(selectedManager: Array<string>): void {
		const {
			storeUpdater = () => {
				return 0;
			},
			updateSelectedManager,
		} = this.props;

		if (typeof updateSelectedManager === 'function') {
			try {
				updateSelectedManager(selectedManager[0]);
			} catch (error) {
				console.log(error);
			}
		}
		this.setState(
			{
				selectedManagerOptions: selectedManager,
			},
			() => {
				storeUpdater(this.state);
			}
		);
	}

	_handleTimeChange(selectedTimes: Array<string>): void {
		const {
			storeUpdater = () => {
				return 0;
			},
		} = this.props;
		this.setState(
			{
				selectedArrivalTime: selectedTimes,
			},
			() => {
				storeUpdater(this.state);
			}
		);
	}

	componentDidMount(): void {
		const element = document.querySelector('.js-hireDatePickerInput');
		if (element) {
			element.addEventListener('blur', (event): void => {
				// @ts-ignore
				const date = event.target.value as string;
				if (!date || date === '') {
					this._handleUpdateHireDate(date);
				}
			});
		}
	}

	render(): ReactElement {
		const { arrivalTimes, contactOptions, errorField = {}, infoHighlightFields = {}, managerOptions, name, templateView } = this.props;

		const {
			hireDate,
			location = '',
			otherInstructions = '',
			selectedArrivalTime,
			selectedContactOptions,
			selectedManagerOptions,
		} = this.state;

		const hireDateLabelClasses = classNames('fab-Label fab-Label--required', {
			'fab-Label--error': errorField.hireDate,
			'fab-Label--info': infoHighlightFields.hireDate,
		});

		const hireDatePickerClasses = classNames('js-hireDatePickerInput fab-TextInput--width5', {
			'fab-TextInput--error': errorField.hireDate,
			'fab-TextInput--info': infoHighlightFields.hireDate,
		});

		const managerLabelClasses = classNames('fab-Label', {
			'fab-Label--error': errorField.manager,
			'fab-Label--info': infoHighlightFields.manager,
		});

		let managerCondition;

		if (errorField.manager) {
			managerCondition = 'error';
		} else if (infoHighlightFields.manager) {
			managerCondition = 'info';
		}

		return (
			<>
				{ifFeature(
					'encore',
					<Section paddingTop='24px'>
						<Section.Header icon='map-solid' size='large' title={$.__('When, Where, and Other Info')} />
						<Flex flexDirection='column' gap={2.5} marginTop={3}>
							<Flex flexDirection='row' gap={1.5}>
								{!templateView && (
									<div className='fab-FormColumn' id='hireDate'>
										<label className={hireDateLabelClasses} htmlFor='hireDate'>
											{$.__('Hire Date (First Day)')}
										</label>
										<CalendarPicker
											id='hireDate'
											cssClasses={{
												single: hireDatePickerClasses,
											}}
											name='employees[hireDate]'
											noFacade={true}
											onChange={(date) => {
												this._handleUpdateHireDate(date);
											}}
											required={true}
											settings={{
												start: hireDate,
											}}
											type='date'
											unsafeProps={{
												defaultValue: moment(hireDate).format(moment.defaultFormat),
											}}
										/>
									</div>
								)}
								<SelectField
									id='arrivalTime'
									items={arrivalTimes}
									label={$.__('Please Arrive by...')}
									menuWidth={7}
									onChange={(e) => {
										this._handleTimeChange([e.target.value.toString()]);
									}}
									value={selectedArrivalTime}
									width={7}
								/>
								<TextField
									id='location'
									label={$.__('Location')}
									onChange={this._handleChangeLocation}
									placeholder={$.__('Enter Address (Leave blank for remote employee)')}
									value={location}
									width={10}
								/>
							</Flex>
							<Flex flexDirection='row' gap={1.5}>
								<SelectField
									id='whoToContact'
									items={contactOptions}
									label={$.__('Who to Contact')}
									menuWidth={7}
									onChange={(e) => {
										this._handleContactChange([e.target.value.toString()]);
									}}
									value={selectedContactOptions}
									width={7}
								/>
								{!templateView && (
									<SelectField
										id='managerContact'
										items={managerOptions}
										label={$.__('Manager')}
										menuWidth={7}
										onChange={(e) => {
											this._handleManagerChange([e.target.value.toString()]);
										}}
										value={selectedManagerOptions}
										width={7}
									/>
								)}
							</Flex>
							<TextArea
								id='otherInstructions'
								label={$.__('Other Instructions')}
								onChange={(e) => {
									this._handleChangeInstructions(e);
								}}
								value={otherInstructions}
								width={10}
							/>
						</Flex>
					</Section>,
					<div className='fab-FormSection'>
						<legend className='fab-FormSection__legend FirstDayInformation__legend'>
							<Icon brand={true} name='fab-map-grid-20x20' />
							<span className='fab-FormSection__legendText'>
								{$.__('When, Where, and Other Info')}
								<span className='fab-FormSection__legendNote'>
									<Message
										params={[name]}
										text={$._('Enter information about when {1} should arrive, where to go and who to contact on their first day.')}
									/>
								</span>
							</span>
						</legend>
						<div className='FirstDayInformation--indent'>
							<div className='fab-FormRow'>
								{!templateView && (
									<div className='fab-FormColumn' id='hireDate'>
										<label className={hireDateLabelClasses} htmlFor='hireDate'>
											{$.__('Hire Date (First Day)')}
										</label>
										<CalendarPicker
											id='hireDate'
											cssClasses={{
												single: hireDatePickerClasses,
											}}
											name='employees[hireDate]'
											noFacade={true}
											onChange={(date) => {
												this._handleUpdateHireDate(date);
											}}
											required={true}
											settings={{
												start: hireDate,
											}}
											type='date'
											unsafeProps={{
												defaultValue: moment(hireDate).format(moment.defaultFormat),
											}}
										/>
									</div>
								)}
								<div className='fab-FormColumn'>
									<label className='fab-Label' htmlFor='arrivalTime'>
										{$.__('Please Arrive by...')}
									</label>
									<Select
										id='arrivalTime'
										items={arrivalTimes}
										name='newHirePackets[arrivalTime]'
										onChange={this._handleTimeChange}
										selectedValues={selectedArrivalTime}
										width={5}
									/>
								</div>
								<div className='fab-FormColumn'>
									<label className='fab-Label' htmlFor='location'>
										{$.__('Location')}
									</label>
									<div className='fab-InputWrapper'>
										<input
											className='fab-TextInput fab-TextInput--width10'
											id='location'
											name='newHirePackets[location]'
											onChange={this._handleChangeLocation}
											placeholder={$.__('Enter Address (Leave blank for remote employee)')}
											type='text'
											value={location || ''}
											width='10'
										/>
									</div>
								</div>
							</div>
							<div className='fab-FormRow'>
								<div className='fab-FormColumn'>
									<label className='fab-Label' htmlFor='whoToContact'>
										{$.__('Who to Contact')}
									</label>
									<Select
										id='whoToContact'
										items={contactOptions}
										name='newHirePackets[contactEmployeeId]'
										onChange={this._handleContactChange}
										selectedValues={selectedContactOptions}
										width={6}
									/>
								</div>
								{!templateView && (
									<div className='fab-FormColumn' id='managerSelect'>
										<label className={managerLabelClasses} htmlFor='managerContact'>
											{$.__('Manager')}
										</label>
										<Select
											condition={managerCondition}
											id='managerContact'
											items={managerOptions}
											name='employeeJobHistoryItems[reportsToEmployeeId]'
											onChange={this._handleManagerChange}
											selectedValues={selectedManagerOptions}
											width={6}
										/>
									</div>
								)}
							</div>
							<div className='fab-FormRow'>
								<div className='fab-FormColumn'>
									<label className='fab-Label' htmlFor='otherInstructions'>
										{$.__('Other Instructions')}
									</label>
									<TextareaAutosize
										className='fab-Textarea fab-Textarea--width10'
										id='otherInstructions'
										name='newHirePackets[otherInstructions]'
										onChange={this._handleChangeInstructions}
										value={otherInstructions || ''}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</>
		);
	}
}
