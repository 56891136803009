import {
	LayoutBox,
} from '@bamboohr/fabric';
import c from 'classnames';
import {
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
/* @startCleanup encore */
import { ifFeature } from '@utils/feature';
/* @endCleanup encore */

import { formatCurrencyPercent } from '../../utils';

export function AdditionalWithholding(props) {
	const {
		type,
		data,
		viewOnly,
		is2020,
	} = props;
	let amount = 0;

	const refInputElement = useRef();
	const refCurrencyToggleElement = useRef();
	const refPercentToggleElement = useRef();

	const [initialDataType, setInitialDataType] = useState(data.type);
	useLayoutEffect(() => {
		if (data.type && !initialDataType) {
			setInitialDataType(data.type);
		}
	}, [data.type, initialDataType]);

	useLayoutEffect(() => {
		if (refCurrencyToggleElement.current.checked) {
			refInputElement.current.classList.add('currency-field');
		} else if (refPercentToggleElement.current.checked) {
			refInputElement.current.classList.add('percent-field');
		}
	}, []);

	if (Number(data.amount) && data.type) {
		amount = formatCurrencyPercent(data.amount, data.type);
	}

	const toggleBtnClasses = ifFeature('encore', c(
		'fab-TextToggle',
		{ 'fab-TextToggle--disabled': viewOnly }
	), c(
		'bhrToggleSmall bhrToggle--btn fab-FormField AdditionalWithholding__toggleButton',
		{ 'bhrToggle--btn--disabled': viewOnly }
	));
	const labelClasses = ifFeature('encore', c(
		'fab-Label',
		{ 'fab-Label--disabled': viewOnly }
	), c(
		'fab-Label AdditionalWithholding__label',
		{ 'fab-Label--disabled': viewOnly }
	));
	const perPeriodClasses = ifFeature('encore', 'fab-FormNote', c(
		'fab-FormField AdditionalWithholding__perPeriod',
		{ 'fab-Label--disabled': viewOnly }
	));

	const toggleInputCurrencyClasses = ifFeature('encore', 'fab-TextToggle__input', 'bhrToggle--btn-1');
	const toggleInputPercentClasses = ifFeature('encore', 'fab-TextToggle__input', 'bhrToggle--btn-2');
	const toggleLabelClasses = ifFeature('encore', 'fab-TextToggle__label bhrToggle__label bhrToggle--btn__label', 'bhrToggle__label bhrToggle--btn__label');
	const toggleHandleClasses = ifFeature('encore', 'fab-TextToggle__handle bhrToggle__handle bhrToggle--btn__handle', 'bhrToggle__handle bhrToggle--btn__handle brandedToggle');

	const inputClass = `.js-${ type }AdditionalWithholding`;

	return (
		<div className="AdditionalWithholding fab-FormColumn">
			<label className={ labelClasses } htmlFor={ `${ type }Additional` }>
				{ is2020 ? $.__('Extra Withholding') : $.__('Additional Withholding') }
			</label>
			<div className="fab-FormRow">
				<div className={ toggleBtnClasses }>
					<input
						className={ toggleInputCurrencyClasses }
						data-currency-toggle="$"
						data-field-class={ `js-${ type }AdditionalWithholding` }
						data-flag="currency"
						defaultChecked={ initialDataType === 'currency' ? 'checked' : null }
						disabled={ viewOnly }
						id={ `${ type }Currency` }
						name={ `${ type }[withholdExtraType]` }
						onChange={ event => window.BambooHR.Utils.formatCurrencyPercentToggle(event.target, inputClass) }
						ref={ refCurrencyToggleElement }
						type="radio"
						value="$"
					/>
					<label className={ toggleLabelClasses } htmlFor={ `${ type }Currency` }>$</label>
					<input
						className={ toggleInputPercentClasses }
						data-currency-toggle="%"
						data-field-class={ `js-${ type }AdditionalWithholding` }
						data-flag="percent"
						defaultChecked={ initialDataType === 'percent' ? 'checked' : null }
						disabled={ viewOnly }
						id={ `${ type }Percentage` }
						name={ `${ type }[withholdExtraType]` }
						onChange={ event => window.BambooHR.Utils.formatCurrencyPercentToggle(event.target, inputClass) }
						ref={ refPercentToggleElement }
						type="radio"
						value="%"
					/>
					<label className={ toggleLabelClasses } htmlFor={ `${ type }Percentage` }>%</label>
					<span className={ toggleHandleClasses }></span>
				</div>
				{ ifFeature('encore',
					(
						<LayoutBox marginLeft={ 1 }>
							<span className="fab-FormField">
								<input
									className={ `AdditionalWithholding__amount fab-TextInput fab-TextInput--width4 js-${ type }AdditionalWithholding` }
									data-cursymbol="$"
									data-decimal-char="."
									data-thousands-separator=","
									defaultValue={ amount || '' }
									disabled={ viewOnly }
									id={ `${ type }Additional` }
									name={ `${ type }[withholdExtraValue]` }
									onBlur={ handleAdditionalWithholdingBlur }
									ref={ refInputElement }
									type="text"
								/>
							</span>
						</LayoutBox>
					),
					(
						<span className="fab-FormField">
							<input
								className={ `AdditionalWithholding__amount fab-TextInput fab-TextInput--width4 js-${ type }AdditionalWithholding` }
								data-cursymbol="$"
								data-decimal-char="."
								data-thousands-separator=","
								defaultValue={ amount || '' }
								disabled={ viewOnly }
								id={ `${ type }Additional` }
								name={ `${ type }[withholdExtraValue]` }
								onBlur={ handleAdditionalWithholdingBlur }
								ref={ refInputElement }
								type="text"
							/>
						</span>
					)
				) }
				<span className={ perPeriodClasses }>{ ifFeature('encore', $.__('Per Pay Period'), $.__('per pay period')) }</span>
			</div>
		</div>
	);

	function handleAdditionalWithholdingBlur() {
		if (!refCurrencyToggleElement.current || !refPercentToggleElement.current) {
			return;
		}

		if (refCurrencyToggleElement.current.checked) {
			window.BambooHR.Utils.formatCurrencyPercentToggle(refCurrencyToggleElement.current, inputClass);
		} else if (refPercentToggleElement.current.checked) {
			window.BambooHR.Utils.formatCurrencyPercentToggle(refPercentToggleElement.current, inputClass);
		}
	}
}
