import { Fragment, Component } from 'react';
import { BodyText, Icon, IconV2, Select } from '@bamboohr/fabric';
import { Modal } from 'modal-legacy';
import { Select as FabricSelect } from '@fabric/select';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import './styles.styl';

export default class FilterDropdown extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
		};
	}

	_handleToggleModalOpen = () => {
		this.setState((previousState) => {
			return {
				showModal: !previousState.showModal,
			};
		});
	};

	render() {
		const { filters, onFilterChange, selectedFilter, size, variant = 'single' } = this.props;
		const { showModal } = this.state;
		const smallIcon = (value) => {
			switch (value) {
				case 'circle':
					return ifFeature('encore', 'circle-dashed-regular', 'fab-circle-dots-14x14');
				case 'department':
					return ifFeature('encore', 'user-group-regular', 'fab-people-14x12');
				case 'location':
					return ifFeature('encore', 'location-dot-regular', 'fab-location-11x14');
				case 'division':
					return ifFeature('encore', 'sitemap-regular', 'fab-org-chart-12x13');
				case 'all':
					return ifFeature('encore', 'buildings-regular', 'fab-company-12x14');
				case 'custom':
				default:
					return ifFeature('encore', 'user-group-regular', 'fab-people-14x12');
			}
		};

		const filterListItems = filters.map(({ description, displayText, value }) => {
			const iconName = smallIcon(value);

			return ifFeature(
				'encore',
				{
					note: description,
					text: displayText,
					icon: <IconV2 name={iconName} size={16} />,
					value,
				},
				{
					description,
					text: displayText,
					iconName,
					value,
				}
			);
		});

		const customFilterItem = ifFeature(
			'encore',
			{
				text: $.__('Custom Filter...'),
				icon: <IconV2 name={smallIcon('custom')} size={16} />,
				id: 'custom',
				value: 'custom',
			},
			{
				displayText: $.__('Custom Filter...'),
				text: $.__('Custom Filter...'),
				iconName: smallIcon('custom'),
				id: 'custom',
				value: 'custom',
			}
		);

		// TODO: when ee employee filter modal is ready include the custom list item
		filterListItems.push(customFilterItem);

		const handleOnSelect = (_value, _selectedItems, selected) => {
			if (selected.item.value === customFilterItem.value) {
				window.showFilterModal(selected.item.text);
			} else {
				onFilterChange(selected.item.text, selected.item.value);
			}
		};

		const commonSelectProps = {
			isClearable: false,
			width: 7,
			size,
			onSelect: handleOnSelect,
			items: filterListItems,
			selectedValues: [selectedFilter.value],
			variant,
		};

		return (
			<Fragment>
				{filterListItems &&
					filterListItems.length > 0 &&
					ifFeature(
						'encore',
						<div className="FiltersContainer__select">
							<Select
								{...commonSelectProps}
								renderToggleContent={(selectedItems) => {
									const [selectedItem] = selectedItems;
									return <BodyText icon={<IconV2 name={smallIcon(selectedItem.value)} size={16} />}>{selectedItem.text}</BodyText>;
								}}
								size="medium"
								width={100}
							/>
						</div>,
						<div className='FilterDropdown__select'>
							<FabricSelect
								{...commonSelectProps}
								renderOptionContent={(item) => (
									<div className='FilterDropdown__option'>
										<div className='FilterDropdown__optionText'>
											{item.iconName && (
												<span className='FilterDropdown__optionIcon'>
													<Icon name={item.iconName} />
												</span>
											)}
											<span>{item.text}</span>
										</div>
										<span className='FilterDropdown__optionDescription'>{item.description}</span>
									</div>
								)}
								renderToggleContent={(selectedItems) => {
									const [selectedItem] = selectedItems;
									return selectedItem ? (
										<div className='FilterDropdown__toggle'>
											{selectedItem.iconName && (
												<span className='FilterDropdown__toggleIcon'>
													<Icon name={selectedItem.iconName} />
												</span>
											)}
											<span>{selectedItem.text}</span>
										</div>
									) : null;
								}}
							/>
						</div>
					)}
				<Modal
					icon='fab-employees-20x18'
					isOpen={showModal}
					onClose={this._handleToggleModalOpen}
					primaryAction={() => {
						onFilterChange(customFilterItem.displayText, customFilterItem.value);
						this._handleToggleModalOpen();
					}}
					primaryActionText={$.__('Done')}
					title={$.__('Filter Options')}
				>
					<div>{/* TODO: place ee employee filter modal content here */}</div>
				</Modal>
			</Fragment>
		);
	}
}
