import { Component } from 'react';

import Ajax from '@utils/ajax';
import { Modal } from 'modal-legacy';
import { BodyText, Divider, Icon, IconV2, LayoutBox } from '@bamboohr/fabric';
import {
	formatTime,
} from 'time-tracking/utils';

import ChooseDate from 'time-tracking/modals/shared/components/choose-date.react';

import './styles.styl';
/* @startCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';
/* @endCleanup encore */

const CLOCK_OUT_ERRORS = {
	INVALID_CLOCK_TIME: $.__('The clock out time is before the clock in time.'),
	MISSING_EMPLOYEE_IDS: $.__('Missing employee ID(s)'),
	MISSING_TOOL: $.__('Time Tracking is not enabled.'),
	PERMISSIONS: $.__('You don\'t have permission to do that'),
	FUTURE_TIMESHEET: $.__('You can\'t perform that action on a future timesheet.'),
	TIMESHEET_NOT_FOUND: $.__('Timesheet not found'),
	INVALID_ID: $.__('Invalid employee or timesheet id.'),
	PREVIEW: $.__('Cannot change a clock out date while previewing as another user.'),
	CLOCKED_OUT: $.__('The employee is currently clocked out.'),
	TIMESHEET_TYPE: $.__('Invalid timesheet type'),
	INVALID_TIMEZONE: $.__('Employee has an invalid timezone setup'),
	NO_CLOCK_ENTRIES: $.__('The employee hasn\'t clocked in yet.'),
	SERVER_ERROR: $.__('Internal error.'),
	OVERLAP: $.__('Overlapping timesheets found. Please contact support.'),
};

export default class ChooseClockOutDate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedDateTime: null,
			totalTime: null,
			isModalOpen: false,
			isModalProcessing: false,
		};
	}

	_handleDateChange = (date, totalTime) => {
		this.setState({
			selectedDateTime: date,
			totalTime,
		});
	};

	_getClockOutDateTime = () => {
		const { selectedDateTime } = this.state;
		return selectedDateTime;
	};

	_clockOut = () => {
		const { selectedDateTime } = this.state;
		const { afterClockOut, employeeId } = this.props;
		const postData = {
			clockOuts: [
				{
					employeeId,
					datetime: selectedDateTime.toISOString(true),
					timezone: selectedDateTime.tz(),
				},
			],
		};

		this.setState({ isModalProcessing: true });
		Ajax.post('/timesheet/clock/out/datetime', postData)
			.then(() => {
				this.setState({ isModalOpen: false });
				afterClockOut();
			})
			.catch((error) => {
				if (error.response.data.error) {
					const errorCode = error.response.data.error.code;
					setMessage(CLOCK_OUT_ERRORS[errorCode], 'error');
				} else {
					window.errorFallBack();
				}
				this.setState({ isModalProcessing: false });
			});
	};

	componentDidMount() {
		setTimeout(() => {
			this.setState({ isModalOpen: true });
		});
	}

	render() {
		const { isModalOpen, isModalProcessing, totalTime } = this.state;
		const { lastClockEntry } = this.props;

		const content = (
			<div className="ChooseClockOutDate">
				<div className="ChooseClockOutDate__icon">
					{ifFeature('encore',
						<IconV2 name="triangle-exclamation-solid" size={55} />,
						<Icon name="fab-stopwatch-exclamation-51x54" />
					)}
				</div>
				<h4 className="ChooseClockOutDate__headingText">{ $.__('Looks like you forgot to clock out.') }</h4>
				<p className="ChooseClockOutDate__text">{ $.__('When should you have clocked out?') }</p>
				{ifFeature(
					'encore',
					<LayoutBox width='100%'>
						<Divider marginY={2} />
					</LayoutBox>,
					<hr className='ChooseClockOutDate__divider' />
				)}
				<ChooseDate lastClockEntry={lastClockEntry} onDateChange={this._handleDateChange} />
				{ifFeature(
					'encore',
					<LayoutBox alignSelf='start'>
						<BodyText weight='medium'>
							{$.__('Total Time: %1', totalTime > 0 ? formatTime(totalTime) : formatTime(0))}
						</BodyText>
					</LayoutBox>,
					<p className='ChooseClockOutDate__totalTime'>
						{$.__('Total Time: %1', totalTime > 0 ? formatTime(totalTime) : formatTime(0))}
					</p>
				)}
			</div>
		);

		return (
			<Modal
				biId="my-info-timesheet-clock-out"
				isOpen={ isModalOpen }
				isProcessing={ isModalProcessing }
				onClose={ () => this.setState({ isModalOpen: false }) }
				primaryAction={ this._clockOut }
				primaryActionText={ $.__('Clock Out') }
				title={ $.__('Verify Time & Clock Out') }
				type="medium"
			>
				{ content }
			</Modal>
		);
	}
}
