import {FC} from "react";
import {FileDocument} from "../interfaces";
import {Button, StandardModal, RoundedBox, FileIcon, Typography, makeStyles, createStyles} from "@bamboohr/fabric";
import {TrashCan43x48} from "@bamboohr/grim";
import {humanFileSize} from 'BambooHR.util';
import moment from "moment";

interface DeleteFileModalProps {
    file: FileDocument,
    isOpen: boolean,
    handleClose: () => void,
    handleConfirm: () => void
}

const useStyles = makeStyles(({palette}) => {
    return createStyles({
        trash: {
          fill: palette.error.main,
        },
        file: {
            marginTop: '10px',
            padding: '25px 0px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        fileName: {
            marginBottom: '0',
        }
    })
});

export const DeleteFileModal: FC<DeleteFileModalProps> = (props) => {
    const {
        file,
        isOpen,
        handleClose,
        handleConfirm,
    } = props;
    const classes = useStyles();

    const fileCreatedDate = moment(file.created).format(moment.defaultFormat);

    return (
        <StandardModal isOpen={isOpen} onRequestClose={handleClose}>
            <StandardModal.Body
                renderFooter={ (
                    <StandardModal.Footer
                        actions={ [
                            <Button
                                key="primary"
                                onClick={handleConfirm}
                                type="button"
                            >
                                {$.__('Delete File')}
                            </Button>,
                            <Button
                                color="secondary"
                                key="secondary"
                                onClick={handleClose}
                                type="button"
                            >
                                { $.__('Keep File') }
                            </Button>
                        ] }
                    />
                ) }
                renderHeader={ (
                    <StandardModal.Header hasCloseButton={ true } title={ $.__('Just Checking...') } />
                ) }
            >
                <StandardModal.Constraint spacingOverrides={{
                    bottom: StandardModal.Constraint.Spacing.MEDIUM,
                    top: StandardModal.Constraint.Spacing.MEDIUM
                }}>
                    <StandardModal.HeroHeadline icon={<TrashCan43x48 className={classes.trash}/>} text={$.__('Are you sure you want to delete this file?')} />
                    <RoundedBox className={classes.file}>
                        <FileIcon filename={file.name} size='large' />
                        <Typography className={classes.fileName} variant='h4'>{file.name}</Typography>
                        <Typography variant='small'>Created {fileCreatedDate} by {file.created_user} ({humanFileSize(file.size)})</Typography>
                    </RoundedBox>
                </StandardModal.Constraint>
            </StandardModal.Body>
        </StandardModal>
    )
}
