import './styles.styl';

import { round } from 'lodash';
import {Component, Fragment} from 'react';
import {classNameFromObject} from '@utils/dom';
import {applyCompanyDecimal, trimTrailingZeroes} from 'Number.util';
import moment from 'moment.lib';
import { isEnabled } from 'FeatureToggle.util';
import { Icon, IconV2, Flex, BodyText, IconButton } from '@bamboohr/fabric';
// @startCleanup encore
import { FloatingIconButton } from '@fabric/button';
// @endCleanup encore
import Tooltip from 'tooltip.react';
import { TIME_OFF_ICON } from 'time-off';

import {formatTime, parseTime, INVALID_FORMAT, OVER_24, getPaidHolidayText, getProjectTaskText} from 'time-tracking/utils';
import { INVALID_FORMAT_MSG, OVER_24_MSG, JADE_TIME_OFF_ICONS, TIME_FORMAT } from 'time-tracking/constants';
import createErrorPopover, {updateErrorPopover} from 'time-tracking/popovers/input-error';
import createEntryHintPopover from 'time-tracking/popovers/entry-hint';
import addEditEntryModal from 'time-tracking/modals/add-edit-entry';
import TimeEntries from 'time-tracking/components/time-entries.react';
import { HourEntries } from 'time-tracking/components/hour-entries.react';
import NoteIcon from 'time-tracking/components/note-icon.react';
import AddNoteModal from 'time-tracking/modals/add-note';
import openChangeHistoryModal from 'time-tracking/modals/change-history';

import {consume} from '../../store/context';
import { ifFeature } from '@bamboohr/utils/lib/feature';

// Helper Popovers are only used for Single Daily Entry
let errorPopover = null;
let entryHintPopover = null;
let focusInputTimeout = null;

const timesheetChangeEnabled = isEnabled('timesheetChange');

export class Slat extends Component {
	constructor(props) {
		super(props);

		this.isMultipleEntryUI = props.type === 'multiple' || props.type === 'hour';

		this.state = {
			validInput: true,
			isExpanded: false,
			isForgotClockDay: this._getIsForgotClockDay(),
		};

		if (props.type === 'single') {
			if (!errorPopover) {
				errorPopover = createErrorPopover();
			}

			if (props.timesheetOptions.showTimeEntryHint && !entryHintPopover) {
				entryHintPopover = createEntryHintPopover();
				entryHintPopover.onClose(() => {
					entryHintPopover.destroy();
					entryHintPopover = null;
					props.changeViewOption('showTimeEntryHint', false);
				});
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.userEdit !== null && this.props.userEdit === null) {
			this.inputRef.blur();
			this.setState({validInput: true});
		}

		if (prevProps.clockEntries.length && !this.props.clockEntries.length) {
			this._collapseEntriesList();
		}

		const isForgotClockDay = this._getIsForgotClockDay();

		if (prevState.isForgotClockDay !== isForgotClockDay) {
			this.setState({ isForgotClockDay });
		}
	}

	_getIsForgotClockDay = () => {
		const {
			forgotClockOut,
			clock,
			date,
			isFuture
		} = this.props;

		return forgotClockOut && clock && clock.latest !== null
			? !isFuture && moment(date).isSameOrAfter(moment(clock.latest.start).startOf('day'))
			: false;
	};

	_expandEntriesList = () => {
		this.setState({isExpanded: true});
		$(this.collapseButtonRef).fadeIn(200);
		$(this.clockEntriesRef).slideDown(200);
	};

	_collapseEntriesList = () => {
		this.setState({isExpanded: false});
		$(this.collapseButtonRef).fadeOut(200);
		$(this.clockEntriesRef).slideUp(200);
	};

	_handleShowChangeHistory = () => {
		const { date, getFocusedDays } = this.props;
		openChangeHistoryModal(getFocusedDays(), date);
	};

	_handleCollapseButtonClick = (event) => {
		event.preventDefault();
		this._collapseEntriesList();
	};

	_launchAddEditModal = (id, type, timezone) => {
		const {
			canEdit,
			isViewOnly,
			changeViewOption,
			clock,
			clockEntries,
			date,
			hourEntries,
			employee,
			hours,
			projectsWithTasks,
			timesheetOptions,
			type: timesheetType
		} = this.props;

		const editEntryModalData = {
			canEdit: canEdit && !isViewOnly,
			openAsSheet: isViewOnly,
			clockedIn: clock && clock.clockedIn,
			clockEntries,
			currentEntryId: id,
			date,
			dayTotal: hours,
			employeeId: employee.id,
			hourEntries,
			onHintDismiss: changeViewOption,
			projectsWithTasks,
			showTimeEntryHint: timesheetOptions.showTimeEntryHint,
			timesheetType,
			timezone,
			type,
		};

		addEditEntryModal(editEntryModalData)
			.then(this.props.refreshTimesheet)
			.then(() => {
				if (!this.props.isToday && this.props.clockEntries.length) {
					this._expandEntriesList();
				}
			});
	};

	_onInputFocus = () => {
		document.dispatchEvent(new CustomEvent('SiteFooter:open'));

		if (this.props.hours !== null && this.props.userEdit === null) {
			let hours = round(this.props.hours, 2);
			if (window.GLOBAL_DECIMAL_CHAR === ',') {
				hours = String(hours).replace('.', ',');
			}
			this.props.editSingleEntrySlat(this.props.date, hours);
		}

		if (focusInputTimeout !== null) {
			clearTimeout(focusInputTimeout);
			focusInputTimeout = null;
		}

		focusInputTimeout = setTimeout(() => {
			focusInputTimeout = null;
			const $inputRef = $(this.inputRef);
			// https://stackoverflow.com/questions/3380458/looking-for-a-better-workaround-to-chrome-select-on-focus-bug
			$inputRef
				.select()
				.one('mouseup', (e) => {
					e.preventDefault();
					$inputRef.select();
				});
		});

		if (entryHintPopover) {
			entryHintPopover.setAnchor(this.inputRef);
			if (this.state.validInput) {
				entryHintPopover.show();
			}
		}

		if (!this.state.validInput) {
			updateErrorPopover(this._errorMessage, this.inputRef);
			errorPopover.show();
		}
	};

	_onInputBlur = () => {
		if (entryHintPopover) {
			entryHintPopover.hide();
		}
		errorPopover.hide();
	};

	_onInputChange = () => {
		const {value} = this.inputRef;
		const parsedValue = parseTime(value);
		const isValid = (parsedValue !== OVER_24 && parsedValue !== INVALID_FORMAT);

		if (isValid) {
			this.setState({validInput: true});

			if (entryHintPopover) {
				entryHintPopover.show();
			}

			errorPopover.hide();
		} else {
			this.setState({validInput: false});
			this._errorMessage = (parsedValue === INVALID_FORMAT ? INVALID_FORMAT_MSG : OVER_24_MSG);

			if (entryHintPopover) {
				entryHintPopover.hide();
			}

			updateErrorPopover(this._errorMessage, this.inputRef);
			errorPopover.show();
		}

		this.props.editSingleEntrySlat(this.props.date, value);
	};

	_renderOvertimeHours() {
		const {overtimeSummary} = this.props;
		const classes = classNameFromObject({
			'TimesheetSlat__extraInfoItem': true,
			'TimesheetSlat__extraInfoItem--overtimePush': this.isMultipleEntryUI,
		});
		const showMultiplier = this.props.overtimeSummary.length > 1;

		return overtimeSummary.map((item) => ifFeature(
				'encore',
				<div className={classes} key={item.multiplier}>
					<BodyText color='warning-medium' icon="stopwatch-regular">
						{$.__('%1$s Overtime', formatTime(item.hours, true))} {showMultiplier && `(${item.multiplier}x)`}
					</BodyText>
				</div>,
				<div className={classes} key={item.multiplier}>
					<span className='TimesheetSlat__overtimeIcon'>
						<Icon name='fab-stopwatch-alert-12x12' />
					</span>
					<div className='TimesheetSlat__overtimeWarning'>
						{$.__('%1$s Overtime', formatTime(item.hours, true))} {showMultiplier && `(${item.multiplier}x)`}
					</div>
				</div>
			)
		);
	}

	_renderTimeOffInfoItems() {
		const { timeOff } = this.props;
		return timeOff.map((request, i) => {
			const requestIcon = ifFeature(
				'encore',
				request.icon ? TIME_OFF_ICON[request.icon] : 'business-time-regular',
				request.icon ? JADE_TIME_OFF_ICONS[request.icon] : 'fab-briefcase-clock-16x12'
			);
			const requestUnit = (request.unit === 'hours' ? $.__('hours') : $.__('days'));

			const classes = classNameFromObject({
				'TimesheetSlat__extraInfoItem': true,
				'TimesheetSlat__extraInfoItem--clockPush': this.isMultipleEntryUI,
			});

			return ifFeature(
				'encore',
				<div className={classes} key={i}>
					<BodyText color='neutral' icon={requestIcon}>
						{request.amount} {requestUnit} {request.type}
					</BodyText>
				</div>,
				<div className={classes} key={i}>
					<span className='TimesheetSlat__infoIcon'>
						<Icon name={requestIcon} />
					</span>
					{request.amount} {requestUnit} {request.type}
				</div>
			);
		});
	}

	_renderHolidayInfoItems() {
		return this.props.holidays.map((holiday, i) => {
			const multiplier = applyCompanyDecimal(trimTrailingZeroes(holiday.multiplier));

			const classes = classNameFromObject({
				'TimesheetSlat__extraInfoItem': true,
				'TimesheetSlat__extraInfoItem--clockPush': this.isMultipleEntryUI,
			});

			let holidayMessage = `${ holiday.name } ${ holiday.multiplier ? `(${ $.__('pays') } ${ multiplier }x)` : '' }`;
			if (holiday.paidHours > 0) {
				holidayMessage = getPaidHolidayText(holiday);
			}

			return ifFeature(
				'encore',
				<div className={classes} key={i}>
					<BodyText color='neutral' icon="gift-regular" key={i}>
						{ holidayMessage }
					</BodyText>
				</div>,
				<div className={ classes } key={ i }>
					<span className="TimesheetSlat__infoIcon">
						<Icon name="fab-fireworks-16x16" />
					</span>
					{ holidayMessage }
				</div>
			);
		});
	}

	_renderClockEntries() {
		const { clockEntries, canEdit, clock, isToday, forgotClockOut, isViewOnly, type } = this.props;
		const { isForgotClockDay } = this.state;
		return !!clockEntries.length && (
			<div ref={ node => (this.clockEntriesRef = node) } style={ {display: isToday ? '' : 'none'} }>
				<div className="TimesheetSlat__contentDivider"/>
				{ TimeEntries({
					canEditEntries: canEdit,
					clockEntries,
					elapsedTimeToday: clock ? clock.earned : 0,
					hasLightBackground: isToday,
					onEditClick: this._launchAddEditModal,
					forgotClockOut,
					isForgotClockDay,
					currentEntryClick: this._handleCurrentEntryClick,
					isViewOnly,
					renderProjectAndTask: true,
					type
				}) }
			</div>
		);
	}

	_renderHourEntries() {
		const { hourEntries, canEdit, isToday, isViewOnly, type } = this.props;
		return !!hourEntries?.length && (
			<div ref={ node => (this.clockEntriesRef = node) } style={ {display: isToday ? '' : 'none'} }>
				<div className="TimesheetSlat__contentDivider" />
				<HourEntries
					canEdit={ canEdit }
					hasLightBackground={ isToday }
					hourEntries={ hourEntries }
					isViewOnly={ isViewOnly }
					onEditClick={ this._launchAddEditModal }
					renderProjectAndTask={ true }
					type={ type }
				/>
			</div>
		);
	}

	_handleNoteIconClick = () => {
		const { canEdit, date, employee, hours, note, refreshTimesheet, isViewOnly, projectId, projectName, projectsWithTasks, taskId, taskName } = this.props;
		const projectData = {
			projectId,
			projectName,
			taskId,
			taskName
		};
		const noteModalData = {
			canEdit,
			employeeId: employee.id,
			note,
			timezone: employee.timezone,
			today: moment.tz(date, employee.timezone),
			totalTime: hours,
			type: 'single',
			isViewOnly,
			projectData,
			projectsWithTasks,
		};
		AddNoteModal(noteModalData)
			.then(refreshTimesheet);
	}

	_handleCurrentEntryClick = () => {
		const { canEdit, employee, clock, refreshTimesheet, isViewOnly, projectsWithTasks } = this.props;
		const projectData = {
			projectId: clock.latest.projectId,
			projectName: clock.latest.projectName,
			taskId: clock.latest.taskId,
			taskName: clock.latest.taskName
		};

		const noteModalData = {
			canEdit,
			clock,
			employeeId: employee.id,
			isViewOnly,
			note: clock.latest.note,
			projectData,
			projectsWithTasks,
			timezone: clock.latest.timezone,
			today: moment.tz(clock.latest.start, clock.latest.timezone),
			type: 'clock',
		};
		AddNoteModal(noteModalData)
			.then(refreshTimesheet);
	}

	_renderProjectTaskText() {
		const { canEdit, isToday, isViewOnly, note, projectName, taskName } = this.props;
		const projectTaskText = getProjectTaskText(projectName, taskName);

		return (
			<div
				className={classNameFromObject({
					TimesheetSlat__projectTaskTextWrapper: true,
					'TimesheetSlat__projectTaskTextWrapper--today': isToday,
				})}
			>
				<div
					className={classNameFromObject({
						TimesheetSlat__projectTaskText: true,
						'TimesheetSlat__projectTaskText--editable': !isViewOnly,
						'TimesheetSlat__projectTaskText--expanded': isViewOnly,
					})}
					onClick={!isViewOnly ? this._handleNoteIconClick : null}
				>
					{ifFeature(
						'encore',
						<Flex justifyContent='space-between'>
							<Flex flexDirection='column' overflow='hidden'>
								<div>{projectTaskText}</div>
								<div
									className={classNameFromObject({
										'TimesheetSlat__projectTaskText--note': true,
										'TimesheetSlat__projectTaskText--note--expanded': isViewOnly,
									})}
								>
									{note}
								</div>
							</Flex>
							{canEdit && (
								<span className='TimesheetSlat__projectTaskText__editIcon'>
									<IconV2 color='neutral-extra-strong' name='pen-solid' size={12} />
								</span>
							)}
						</Flex>,
						<>
							<div>{projectTaskText}</div>
							<div
								className={classNameFromObject({
									'TimesheetSlat__projectTaskText--note': true,
									'TimesheetSlat__projectTaskText--note--expanded': isViewOnly,
								})}
							>
								{note}
							</div>
							{canEdit && (
								<span className='TimesheetSlat__projectTaskText__editIcon'>
									<Icon name='fab-pencil-12x12' />
								</span>
							)}
						</>
					)}
				</div>
			</div>
		);
	}

	_renderSingleEntryUi() {
		const { canEdit, hours, isToday, note, slatDisabled, isViewOnly, projectName } = this.props;
		const inputClasses = classNameFromObject({
			'TimesheetSlat__input fab-TextInput fab-TextInput--biggie': true,
			'TimesheetSlat__input--disabled': !this.props.canEdit,
			'fab-TextInput--error': !this.state.validInput,
		});
		const noteClasses = {
			'TimesheetSlat__noteIconWrapper': true,
			'TimesheetSlat__noteIconWrapper--today': isToday && hours !== null,
			'TimesheetSlat__noteIconWrapper--hasNote': note,
			'TimesheetSlat__noteIconWrapper--disabled': slatDisabled,
			'TimesheetSlat__noteIconWrapper--hasHours': hours !== null && canEdit
		};

		const value = (this.props.userEdit === null) ? formatTime(this.props.hours) : this.props.userEdit;

		return (
			<Fragment>
				{ isViewOnly && (
					<div className="TimesheetSlat__viewOnlyHours">{ value ? value : formatTime(0) }</div>
				) }
				{ !isViewOnly && (
					<Fragment>
						<input
							className={ inputClasses }
							disabled={ !this.props.canEdit }
							onBlur={ this._onInputBlur }
							onChange={ this._onInputChange }
							onFocus={ this._onInputFocus }
							ref={ node => (this.inputRef = node) }
							value={ value }
						/>
						{ !!note === false && !projectName && <NoteIcon customCSS={ noteClasses } hasNote={ !!note } onNoteIconClick={ this._handleNoteIconClick } /> }
					</Fragment>
				) }
				<div className="TimesheetSlat__extraInfo">
					{this._renderOvertimeHours()}
					{this._renderTimeOffInfoItems()}
					{this._renderHolidayInfoItems()}
				</div>
			</Fragment>
		);
	}

	_renderFirstAndLast() {
		const entriesCount = this.props.clockEntries.length;
		const hasEntries = !!entriesCount;
		const clockIsEnabled = !!this.props.clock;
		const clockedIn = clockIsEnabled && this.props.clock.clockedIn;
		const nowText = $.__('Now');

		let start = this.props.clockEntries[0];
		let end = this.props.clockEntries[entriesCount - 1];
		let output = '';

		if ((!this.props.isToday || !clockIsEnabled) && !hasEntries) {
			return null;
		}

		if (!this.props.isToday && hasEntries) {
			start = moment.tz(start.start, start.timezone).format(TIME_FORMAT);
			end = moment.tz(end.end, end.timezone).format(TIME_FORMAT);
			output = `${ start } - ${ end }`;
		}

		if (this.props.isToday && !hasEntries && clockIsEnabled) {
			output = this.props.serverTime.format(TIME_FORMAT);
		}

		if (this.props.isToday && !clockedIn && hasEntries) {
			start = moment.tz(start.start, start.timezone).format(TIME_FORMAT);
			end = moment.tz(end.end, end.timezone).format(TIME_FORMAT);
			output = `${ start } – ${ end }`;
		}

		if (this.props.isToday && clockedIn) {
			start = moment.tz(start.start, start.timezone).format(TIME_FORMAT);
			end = nowText;
			output = `${ start } - ${ end }`;
		}

		const firstLastClasses = classNameFromObject({
			'TimesheetSlat__firstAndLast': true,
			'TimesheetSlat__firstAndLast--forgotClockOut': this.props.forgotClockOut && this.state.isForgotClockDay,
		});

		return (
			<div className={ firstLastClasses }>
				{ output }
				{
					hasEntries &&
					<span className="TimesheetSlat__entriesCount"> ({ $.__n('%1$s Entry', '%1$s Entries', entriesCount, {note: 'number of entries'}) })</span>
				}
			</div>
		);
	}

	_renderMultipleEntryUi() {
		const {
			canEdit,
			clock,
			employee,
			forgotClockOut,
			hasMidnightEntry,
			holidays,
			hours,
			isToday,
			isFuture,
			slatDisabled,
			timeOff,
			type,
			historyEventCount,
			isViewOnly,
		} = this.props;
		const { isForgotClockDay } = this.state;

		const clockedIn = (clock && clock.clockedIn);

		const dayTotalClasses = classNameFromObject({
			'TimesheetSlat__dayTotal': true,
			'TimesheetSlat__dayTotal--active': isToday && clockedIn && !forgotClockOut,
			'TimesheetSlat__dayTotal--forgotClockOut': forgotClockOut && isForgotClockDay,
		});

		const hasTimeOffOrHolidays = (!!timeOff.length || !!holidays.length);
		const isTodayOrFuture = (isToday || isFuture);
		const hideAddEntryButton = (
			type === 'single' ||
			(clockedIn && isTodayOrFuture) ||
			!canEdit ||
			isViewOnly ||
			slatDisabled ||
			hasMidnightEntry
		);

		const historyTooltipContent = $.__n('See change history (%1$s event)', 'See change history (%1$s events)', historyEventCount);

		return (
			<Fragment>
				<div className='TimesheetSlat__multipleContent'>
					<h4 className={dayTotalClasses}>{formatTime(hours || 0)}</h4>
					{type === 'multiple' && this._renderFirstAndLast()}
					{this._renderOvertimeHours()}
					{hasTimeOffOrHolidays && (
						<Fragment>
							<div className='TimesheetSlat__contentDivider' />
							{this._renderTimeOffInfoItems()}
							{this._renderHolidayInfoItems()}
						</Fragment>
					)}

					{type === 'multiple' && this._renderClockEntries()}
					{type === 'hour' && this._renderHourEntries()}

					{!hideAddEntryButton && (
						<a
							className='TimesheetSlat__addEntryLink'
							onClick={() => {
								this._launchAddEditModal(null, 'add', employee.timezone);
							}}
						>
							+ {$.__('Add Time Entry')}
						</a>
					)}
				</div>

				{timesheetChangeEnabled && (
					<div className='TimesheetSlat__expandedActions' ref={(node) => (this.collapseButtonRef = node)} style={{ display: 'none' }}>
						{!isViewOnly && historyEventCount > 0 && (
							<Tooltip settings={{ content: historyTooltipContent }}>
								{ifFeature(
									'encore',
									<IconButton
										ariaLabel={historyTooltipContent}
										color='secondary'
										data-buttonname='timesheet-change-history'
										icon='clock-rotate-left-regular'
										onClick={this._handleShowChangeHistory}
										size='extra-small'
										type='button'
										variant='outlined'
									/>,
									<FloatingIconButton
										clickAction={this._handleShowChangeHistory}
										data-buttonname='timesheet-change-history'
										icon='fab-clock-reverse-16x15'
										secondary={true}
										size='teenie'
										type='button'
									/>
								)}
							</Tooltip>
						)}
						{ifFeature(
							'encore',
							<IconButton
								aria-label={$.__('Collapse Entries')}
								color='secondary'
								data-buttonname='timesheet-change-collapse'
								icon='chevrons-up-regular'
								onClick={this._handleCollapseButtonClick}
								size='extra-small'
								type='button'
								variant='outlined'
							/>,
							<FloatingIconButton
								clickAction={this._handleCollapseButtonClick}
								data-buttonname='timesheet-change-collapse'
								icon='fab-double-chevron-up-14x16'
								secondary={true}
								size='teenie'
								type='button'
							/>
						)}
					</div>
				)}

				{!timesheetChangeEnabled &&
					ifFeature(
						'encore',
						<div
							className='TimesheetSlat__expandedActions'
							ref={(node) => (this.collapseButtonRef = node)}
							style={{ display: 'none' }}
						>
							<IconButton
								aria-label={$.__('Collapse Entries')}
								color='secondary'
								data-buttonname='timesheet-change-collapse'
								icon='chevrons-up-regular'
								onClick={this._handleCollapseButtonClick}
								size='extra-small'
								type='button'
								variant='outlined'
							/>
						</div>,
						<button
							className='TimesheetSlat__collapseButton btn'
							onClick={this._handleCollapseButtonClick}
							ref={(node) => (this.collapseButtonRef = node)}
							style={{ display: 'none' }}
							type='button'
						>
							<span className='TimesheetSlat__collapseButtonIcon'>
								<Icon name='fab-double-chevron-up-11x12' />
							</span>
						</button>
					)}
			</Fragment>
		);
	}

	render() {
		const { isExpanded } = this.state;
		const {
			clock,
			clockEntries,
			hasWorkWeekStartsOnChanged,
			hourEntries,
			isFuture,
			isToday,
			isWeekend,
			moment,
			note,
			projectName,
			slatDisabled,
			timesheetOptions,
			type,
			topNote
		} = this.props;
		const hasEntries = clockEntries?.length || hourEntries?.length;
		const expandable = (!isToday && !isExpanded && hasEntries && this.isMultipleEntryUI);
		const todayClockedIn = isToday && clock && clock.clockedIn;
		const projectSectionExists = projectName || !!note;

		const slatClasses = classNameFromObject({
			'TimesheetSlat': true,
			'TimesheetSlat--clock': this.isMultipleEntryUI,
			'TimesheetSlat--future': isFuture && !slatDisabled,
			'TimesheetSlat--disabled': slatDisabled,
			'TimesheetSlat--today': isToday,
			'TimesheetSlat--hide': isWeekend && !timesheetOptions.showWeekends,
			'TimesheetSlat--expandable': expandable,
			'TimesheetSlat--expanded': isExpanded,
			'TimesheetSlat--futurePadClockedIn': todayClockedIn,
			'js-timesheet-showWeekends': isWeekend,
		});

		return ifFeature(
			'encore',
			<div className={slatClasses} onClick={expandable ? this._expandEntriesList : null}>
				{topNote || hasWorkWeekStartsOnChanged ? (
					<Flex flexDirection='column' gap={0.5} marginTop={2}>
						{topNote}
						{hasWorkWeekStartsOnChanged && (
							<BodyText color='warning-strong' icon='triangle-exclamation-solid'>
								{$.__('Overtime cannot be calculated for this day.')}
							</BodyText>
						)}
					</Flex>
				) : null}
				<Flex gap={3} paddingY={3.5}>
					<div className='TimesheetSlat__day'>
						<div
							className={classNameFromObject({
								TimesheetSlat__dayOfWeek: true,
								'TimesheetSlat__dayOfWeek--active': todayClockedIn,
							})}
						>
							{moment.format('ddd')}
						</div>
						<div className='TimesheetSlat__dayDate'>{moment.format('MMM D')}</div>
					</div>
					<div className='TimesheetSlat__dataWrapper'>
						<div
							className={classNameFromObject('TimesheetSlat__data', {
								'TimesheetSlat__data--hasProjectSection': type === 'single' && projectSectionExists,
							})}
						>
							{type === 'single' && this._renderSingleEntryUi()}
							{this.isMultipleEntryUI && this._renderMultipleEntryUi()}
						</div>
						{projectSectionExists && type === 'single' && this._renderProjectTaskText()}
					</div>
				</Flex>
			</div>,
			<div className={slatClasses} onClick={expandable ? this._expandEntriesList : null}>
				<div className='TimesheetSlat__day'>
					{this.isMultipleEntryUI && <div className='TimesheetSlat__arrowLine' />}

					<div
						className={classNameFromObject({
							TimesheetSlat__dayOfWeek: true,
							'TimesheetSlat__dayOfWeek--active': todayClockedIn,
						})}
					>
						{hasWorkWeekStartsOnChanged && (
							<div className='TimesheetSlat__dayOfWeek__warningIconWrapper'>
								<Tooltip
									settings={{
										content: $.__('Overtime cannot be calculated for this day.'),
										tailOnBalloon: '40px',
									}}
								>
									<span className='TimesheetSlat__dayOfWeek__warningIcon'>
										<Icon name='fab-triangle-exclamation-16x16' />
									</span>
								</Tooltip>
							</div>
						)}
						{moment.format('ddd')}
					</div>

					<div className='TimesheetSlat__dayDate'>{moment.format('MMM D')}</div>
				</div>
				<div className='TimesheetSlat__dataWrapper'>
					<div
						className={classNameFromObject('TimesheetSlat__data', {
							'TimesheetSlat__data--hasProjectSection': type === 'single' && projectSectionExists,
						})}
					>
						{type === 'single' && this._renderSingleEntryUi()}
						{this.isMultipleEntryUI && this._renderMultipleEntryUi()}
					</div>
					{projectSectionExists && type === 'single' && this._renderProjectTaskText()}
				</div>
			</div>
		);
	}
}

export default consume([
	// Properties
	'employee',
	'serverTime',
	'timesheetOptions',
	'clock',
	'focusedTimesheet.holidayPayType',
	'isViewOnly',
	'projectsWithTasks',
	// Actions
	'changeViewOption',
	'editSingleEntrySlat',
	'refreshTimesheet',
	'forgotClockOut',
	// Queries
	'getFocusedDays',
], Slat);
