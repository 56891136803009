import { BlankState, BodyText, Button, Flex, Headline, IconV2, StyledBox, createStyles, makeStyles } from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';
import { DynamicForm } from 'dynamic-form';
import { Fragment, ReactElement, useEffect } from 'react';

import { ifFeature } from '@bamboohr/utils/lib/feature';
import classNames from 'classnames';

export const styles = makeStyles(({ palette, typography }) => {
	return createStyles({
		headerTitle: ifFeature(
			'encore',
			{
				fontSize: '26px',
				fontWeight: typography.fontWeightSemibold,
				margin: 0,
			},
			{
				fontSize: '32px',
				fontWeight: typography.fontWeightRegular,
			}
		),
		headerDesc: {
			color: palette.gray[700],
			fontSize: typography.biggie.fontSize,
		},
		divider: {
			margin: '35px 0',
		},
		blankStateDivider: {
			margin: '35px 0 70px',
		},
		blankStateOverride: {
			marginBottom: '88px',
			'& p': {
				maxWidth: 'none',
			},
		},
		// @startCleanup encore
		employeePhoto: ifFeature('encore', undefined, {
			position: 'absolute',
			right: '70px',
			marginTop: '33px',
			zIndex: 1,
		}),
		// @endCleanup encore
		employeePhotoText: {
			fontSize: typography.teenie.fontSize,
			fabColor: palette.gray[700],
			lineHeight: '20px',
		},
		hidden: {
			display: 'none',
		},
	});
});

interface Props {
	data: any;
	isNHPT: boolean;
	photoRequired: boolean;
	photoURL: string;
	previewMode: boolean;
	showPhoto: boolean;
	employeeId: number | string;
	showPayrollFederal: boolean;
	showPayrollState: boolean;
}

export function NewEmployeeInfo(props: Props): ReactElement {
	const classes = styles();

	const {
		data,
		isNHPT,
		photoRequired = false,
		photoURL = '',
		previewMode = true,
		showPhoto = true,
		employeeId = -1,
		showPayrollFederal = false,
		showPayrollState = false,
	} = props;

	let photoSrc = '/images/photo_person_150x150.png';
	let hasPhoto = false;

	if (!photoURL.includes('initials')) {
		photoSrc = photoURL;
		hasPhoto = true;
	}

	useEffect(() => {
		$('.employeeForm .required').removeClass('required').addClass('visuallyRequired');
		$('.employeeForm span.inputIcons').addClass('disabled');
		$('.employeeForm .bhrToggle--btn').addClass('bhrToggle--btn--disabled');
		$('.employeeForm .fab-SelectToggle').addClass('fab-SelectToggle--disabled');
		// Disabling the date-picker and Fabric labels
		setTimeout(function () {
			$('.employeeForm .fab-Label, .AdditionalWithholding .fab-FormField').addClass('fab-Label--disabled');
		});
		$('.employeeForm .BhrTable__header--section').removeClass('BhrTable__header--section');
		// @ts-ignore
		$('.employeeForm').find('input, button, ba-select').addClass('disabled').attr('disabled', true);
	});

	const employeePhotoContainerClasses = classNames(classes.employeePhoto, 'js-fieldBox', {
		'required requiredWhenVisible visuallyRequired': photoRequired,
	});

	const employeePhotoLabel = classNames('fab-Label', {
		'fab-Label--required requiredWhenVisible visuallyRequired': photoRequired,
	});

	function onPhotoUploadHandler() {
		if (previewMode) {
			window.disabledForPermsTest();
		}
	}

	return (
		<div>
			{ifFeature(
				'encore',
				<StyledBox backgroundColor='neutral-weak' borderRadius='medium' padding={4}>
					<Headline color='primary' component='h2' size={'medium'}>
						<Message text={$._('New Employee Information')} />
					</Headline>
					<BodyText>
						<Message text={$._(`Let's start with the basics. `)} />
						<Message text={$._(`Please complete the following new employee form and add your profile photo. `)} />
						<Message text={$._(`This will ensure we have the most up-to-date information. `)} />
						<Message text={$._(`It will also help us get you up and running quickly with the required paperwork. `)} />
					</BodyText>
				</StyledBox>,
				<Fragment>
					<h2 className={classes.headerTitle}>
						<Message text={$._('New Employee Information')} color='secondary' />
					</h2>
					<p className={classes.headerDesc}>
						<Message text={$._(`Let's start with the basics. `)} />
						<Message text={$._(`Please complete the following new employee form and add your profile photo. `)} />
						<Message text={$._(`This will ensure we have the most up-to-date information. `)} />
						<Message text={$._(`It will also help us get you up and running quickly with the required paperwork. `)} />
					</p>
					<hr className={classes.divider} />
				</Fragment>
			)}

			{isNHPT && (
				<div className='NewEmployeeInfoBlankStateWrapper'>
					<div className={classes.blankStateOverride}>
						<BlankState
							icon='fab-person-index-card-120x92'
							subtitle={$.__(
								'You’ll be able to preview this step, along with the actual fields, when sending the actual New Hire Packet.'
							)}
							title={$.__(`Because this is just the template, we can’t show an accurate preview of this step.`)}
						/>
					</div>
				</div>
			)}

			{!isNHPT && (
				<Fragment>
					{showPhoto &&
						ifFeature(
							'encore',
							<StyledBox backgroundColor='neutral-weak' borderRadius='medium' marginTop={3} marginBottom={3} padding={4}>
								<Headline size={'medium'}>{$.__('Profile Photo')}</Headline>
								<input id='photo' name='photo' type='hidden' value={hasPhoto === true ? 1 : undefined} />
								<StyledBox
									backgroundColor='neutral-strong'
									borderRadius='large'
									height={'224px'}
									marginTop={4}
									v2={true}
									width={'224px'}
								>
									{!photoURL.includes('initials') ? (
										<img src={photoURL} style={{ borderRadius: 15 }} width={'100%'} />
									) : (
										<Flex alignItems={'center'} height={'224px'} justifyContent={'center'} width={'224px'}>
											<IconV2 color='neutral-forced-white' name={'user-solid'} size={170} />
										</Flex>
									)}
								</StyledBox>
								<StyledBox marginTop={4} marginLeft={3}>
									<Button color='secondary' onClick={onPhotoUploadHandler} size={'large'} startIcon={'circle-plus-regular'}>
										{$._('Add\nPhoto')}
									</Button>
								</StyledBox>
							</StyledBox>,
							<div className={employeePhotoContainerClasses}>
								<label className={employeePhotoLabel} htmlFor='photo'>
									{$.__('Profile Photo')}
								</label>
								<input id='photo' name='photo' type='hidden' value={hasPhoto === true ? 1 : undefined} />
								<div className='Photo childHover' id='employeePhoto' onClick={onPhotoUploadHandler}>
									<img className='Avatar Photo__image' src={photoSrc} />
									<div className='Photo__image-plus phoneHide'>
										<ba-icon name='fab-circle-plus-34x34' />
									</div>
									<div className={`Photo__image-overlay ${hasPhoto ? classes.hidden : ''}`}>
										<Message text={$._('Add\nPhoto')} />
									</div>
								</div>
							</div>
						)}
					<div className='employeeForm'>
						<DynamicForm
							additionalOptions={{
								employeeId,
								isNHPPacket: true,
								showPayrollFederal,
								showPayrollState,
							}}
							data={data}
							isAddEmployeeForm={false}
						/>
					</div>
				</Fragment>
			)}
		</div>
	);
}
