import { Icon, Button, TextButton } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import React from 'react';
import './styles.styl';
import classNames from 'classnames';

const noop = Function.prototype;

export const ModalActions = function ModalActions(props) {
	const {
		actionNote,
		additionalAction,
		additionalActionButtonType,
		additionalActionText,
		alternativeAction,
		alternativeActionText,
		biId,
		isAggressivelyConfirmed,
		isConfirmation,
		isLoading,
		isMobileFriendly = false,
		isProcessing,
		isSheet,
		onClose,
		primaryAction,
		primaryActionText,
		secondaryAction,
		secondaryActionText,
		type,
	} = props;
	// @startCleanup encore
	const encoreEnabled = ifFeature('encore', true, false);
	// @endCleanup encore

	const primaryActionCallback = (isConfirmation && !isAggressivelyConfirmed ? null : primaryAction);
	const primaryActionButton = (
		<Button
			clickAction={ typeof primaryActionCallback === 'function' ? primaryActionCallback : null }
			data-bi-id={ biId ? `${ biId }-modal-action-primary` : null }
			isDisabled={ !primaryActionCallback }
			isProcessing={ isProcessing }
			size={ifFeature('encore', undefined, 'large')}
			text={ primaryActionText }
			type="button"
		/>
	);

	const mobileActionClasses = classNames('legacyModal__actions', {
		[`legacyModal__actions--${ type }--mobileShow`]: !!isMobileFriendly,
		[`legacyModal__actions--mobileShow`]: !!isMobileFriendly,
	});

	const actionClasses = classNames('legacyModal__actions', { [`legacyModal__actions--mobileHide`]: !!isMobileFriendly });
	const actionContainerClasses = ifFeature('encore', classNames('legacyModal__actionsContainer', {'legacyModal__actionsContainer--hidden': isLoading}), '');

	function getPrimaryButton() {
		return primaryActionButton
	}
	function getSecondaryButton() {
		return typeof secondaryAction === 'function' ? (
			<Button
				clickAction={ secondaryAction }
				data-bi-id={ biId ? `${ biId }-modal-action-secondary` : null }
				isProcessing={ isProcessing }
				outline={ type === 'fullScreen' }
				secondary={ true }
				size={ifFeature('encore', undefined, 'large')}
				text={ secondaryActionText }
				type="button"
			/>
		) : null;
	}
	function getAlternativeAction() {
		return typeof alternativeAction === 'function' ? (
				<TextButton
					clickAction={ alternativeAction === noop ? onClose : alternativeAction }
					data-bi-id={ biId ? `${ biId }-modal-action-alternative` : null }
					isDisabled={ isProcessing }
					size={ifFeature('encore', undefined, 'large')}
					text={ alternativeActionText }
					type="button"
				/>
			) : null;
	}
	function getActionNote() {
		return actionNote ? (
			<div className={ 'actionNote' }>{ actionNote }</div>
		) : null;
	}

	function getAdditionalActions() {
		return typeof additionalAction === 'function' ? (
			<div className={'legacyModal__additionalAction'}>
				{React.createElement(
					(additionalActionButtonType === 'text') ? TextButton : Button,
					{
						clickAction: additionalAction,
						'data-bi-id': biId ? `${biId}-modal-action-additional` : null,
						secondary: true,
						text: additionalActionText,
						type: 'button'
					}
				)}
			</div>
		) : null;

	}

	const actionsArray = [
		getPrimaryButton,
		getSecondaryButton,
		getAlternativeAction,
		getActionNote,
		getAdditionalActions
	];

	function buildFooterActions(reverse = false) {
		if (reverse) {
			return actionsArray.reduceRight((actionsAcc, action) => {
				actionsAcc.push(action());
				return actionsAcc;
			}, []);
		}

			return actionsArray.map((action) => action());
	}

	return (
		<div className={actionContainerClasses}>
			{isMobileFriendly && isSheet && (
				// currently we can't add class names to buttons so in order to maintain the styles for sibling buttons
				// removing the group was the way to go. This does mean you get 2 primary actions buttons but only
				// one should show at a time thanks to CSS
				<div className={ mobileActionClasses }>
					{ primaryActionButton }
				</div>
			) }
			<div className={ actionClasses }>
				{ifFeature('encore', buildFooterActions(type !== 'fullScreen'), buildFooterActions(false))}
				{
					// @startCleanup encore
					type === 'fullScreen' && !encoreEnabled && !isMobileFriendly && <div className={ 'legacyModal__logo' }><Icon name="fab-bamboohr-logo-113x18" /></div>
					// @endCleanup encore
				}
			</div>
		</div>
	);
}
