import {startsWith} from 'lodash';
import warningTemplate from './shared/templates/warning';

export default (attemptedAction, callback) => {
	const modalTitle = (startsWith(attemptedAction, 'approve') ? $.__('Hours have changed') : $.__('Hours were not saved'));
	const primaryActionText = $.__('Refresh');
	let paragraphs = [];

	if (attemptedAction === 'save') {
		paragraphs = [
			$.__('Your timesheet was approved before you submitted your hours.'),
			$.__("Bad timing, but reach out to your manager or HR administrator and they should make sure you're taken care of."),
		];
	} else {
		paragraphs = [
			$.__('Some hours have been recently edited.') + ' ' + $.__('Click "Refresh" to see any recent changes.'),
			$.__('You will then be able to continue approving.'),
		];
	}

	const content = microTemplate(warningTemplate, {
		iconName: 'fab-stopwatch-exclamation-51x54',
		paragraphs,
	});

	window.BambooHR.Modal.setState({
		isOpen: true,
		hasCloseButton: false,
		alternativeAction: null,
		title: modalTitle,
		content: (
			<div dangerouslySetInnerHTML={ { __html: content } } />
		),
		primaryActionText,
		primaryAction() {
			window.BambooHR.Modal.setState({isOpen: true, isProcessing: true}, true);

			if (callback) {
				callback();
			} else {
				window.location.reload(true);
			}
		},
	});
};
