import { BlankState, createStyles, Divider, Headline, makeStyles, Section, TaskProgressBar } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Message } from '@bamboohr/utils/lib/message';
import { PacketTask } from 'NHPTemplates.mod/Form/store/Interfaces';
import { ReactElement, useMemo } from 'react';
import { Task } from './tasks-components';
import { TaskItem } from './tasks-components/task-item/task-item';

export const styles = makeStyles(({ palette, typography }) => {
	return createStyles({
		headerTitle: {
			fontWeight: typography.fontWeightMedium,
			fontSize: '32px',
		},
		headerDesc: {
			fontSize: typography.fabricFontSize('biggie'),
			color: palette.grey[700],
		},
		divider: {
			margin: '35px 0',
		},
		blankStateDivider: {
			margin: '35px 0 70px',
		},
		blankStateOverride: {
			marginBottom: '88px',
			'& p': {
				maxWidth: 'none',
			},
		},
		tasksWrapper: {
			backgroundColor: palette.grey[100],
			borderRadius: '3px',
			border: `1px solid ${palette.grey[300]}`,
			padding: '10px',
		},
		progress: {
			display: 'flex',
			alignItems: 'center',
		},
		progressBarBorder: {
			height: '15px',
			backgroundColor: palette.grey[300],
			borderRadius: '10px',
			width: '100px',
		},
		progressBar: {
			backgroundColor: palette.primary.main,
		},
		progressText: {
			color: palette.grey[600],
		},
		taskList: {
			marginTop: '10px',
			border: `1px solid ${palette.grey[300]}`,
			borderTop: `3px solid ${palette.primary.main}`,
			backgroundColor: palette.background.default,
			'& > div': {
				'&:not(:last-child)': {
					borderBottom: `1px solid ${palette.grey[300]}`,
				},
			},
		},
	});
});

interface Props {
	isNHPT: boolean;
	tasks: Array<PacketTask>;
}

export function Tasks(props: Props): ReactElement {
	const classes = styles();

	const { isNHPT, tasks = [] } = props;

	const [completed, numberOfTasks] = useMemo(() => {
		let completedTasks = 0;
		tasks.forEach((task) => {
			if (task.completed) completedTasks++;
		});
		return [completedTasks, tasks.length];
	}, [tasks]);

	const sortedTasks = useMemo(() => [...tasks].sort((a, b) => +a.nhpSortOrder - +b.nhpSortOrder), [tasks]);

	return ifFeature(
		'encore',
		<>
			<Section.Header
				description={$._(
					`We want to make sure that you are ready to go on your first day. Please complete the tasks below so we can get you up and running quickly.`
				)}
				title={<Headline size='medium'>{$._('Tasks to Complete')}</Headline>}
			/>
			<Divider marginBottom={3} marginTop={2} />
			<TaskProgressBar current={completed} note={$._('Completed')} total={numberOfTasks} width={160} />
			<Divider marginTop={3} />
			{sortedTasks.map((task, index) => {
				return <TaskItem index={index} key={`task-${task.name}`} task={task} />;
			})}
		</>,
		<div>
			<div>
				<h2 className={classes.headerTitle}>
					<Message text={$._('Tasks to Complete')} />
				</h2>
				<p className={classes.headerDesc}>
					<Message
						text={$._(
							`We want to make sure that you are ready to go on your first day. Please complete the tasks below so we can get you up and running quickly.`
						)}
					/>
				</p>
				<hr className={classes.divider} />
				{isNHPT && (
					<div className='TasksBlankStateWrapper'>
						<div className={classes.blankStateOverride}>
							<BlankState
								icon='fab-clipboard-outlined-100x120'
								subtitle={$.__('You’ll be able to preview this step on the actual New Hire Packet after tasks are added.')}
								title={$.__(`Because this is just the template, there aren’t any tasks to preview.`)}
							/>
						</div>
					</div>
				)}
				{!isNHPT && (
					<div className={classes.tasksWrapper}>
						<div className={classes.progress}>
							<div className='ProgressBar ProgressBar--tasklist fl-left'>
								<div
									className='ProgressBar__bar ProgressBar__bar--tasklist branded-icon baseColorBg'
									style={{ width: `${(completed / numberOfTasks) * 100}%` }}
								/>
							</div>
							<h5 className={classes.progressText}>
								{completed} of {numberOfTasks} Completed
							</h5>
						</div>
						<div className={classes.taskList}>
							{sortedTasks.map((task, index) => {
								return <Task index={index} key={`task-${task.name}`} task={task} />;
							})}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
