import { FunctionComponent, useState } from 'react';
import { PageActionsFooter } from 'page-actions-footer.react';
import classnames from 'classnames';
import { Button, LayoutBox, TextButton } from '@fabric/button';
import {
	resetForm,
	determineValidEditableField,
	isSelectedPSSyncing,
	scrollToError,
} from 'dynamic-form';

import { isEnabled } from 'FeatureToggle.util';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const GENDER_TOGGLE = isEnabled('GenderSelectUpdates');

const Form: FunctionComponent<DynamicForm.FormElementProps> = ({
	props,
	children,
	settings,
	context,
}) => {
	const { className = '', id } = props;
	const {
		isClickToEdit = false,
		customFooterData = {
			primaryButton: {},
			secondaryButton: {}
		},
		validation = {},
	} = settings;
	const {
		primaryButton = {},
		secondaryButton = {},
	} = customFooterData;
	const {
		form: { isEditable, setIsEditable },
		validation: { trigger, formState: { errors } },
	} = context;
	const [isProcessing, setIsProcessing] = useState(false);

	const classes = classnames(...className.split(' '), {
		// TODO: If we are going to keep this, move it off of BhrForms namespace -> Personal Tabs
		'BhrForms--view': isClickToEdit ? !isEditable : false,
		DynamicForm__TraxSyncing: isSelectedPSSyncing(context),
	});

	const handleFormEditable = (e): void => {
		const isValidTag = determineValidEditableField(e);
		if (!isEditable && isValidTag) {
			setIsEditable(true);
		}
	};

	const handleSubmit = async () => {
		closeMessage();
		setIsProcessing(true);
		const isValid = (validation.noValidation && GENDER_TOGGLE) ? true : await trigger();
		console.log('isValid', isValid, errors);
		if (isValid) {
			const $form = $(`#${ id }`);

			if (window.ASSUMED_USER) {
				window.disabledForPermsTest();
				return;
			}

			$form.submit();
		} else {
			setMessage('Whoops... No worries. Please fix any missing or incorrect information and try again.', 'error');
			setIsProcessing(false);
			scrollToError();
		}
	};

	const handleCancel = (): void => {
		resetForm(context);
		if (isClickToEdit) {
			setIsEditable(false);
		}
	};

	return (
		<form { ...props } className={ classes } onClick={ handleFormEditable }>
			<LayoutBox marginTop={ifFeature('encore', id === "emergencyContact" ? "-10px" : "-24px")}>
				{ children }
				<PageActionsFooter isOpen={ isEditable }>
					<Button clickAction={ handleSubmit } isProcessing={ isProcessing } text={ primaryButton.text || $.__('Save Changes') } type="submit" />
					<TextButton clickAction={ handleCancel } isProcessing={ isProcessing } text={ secondaryButton.text || $.__('Cancel') } type="reset" />
				</PageActionsFooter>
			</LayoutBox>
		</form>
	);
};

export default Form;
