import {
	createContext,
} from 'react';

interface WithholdingContextData {
	isFullPage: boolean;
}

export const WithholdingContext = createContext<WithholdingContextData>({
	isFullPage: true,
});
WithholdingContext.displayName = 'WithholdingContext';
