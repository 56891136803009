import {
	Label,
	LayoutBox,
	Select,
} from '@bamboohr/fabric';
/* @startCleanup encore */
import { ifFeature } from '@utils/feature';
/* @endCleanup encore */
import { Message, ExternalLink } from '@utils/message';
import {
	Fragment,
	useState,
} from 'react';
import c from 'classnames';

/* @startCleanup encore */
import { Select as OldSelect } from '@fabric/select';
/* @endCleanup encore */

import { AdditionalWithholding } from '../additional-withholding.react';
import { HelpPopover } from '../help-popover.react';
import { SectionStyledBox } from '../section-styled-box';
import { SectionStyledHeader } from '../section-styled-header';

import './fed-withholding-2020.styl';

const fedWithholding2020SelectItems = [
	{
		text: $.__('Single, or Married Filing Separately'),
		value: 'Single',
	},
	{
		text: $.__('Married Filing Jointly'),
		value: 'Married',
	},
	{
		text: $.__('Head of Household'),
		value: 'marriedSingleRate',
	},
];

export function FedWithholding2020(props) {
	const {
		filingStatus: selectedFilingStatus,
		twoJobs = false,
		dependentsAmount = '',
		otherIncome = '',
		deductions = '',
		viewOnly = false,
		additionalWithholding = {
			amount: 0,
			type: 'currency',
		},
		ignoreStatusExemptions: selectedIgnoreStatus = false,
		futaExempt = false,
		ficaExempt = false,
		showExemptions = true,
		showWithholding,
	} = props;
	const {
		amount,
		type,
	} = additionalWithholding;

	const [filingStatus, setFilingStatus] = useState(selectedFilingStatus);
	const [ignoreStatus, setIgnoreStatus] = useState(selectedIgnoreStatus);

	const filingStatusLabelClasses = c(
		'fab-Label fab-Label--required',
		{ 'fab-Label--disabled': ignoreStatus || viewOnly }
	);
	const otherLabelClasses = c(
		'fab-Label',
		{ 'fab-Label--disabled': viewOnly }
	);
	/* @startCleanup encore */
	const fieldsetClasses = c(
		'FedWithholding2020',
		'fab-FormSection',
		{ 'fab-FormSection--hidden': showWithholding === false },
	);
	/* @endCleanup encore */

	return ifFeature('encore',
		(
			<SectionStyledBox
				hidden={ showWithholding === false }
				paddingTop={ 2 }
			>
				<fieldset>
					<SectionStyledHeader
						hasPopover={ true }
						popoverContent={ (
							<Message
								// eslint-disable-next-line new-cap
								link={ ExternalLink('https://apps.irs.gov/app/withholdingcalculator/') }
								text={ $._('This determines how much you will withhold from your paycheck for federal taxes. The IRS has provided a handy [withholding calculator] to help you figure out what to elect.') }
							/>
						) }
						popoverIcon="circle-question-regular"
						popoverPlacement="bottom"
						popoverTitle={ $.__('Federal Tax Withholding') }
					>
						{ $.__('Federal Tax Withholding') }
					</SectionStyledHeader>
					<LayoutBox marginTop={ 2 }>
						<label className={ filingStatusLabelClasses } htmlFor="filingStatus">{ $.__('Filing Status') }</label>
					</LayoutBox>
					<div className="fab-FormRow">
						<div className="fab-FormColumn">
							<Select
								isDisabled={ ignoreStatus || viewOnly }
								items={ fedWithholding2020SelectItems }
								name="fed[status2020]"
								onChange={ (selectedValues) => {
									const [value = ''] = selectedValues;
									setFilingStatus(value);
								} }
								onClear={ () => {
									setFilingStatus('');
								} }
								selectedValues={ [filingStatus] }
								width={ 8 }
							/>
						</div>
						{ showExemptions && (
							<div className="fab-Checkbox fab-FormColumn">
								<input
									className="fab-Checkbox__input"
									defaultChecked={ ignoreStatus ? 'checked' : null }
									disabled={ viewOnly ? 'disabled' : null }
									id="ignoreStatus"
									name="fed[taxExempt]"
									onChange={ event => setIgnoreStatus(event.target.checked) }
									type="checkbox"
								/>
								<label className="fab-Checkbox__label" htmlFor="ignoreStatus">{ $.__('Ignore filing status/other adjustments') }</label>
							</div>
						) }
					</div>
					<div className="fab-FormRow">
						<div className="fab-Checkbox">
							<input
								className="fab-Checkbox__input"
								defaultChecked={ twoJobs }
								disabled={ viewOnly ? 'disabled' : null }
								id="twoJobs"
								name="fed[twoJobs]"
								type="checkbox"
							/>
							<label className="fab-Checkbox__label" htmlFor="twoJobs">{ $.__('Works two jobs or spouse works?') }</label>
						</div>
					</div>
					<div className="fab-FormRow">
						<div className="fab-FormColumn">
							<label className={ otherLabelClasses } htmlFor="dependentsAmt">{ $.__('Dependents Amount') }</label>
							<input
								className="fab-TextInput fab-TextInput--width5 currency-field"
								data-cursymbol="$"
								data-decimal-char="."
								data-thousands-separator=","
								defaultValue={ dependentsAmount }
								disabled={ viewOnly ? 'disabled' : null }
								id="dependentsAmt"
								name="fed[dependentsAmt]"
								type="text"
							/>
						</div>
						<div className="fab-FormColumn">
							<label className={ otherLabelClasses } htmlFor="otherIncome">{ $.__('Other Income') }</label>
							<input
								className="fab-TextInput fab-TextInput--width5 currency-field"
								data-cursymbol="$"
								data-decimal-char="."
								data-thousands-separator=","
								defaultValue={ otherIncome }
								disabled={ viewOnly ? 'disabled' : null }
								id="otherIncome"
								name="fed[otherIncome]"
								type="text"
							/>
						</div>
						<div className="fab-FormColumn">
							<label className={ otherLabelClasses } htmlFor="deductionsAmt">{ $.__('Deductions Amount') }</label>
							<input
								className="fab-TextInput fab-TextInput--width5 currency-field"
								data-cursymbol="$"
								data-decimal-char="."
								data-thousands-separator=","
								defaultValue={ deductions }
								disabled={ viewOnly ? 'disabled' : null }
								id="deductionsAmt"
								name="fed[deductionsAmt]"
								type="text"
							/>
						</div>
					</div>
					<AdditionalWithholding
						data={ {
							amount,
							type
						} }
						is2020={ true }
						type="fed"
						viewOnly={ viewOnly }
					/>
					{ showExemptions && (
						<Fragment>
							<Label>
								{ $.__('Exemption Options') }
							</Label>
							<div className="fab-FormRow">
								<div className="fab-Checkbox fab-FormColumn">
									<input
										className="fab-Checkbox__input"
										defaultChecked={ futaExempt }
										disabled={ viewOnly ? 'disabled' : null }
										id="futaExempt"
										name="fed[futaExempt]"
										type="checkbox"
									/>
									<label className="fab-Checkbox__label" htmlFor="futaExempt">{ $.__('FUTA exempt') }</label>
								</div>
								<div className="fab-Checkbox fab-FormColumn">
									<input
										className="fab-Checkbox__input"
										defaultChecked={ ficaExempt }
										disabled={ viewOnly ? 'disabled' : null }
										id="ficaExempt"
										name="fed[ficaExempt]"
										type="checkbox"
									/>
									<label className="fab-Checkbox__label" htmlFor="ficaExempt">{ $.__('FICA exempt') }</label>
								</div>
							</div>
						</Fragment>
					) }
				</fieldset>
			</SectionStyledBox>
		),
		(
			<fieldset className={ fieldsetClasses }>
				<legend className="fab-FormSection__legend">
					{ $.__('Federal Tax Withholding') }
					<HelpPopover { ...getHelpPopoverProps() } />
				</legend>
				<label className={ filingStatusLabelClasses } htmlFor="filingStatus">{ $.__('Filing Status') }</label>
				<div className="fab-FormRow">
					<div className="fab-FormColumn">
						<OldSelect { ...getFilingStatusProps(filingStatus, setFilingStatus, ignoreStatus, viewOnly) } />
					</div>
					{ showExemptions && (
						<div className="fab-Checkbox fab-FormColumn">
							<input
								className="fab-Checkbox__input"
								defaultChecked={ ignoreStatus ? 'checked' : null }
								disabled={ viewOnly ? 'disabled' : null }
								id="ignoreStatus"
								name="fed[taxExempt]"
								onChange={ event => setIgnoreStatus(event.target.checked) }
								type="checkbox"
							/>
							<label className="fab-Checkbox__label" htmlFor="ignoreStatus">{ $.__('Ignore filing status/other adjustments') }</label>
						</div>
					) }
				</div>
				<div className="fab-FormRow">
					<div className="fab-Checkbox">
						<input
							className="fab-Checkbox__input"
							defaultChecked={ twoJobs }
							disabled={ viewOnly ? 'disabled' : null }
							id="twoJobs"
							name="fed[twoJobs]"
							type="checkbox"
						/>
						<label className="fab-Checkbox__label" htmlFor="twoJobs">{ $.__('Works two jobs or spouse works?') }</label>
					</div>
				</div>
				<div className="fab-FormRow">
					<div className="fab-FormColumn">
						<label className={ otherLabelClasses } htmlFor="dependentsAmt">{ $.__('Dependents Amount') }</label>
						<input
							className="fab-TextInput fab-TextInput--width5 currency-field"
							data-cursymbol="$"
							data-decimal-char="."
							data-thousands-separator=","
							defaultValue={ dependentsAmount }
							disabled={ viewOnly ? 'disabled' : null }
							id="dependentsAmt"
							name="fed[dependentsAmt]"
							type="text"
						/>
					</div>
					<div className="fab-FormColumn">
						<label className={ otherLabelClasses } htmlFor="otherIncome">{ $.__('Other Income') }</label>
						<input
							className="fab-TextInput fab-TextInput--width5 currency-field"
							data-cursymbol="$"
							data-decimal-char="."
							data-thousands-separator=","
							defaultValue={ otherIncome }
							disabled={ viewOnly ? 'disabled' : null }
							id="otherIncome"
							name="fed[otherIncome]"
							type="text"
						/>
					</div>
					<div className="fab-FormColumn">
						<label className={ otherLabelClasses } htmlFor="deductionsAmt">{ $.__('Deductions Amount') }</label>
						<input
							className="fab-TextInput fab-TextInput--width5 currency-field"
							data-cursymbol="$"
							data-decimal-char="."
							data-thousands-separator=","
							defaultValue={ deductions }
							disabled={ viewOnly ? 'disabled' : null }
							id="deductionsAmt"
							name="fed[deductionsAmt]"
							type="text"
						/>
					</div>
				</div>
				<AdditionalWithholding
					data={ {
						amount,
						type
					} }
					is2020={ true }
					type="fed"
					viewOnly={ viewOnly }
				/>
				{ showExemptions && (
					<Fragment>
						<legend className="fab-CheckboxGroup__legend">{ $.__('Exemption Options') }</legend>
						<div className="fab-FormRow">
							<div className="fab-Checkbox fab-FormColumn">
								<input
									className="fab-Checkbox__input"
									defaultChecked={ futaExempt }
									disabled={ viewOnly ? 'disabled' : null }
									id="futaExempt"
									name="fed[futaExempt]"
									type="checkbox"
								/>
								<label className="fab-Checkbox__label" htmlFor="futaExempt">{ $.__('FUTA exempt') }</label>
							</div>
							<div className="fab-Checkbox fab-FormColumn">
								<input
									className="fab-Checkbox__input"
									defaultChecked={ ficaExempt }
									disabled={ viewOnly ? 'disabled' : null }
									id="ficaExempt"
									name="fed[ficaExempt]"
									type="checkbox"
								/>
								<label className="fab-Checkbox__label" htmlFor="ficaExempt">{ $.__('FICA exempt') }</label>
							</div>
						</div>
					</Fragment>
				) }
			</fieldset>
		)
	);
}

/* @startCleanup encore */
function getFilingStatusProps(filingStatus, setFilingStatus, ignoreStatus, viewOnly) {
	return {
		isDisabled: ignoreStatus || viewOnly,
		items: [
			{
				text: $.__('Single, or Married Filing Separately'),
				value: 'Single',
			},
			{
				text: $.__('Married Filing Jointly'),
				value: 'Married',
			},
			{
				text: $.__('Head of Household'),
				value: 'marriedSingleRate',
			},
		],
		name: 'fed[status2020]',
		onClear: () => setFilingStatus(''),
		onSelect: value => setFilingStatus(value || ''),
		selectedValues: [filingStatus],
		width: '8',
	};
}

function getHelpPopoverProps() {
	const contentFirst = $.__('This determines how much you will withhold from your paycheck for federal taxes.');
	const contentSecond = $.__('The IRS has provided a handy %1$swithholding calculator%2$s to help you figure out what to elect.', '<a href="https://apps.irs.gov/app/withholdingcalculator/" target="_blank" rel="noopener noreferrer">', '</a>');
	const content = `${ contentFirst } ${ contentSecond }`;
	return {
		title: $.__('Federal Tax Withholding'),
		content,
		addTop: false
	};
}

/* @endCleanup encore */
