import { makeStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import classNames from 'classnames';
import { Fragment, useEffect, useRef, useState } from 'react';
import { PageHeaderIcon } from './icon';
import { SubTitle } from './subtitle';

const useRoundedStyles = makeStyles(() => ({
	rounded: {
		'&&:before': {
			width: ifFeature('encore', (props) => props.beforeWidth),
		},
	},
}));

export function PageHeader(props) {
	const { includeBorder = true, rounded = false, leftOptionalBlock, rightOptionalBlock, size, back, icon, title, subTitle } = props;

	let _size = size;
	const isLargeHeader = _size === 'lg' || _size === 'xl';

	if (!leftOptionalBlock && !isLargeHeader) {
		_size = 'sm';
	}
	if (leftOptionalBlock && !isLargeHeader) {
		_size = 'md';
	}

	const _handleBackAction = back && back.action ? (event) => back.action(event) : undefined;

	const borderClass = includeBorder ? '' : 'PageHeader--noBorder';
	const roundedClass = rounded ? `PageHeader--rounded` : '';

	const pageWrapper = useRef(document.getElementById('js-PageCapsule'));
	const [beforeWidth, setBeforeWidth] = useState('100%');

	useEffect(() => {
		if (!rounded || !pageWrapper.current) return;

		const resizeObserver = new ResizeObserver(() => {
			setBeforeWidth(pageWrapper.current.offsetWidth);
		});
		resizeObserver.observe(pageWrapper.current);

		return () => resizeObserver.disconnect();
	}, [rounded]);

	const roundedStyles = useRoundedStyles({ beforeWidth });

	return (
		<header
			className={classNames(`PageHeader PageHeader--${_size} ${borderClass} ${roundedClass}`, {
				[roundedStyles.rounded]: ifFeature('encore', rounded, false),
			})}
		>
			{back && (back.value || back.extraContent) && (
				<Fragment>
					{back.value ? (
						<a className='PageHeader__backBtn' href={back.destination} onClick={_handleBackAction}>
							<span className='PageHeader__backBtnArrow'>
								<PageHeaderIcon icon='fab-arrow-left-8x7' />
							</span>
							{back.value}
						</a>
					) : null}
					{back.extraContent && back.extraContent()}
				</Fragment>
			)}
			<div className='PageHeader__titleWrap'>
				{icon && (
					<span className='PageHeader__titleIcon'>
						<PageHeaderIcon icon={icon} />
					</span>
				)}
				<div className={`PageHeader__titleSubTitleWrap--${_size}`}>
					{isLargeHeader ? <h1 className='PageHeader__title'>{title}</h1> : <h2 className='PageHeader__title'>{title}</h2>}
					{subTitle && isLargeHeader && (
						<h4 className='PageHeader__subTitle--large'>
							<SubTitle subTitle={subTitle} />
						</h4>
					)}
					{subTitle && !isLargeHeader && (
						<p className='PageHeader__subTitle'>
							{' '}
							<SubTitle subTitle={subTitle} />{' '}
						</p>
					)}
				</div>

				{_size !== 'md' && rightOptionalBlock && <div className='PageHeader__optionalContent--right'>{rightOptionalBlock()}</div>}
			</div>

			{_size === 'md' && leftOptionalBlock && (
				<div className='PageHeader__optionalContentWrap'>
					<div>{leftOptionalBlock()}</div>
					<div>{rightOptionalBlock && rightOptionalBlock()}</div>
				</div>
			)}
		</header>
	);
}
