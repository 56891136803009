import { ReactElement } from 'react';
import { Message } from '@bamboohr/utils/lib/message';
import { styles } from './styles';
import { Question } from 'NHPTemplates.mod/Form/Interfaces';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { BodyText, Divider, Flex, Headline, LayoutBox, TextArea } from '@bamboohr/fabric';

interface Props {
	gtkyQuestions: Array<Question>;
}

export function GetIntroduced(props: Props): ReactElement {
	const classes = styles();

	const { gtkyQuestions = [] } = props;

	const questionElements = gtkyQuestions.map((question: Question, ind: number) =>
		ifFeature(
			'encore',
			<Flex key={question.sortOrder} marginBottom={'20px'} flexDirection={'column'}>
				<TextArea
					disabled={true}
					label={question.question}
					required={question.required}
					width={10}
					value={question.employeeResponse ?? ''}
					id={`question-${ind}`}
				/>
			</Flex>,
			<div className={classes.question} key={question.sortOrder}>
				<label className={`fab-Label ${question.required ? 'fab-Label--required' : ''}`} htmlFor={`question-${ind}`}>
					{question.question}
				</label>
				<textarea
					className='fab-Textarea fab-Textarea--width10'
					defaultValue={question.employeeResponse ?? ''}
					disabled={true}
					id={`question-${ind}`}
				/>
			</div>
		)
	);

	return ifFeature(
		'encore',
		<LayoutBox>
			<LayoutBox>
				<Headline size={'medium'}>
					<Message text={$._('Get Introduced to The Team')} />
				</Headline>
				<LayoutBox marginTop={'4px'}>
					<BodyText size='extra-small' color='neutral-strong'>
						{$._(
							`You're almost done, but first we'd like to get to know a bit more about you so we can introduce you to the rest of the team. Please answer the following questions and we'll send out a quick introduction email to the people you'll be working with.`
						)}
					</BodyText>
				</LayoutBox>
			</LayoutBox>
			<Divider color='neutral-extra-weak' marginTop={'16px'} marginBottom={'24px'} />
			<LayoutBox marginBottom={'24px'}>
				<Headline size='small'>{$._('Tell us a little about yourself:')}</Headline>
			</LayoutBox>
			<LayoutBox>{questionElements}</LayoutBox>
		</LayoutBox>,
		<div>
			<div>
				<h2 className={classes.headerTitle}>
					<Message text={$._('Get Introduced to The Team')} />
				</h2>
				<p className={classes.headerDesc}>
					<Message
						text={$._(
							`You're almost done, but first we'd like to get to know a bit more about you so we can introduce you to the rest of the team. `
						)}
					/>
					<Message
						text={$._(
							`Please answer the following questions and we'll send out a quick introduction email to the people you'll be working with.`
						)}
					/>
				</p>
			</div>
			<hr className={classes.divider} />
			<h3>
				<Message text={$._('Tell us a little about yourself:')} />
			</h3>
			<div className={classes.inputs}>{questionElements}</div>
		</div>
	);
}
