/* eslint-disable no-negated-condition */
import {
	Flex,
} from '@bamboohr/fabric';
/* @startCleanup encore */
import { ifFeature } from '@utils/feature';
/* @endCleanup encore */
import {
	Fragment,
	useState,
	useEffect,
} from 'react';
import c from 'classnames';

import { isEnabled } from 'FeatureToggle.util';

import { canShowIowaStateMessage, shouldUseNewIowaForm } from './withholding.domain';
import { FedWithholding } from '../fed-withholding.react';
import { FedWithholding2020 } from '../fed-withholding-2020.react';
import { IowaStateWithholding } from '../iowa-state-withholding.react';
import { StateWithholding } from '../state-withholding.react';
import { StateUnemployment } from '../state-unemployment.react';
import { WithholdingBlankState } from '../withholding-blank-state.react';
import { validateFederalTaxData, validateStateTaxData } from '../../utils';
import {
	WithholdingContext,
} from '../../context';

import './withholding.styl';

export function Withholding(props) {
	const {
		data,
		newHirePacket,
		show,
		fedPerms,
		statePerms,
		onStateLocationChange,
		onStateUnemploymentLocationChange,
		isAdminUser,
		isFullPage = true,
		isGreenhouse = false,
		isPayrollAdminUser = false,
		canAccessStateUiFields = false,
		renderFederal = true,
		renderState = true,
		renderStateUnemployment = true,
		showWithholdingInformation,
		stateSectionNote = '',
		stateUnemploymentSectionNote = '',
		showStateLocationRequiredAsterisk = false,
		showStateUnemploymentLocationRequiredAsterisk = false,
	} = props;

	const {
		federal,
		federal2020,
		has2020W4,
		is2020Release,
		state,
		stateUnemployment,
	} = data;

	const isNewIowaFormAccessEnabled = isGreenhouse || isEnabled('PAY_INFO_TAB_IOWA_STATE_WITHHOLDING_VIEW_ACCESS');
	const [temporary2020, setTemporary2020] = useState(false);
	const [newIowaStateForm, setNewIowaStateForm] = useState(isNewIowaFormAccessEnabled && state ? shouldUseNewIowaForm(state.locations, state.locations.selected) : false);
	const [selectedStateLocation, setSelectedStateLocation] = useState(isNewIowaFormAccessEnabled && state && state.locations.selected ? state.locations.selected : null);
	const [showIowaStateMessage, setShowIowaStateMessage] = useState(isNewIowaFormAccessEnabled && state ? canShowIowaStateMessage(state.locations, selectedStateLocation) : false);
	const feAccessLevelsQuickForms = isEnabled('PAY_INFO_TAB_ACCESS_LEVELS');
	const callbackReset2020Form = () => setTemporary2020(false);
	const callbackResetIowaStateForm = () => {
		if (isNewIowaFormAccessEnabled) {
			setSelectedStateLocation(state.locations.selected ?? null);
			if (shouldUseNewIowaForm(state.locations, state.locations.selected) === false) {
				setNewIowaStateForm(false);
			}
		}
	};

	// If they cancel a 2020 or newIowa form edit, revert back to pre-2020 Federal Withholding or pre-new iowa form fields.
	useEffect(() => {
		const payInfoTabFooter = document.querySelector('button[data-action="SiteFooterAction:cancel"]');

		if (payInfoTabFooter) {
			payInfoTabFooter.addEventListener('click', callbackReset2020Form);
			payInfoTabFooter.addEventListener('click', callbackResetIowaStateForm);
		}

		return () => {
			// Clean up: remove the listener when the React component is unmounted
			if (payInfoTabFooter) {
				payInfoTabFooter.removeEventListener('click', callbackReset2020Form);
				payInfoTabFooter.removeEventListener('click', callbackResetIowaStateForm);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isNewIowaFormAccessEnabled && selectedStateLocation) {
			setShowIowaStateMessage(canShowIowaStateMessage(state.locations, selectedStateLocation));
			setNewIowaStateForm(shouldUseNewIowaForm(state.locations, selectedStateLocation));
		} else {
			setShowIowaStateMessage(false);
			setNewIowaStateForm(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedStateLocation]);

	let showFederalWithholding = null;
	let showStateWithholding = null;
	let showStateUi = null;

	if (showWithholdingInformation) {
		({
			showFederalWithholding,
			showStateWithholding,
			showStateUi,
		} = showWithholdingInformation);
	}

	const showFederal2020 = is2020Release && (has2020W4 || temporary2020);

	/* @startCleanup encore */
	const federalClasses = c(
		'Federal',
		{ 'Federal--2020': showFederal2020 },
		{ 'Federal--hidden': showFederalWithholding === false },
	);
	/* @endCleanup encore */
	const isNhpPreview = newHirePacket && window.previewUser;

	const isEmployeeSelfService = window.isSelfService;
	const stateAccessLevel = window.stateTaxDeductionsPermissions;
	const unemploymentAccessLevel = window.unemploymentInsurancePermissions;
	// Access Levels: 0 => No Access; 1 or 2 => View; 3 => Edit;
	const stateEditAccessLevel = stateAccessLevel === 3;
	const stateViewOnlyAccessLevel = stateAccessLevel === 2 || stateAccessLevel === 1;
	const unemploymentEditAccessLevel = unemploymentAccessLevel === 3;
	const unemploymentViewOnlyAccessLevel = unemploymentAccessLevel === 2 || unemploymentAccessLevel === 1;

	if (
		(statePerms !== 0 && !validateStateTaxData(data)) ||
		(fedPerms !== 0 && !validateFederalTaxData(data))
	) {
		return <WithholdingBlankState />;
	}

	if (!show) {
		return (
			<div></div>
		);
	}
	return ifFeature('encore', (
		<WithholdingContext.Provider
			value={ {
				isFullPage,
			} }
		>
			<Flex flexDirection="column" gap={ 2 }>
				{ renderWithholding() }
			</Flex>
		</WithholdingContext.Provider>
	), renderWithholding());

	function renderWithholding() {
		return (
			<Fragment>
				{ renderFederal && (
					<>
						{ (fedPerms > 0 && federal2020 && showFederal2020) ? (
							ifFeature('encore',
								(
									<FedWithholding2020
										showExemptions={ isPayrollAdminUser }
										showWithholding={ showFederalWithholding }
										viewOnly={ (fedPerms === 1) || isNhpPreview }
										{ ...federal2020 }
									/>
								),
								(
									<div className={ federalClasses }>
										<FedWithholding2020
											showExemptions={ isPayrollAdminUser }
											showWithholding={ showFederalWithholding }
											viewOnly={ (fedPerms === 1) || isNhpPreview }
											{ ...federal2020 }
										/>
									</div>
								)
							)
						) : null }
						{ (fedPerms > 0 && federal && !showFederal2020) ? (
							ifFeature('encore',
								(
									<FedWithholding
										editPermission={ fedPerms === 3 }
										handle2020ToggleClick={ () => setTemporary2020(true) }
										isAdminUser={ isAdminUser }
										isPayrollAdminUser={ isPayrollAdminUser }
										newHirePacket={ newHirePacket }
										show2020Message={ is2020Release }
										viewOnly={ (fedPerms === 1) || is2020Release }
										{ ...federal }
									/>
								),
								(
									<div className={ federalClasses }>
										<FedWithholding
											editPermission={ fedPerms === 3 }
											handle2020ToggleClick={ () => setTemporary2020(true) }
											isAdminUser={ isAdminUser }
											isPayrollAdminUser={ isPayrollAdminUser }
											newHirePacket={ newHirePacket }
											show2020Message={ is2020Release }
											viewOnly={ (fedPerms === 1) || is2020Release }
											{ ...federal }
										/>
									</div>
								)
							)
						) : null }
					</>
				) }
				{
					feAccessLevelsQuickForms && (
						<>
							{/*Checks if Access Level permission is on the page*/ }
							{ (stateAccessLevel !== undefined) ? (
								<>
									{ (stateEditAccessLevel || stateViewOnlyAccessLevel) && (
										<>
											{ (renderState && state) &&
												<>
													{ !newIowaStateForm ? (
														<StateWithholding
															accessLevel={ stateAccessLevel }
															editPermission={ stateEditAccessLevel }
															handleNewIowaFormToggleClick={ () => setNewIowaStateForm(true) }
															isAdminUser={ isAdminUser }
															isPayrollAdminUser={ isPayrollAdminUser }
															newHirePacket={ newHirePacket }
															onLocationChange={ onStateLocationChange }
															sectionNote={ stateSectionNote }
															selectedLocation={ selectedStateLocation }
															setSelectedStateLocation={ setSelectedStateLocation }
															setShowIowaStateMessage={ setShowIowaStateMessage }
															showIowaStateMessage={ showIowaStateMessage && isNewIowaFormAccessEnabled }
															showLocationRequiredAsterisk={ showStateLocationRequiredAsterisk }
															showWithholding={ showStateWithholding }
															viewOnly={ stateViewOnlyAccessLevel || isNhpPreview }
															{ ...state }
														/>
													) : (
														<IowaStateWithholding
															accessLevel={ stateAccessLevel }
															editPermission={ stateEditAccessLevel }
															isAdminUser={ isAdminUser }
															isPayrollAdminUser={ isPayrollAdminUser }
															newHirePacket={ newHirePacket }
															onLocationChange={ onStateLocationChange }
															sectionNote={ stateSectionNote }
															selectedLocation={ selectedStateLocation }
															setNewIowaStateForm={ setNewIowaStateForm }
															setSelectedStateLocation={ setSelectedStateLocation }
															showLocationRequiredAsterisk={ showStateLocationRequiredAsterisk }
															showWithholding={ showStateWithholding }
															viewOnly={ stateViewOnlyAccessLevel || isNhpPreview }
															{ ...state }
														/>
													) }
												</>
											}
										</>
									) }
								</>
							) : (
								<>
									{ (renderState && statePerms > 0 && state) &&
										<>
											{ !newIowaStateForm ? (
												<StateWithholding
													editPermission={ statePerms === 3 }
													handleNewIowaFormToggleClick={ () => setNewIowaStateForm(true) }
													isAdminUser={ isAdminUser }
													isPayrollAdminUser={ isPayrollAdminUser }
													newHirePacket={ newHirePacket }
													onLocationChange={ onStateLocationChange }
													sectionNote={ stateSectionNote }
													selectedLocation={ selectedStateLocation }
													setSelectedStateLocation={ setSelectedStateLocation }
													setShowIowaStateMessage={ setShowIowaStateMessage }
													showIowaStateMessage={ showIowaStateMessage && isNewIowaFormAccessEnabled }
													showLocationRequiredAsterisk={ showStateLocationRequiredAsterisk }
													showWithholding={ showStateWithholding }
													viewOnly={ (statePerms === 1) || isNhpPreview }
													{ ...state }
												/>
											) : (
												<IowaStateWithholding
													editPermission={ statePerms === 3 }
													isAdminUser={ isAdminUser }
													isPayrollAdminUser={ isPayrollAdminUser }
													newHirePacket={ newHirePacket }
													onLocationChange={ onStateLocationChange }
													sectionNote={ stateSectionNote }
													selectedLocation={ selectedStateLocation }
													setNewIowaStateForm={ setNewIowaStateForm }
													setSelectedStateLocation={ setSelectedStateLocation }
													showLocationRequiredAsterisk={ showStateLocationRequiredAsterisk }
													showWithholding={ showStateWithholding }
													viewOnly={ (statePerms === 1) || isNhpPreview }
													{ ...state }
												/>
											) }
										</>
									}
								</>
							) }
							{/*Checks if Access Level permission is on the page*/ }
							{ (unemploymentAccessLevel !== undefined) ? (
								<>
									{ (unemploymentEditAccessLevel || unemploymentViewOnlyAccessLevel) && (
										<StateUnemployment
											accessLevel={ unemploymentAccessLevel }
											editPermission={ unemploymentEditAccessLevel }
											isAdminUser={ isAdminUser }
											isPayrollAdminUser={ isPayrollAdminUser }
											onLocationChange={ onStateUnemploymentLocationChange }
											sectionNote={ stateUnemploymentSectionNote }
											showLocationRequiredAsterisk={ showStateUnemploymentLocationRequiredAsterisk }
											showUnemployment={ showStateUi }
											viewOnly={ unemploymentViewOnlyAccessLevel }
											{ ...stateUnemployment }
										/>
									) }
								</>
							) : (
								<>
									{ (renderStateUnemployment && (isAdminUser || isPayrollAdminUser || canAccessStateUiFields) && stateUnemployment) && (
										<StateUnemployment
											isAdminUser={ isAdminUser }
											isPayrollAdminUser={ isPayrollAdminUser }
											onLocationChange={ onStateUnemploymentLocationChange }
											sectionNote={ stateUnemploymentSectionNote }
											showLocationRequiredAsterisk={ showStateUnemploymentLocationRequiredAsterisk }
											showUnemployment={ showStateUi }
											viewOnly={ statePerms === 1 }
											{ ...stateUnemployment }
										/>
									) }
								</>
							) }
						</>
					)
				}
				{/*Can be removed when feAccessLevelsQuickForms toggle is removed*/ }
				{
					!feAccessLevelsQuickForms && (
						<>
							{ (renderState && statePerms > 0 && state) &&
								<>
									{ !newIowaStateForm ? (
										<StateWithholding
											editPermission={ statePerms === 3 }
											handleNewIowaFormToggleClick={ () => setNewIowaStateForm(true) }
											isAdminUser={ isAdminUser }
											isPayrollAdminUser={ isPayrollAdminUser }
											newHirePacket={ newHirePacket }
											onLocationChange={ onStateLocationChange }
											sectionNote={ stateSectionNote }
											selectedLocation={ selectedStateLocation }
											setSelectedStateLocation={ setSelectedStateLocation }
											setShowIowaStateMessage={ setShowIowaStateMessage }
											showIowaStateMessage={ showIowaStateMessage && isNewIowaFormAccessEnabled }
											showLocationRequiredAsterisk={ showStateLocationRequiredAsterisk }
											showWithholding={ showStateWithholding }
											viewOnly={ (statePerms === 1) || isNhpPreview }
											{ ...state }
										/>
									) : (
										<IowaStateWithholding
											editPermission={ statePerms === 3 }
											isAdminUser={ isAdminUser }
											isPayrollAdminUser={ isPayrollAdminUser }
											newHirePacket={ newHirePacket }
											onLocationChange={ onStateLocationChange }
											sectionNote={ stateSectionNote }
											selectedLocation={ selectedStateLocation }
											setNewIowaStateForm={ setNewIowaStateForm }
											setSelectedStateLocation={ setSelectedStateLocation }
											showLocationRequiredAsterisk={ showStateLocationRequiredAsterisk }
											showWithholding={ showStateWithholding }
											viewOnly={ (statePerms === 1) || isNhpPreview }
											{ ...state }
										/>
									) }
								</>
							}
							{ (renderStateUnemployment && (isAdminUser || isPayrollAdminUser || canAccessStateUiFields) && stateUnemployment) && (
								<StateUnemployment
									isAdminUser={ isAdminUser }
									isPayrollAdminUser={ isPayrollAdminUser }
									onLocationChange={ onStateUnemploymentLocationChange }
									sectionNote={ stateUnemploymentSectionNote }
									showLocationRequiredAsterisk={ showStateUnemploymentLocationRequiredAsterisk }
									showUnemployment={ showStateUi }
									viewOnly={ statePerms === 1 }
									{ ...stateUnemployment }
								/>
							) }
						</>
					)
				}
				{/*End Code to be removed*/ }
			</Fragment>
		);
	}
}
