import Ajax from '@utils/ajax';

export const DEFAULT_ERROR = $.__('Whoops... No worries. Please fix any missing or incorrect information and try again.');

export function validateAccountData(accounts) {
	let valid = true;
	let error = DEFAULT_ERROR;
	const lastIndex = accounts.length - 1;

	accounts.forEach((account, index) => {
		if (
			account.flatOrPercent === 'PCT' &&
			Number(stripSymbolsFromAmount(account.amount)) === 100 &&
			index !== lastIndex
		) {
			valid = false;
			error = $.__('Oops... Only your last account can have 100%s of the remaining amount deposited into it.', '%');
		}
		if (!account.order) {
			valid = false;
		}
		if (!account.amount) {
			valid = false;
		}
		if (!account.accountNum) {
			valid = false;
		}
		if (!account.routingNum) {
			valid = false;
		}
		if (!account.accountType) {
			valid = false;
		}
		if (!account.bankName) {
			valid = false;
		}
		if (!account.flatOrPercent) {
			valid = false;
		}
		if (account.routingNumError) {
			valid = false;
		}
	});

	return {valid, error};
}

export function formatAccountsToEdit(accounts, accountsHaveUnmaskedAccountNumbers) {
	const accountsToEdit = [];

	accounts.forEach((account) => {
		accountsToEdit.push({
			order: account.order,
			accountId: account.accountId,
			amount: account.amount,
			accountNum: accountsHaveUnmaskedAccountNumbers ? account.unmaskedAccountNumber : account.number,
			routingNum: account.routingNumber,
			accountType: account.type === 'savings' ? 'Savings' : 'Checking',
			flatOrPercent: (account.calculationType === 'Percent' || account.calculationType === 'Balance') ? 'PCT' : 'FLAT',
			bankName: account.name,
			imgUrl: account.imageUrl === '/images/bank-logos/default-bank.png' ? null : account.imageUrl,
			routingNumPending: false,
			routingNumError: false,
			showDelete: true,

		});
	});

	return accountsToEdit;
}

export function formatSaveData(accounts) {
	const formattedAccounts = [];

	accounts.forEach((account) => {
		formattedAccounts.push({
			order: account.order,
			account_id: account.accountId > 0 ? account.accountId : null,
			amount: stripSymbolsFromAmount(account.amount),
			number: account.accountNum,
			routing_number: account.routingNum,
			type: account.accountType,
			name: account.bankName,
			image_url: account.imgUrl,
			flat_or_percent: account.flatOrPercent,
		});
	});

	return formattedAccounts;
}

export const stripSymbolsFromAmount = amount => amount.replace('%', '').replace(',', '').replace('$', '');

export function getUnmaskedAccountNums(accounts, customUrl) {
	let updatedAccounts = [];

	accounts.forEach((account) => {
		const accountPromise = new Promise((resolve, reject) => {
			if (account.accountId < 0) {
				resolve(account);
			} else {
				const url = customUrl ? `${ customUrl }${ account.accountId }` : `/payroll/direct_deposit/unmasked_account_number/${ account.accountId }`;
				Ajax.get(url)
					.then((response) => {
						account.accountNum = response.data.accountNumber;
						resolve(account);
					})
					.catch(response => reject(response));
			}
		});

		updatedAccounts.push(accountPromise);
	});

	return updatedAccounts;
}

export const togglePaperCheckMsg = account => account.flatOrPercent === 'PCT' && Number(stripSymbolsFromAmount(account.amount)) === 100;

export function createNewAccount(id) {
	return {
		showDelete: true,
		accountNum: '',
		routingNum: '',
		routingNumPending: false,
		routingNumError: false,
		accountType: null,
		flatOrPercent: 'PCT',
		amount: '',
		accountId: id ? id : -1,
		bankName: null,
		imgUrl: null,
	};
}

const NUMBERS_REGEX = /^[0-9-]*$/;
export function accountNumIsValid(number) {
	return NUMBERS_REGEX.test(number) && number.length <= 17;
}
