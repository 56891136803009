import {
	BodyText,
	Card,
	CardContent,
	CardSize, Flex,
	FullScreenModal,
	Icon,
	Link,
	StyledBox,
	Svg,
	WithStyles,
	withStyles,
} from '@bamboohr/fabric';
import { PureComponent, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';
import { HashRouter, Route, Switch, withRouter } from 'react-router-dom';
import { PreviewerFooter } from '../PreviewerFooter';

import { HeaderNavigation } from '../HeaderNavigation';

import { styles } from './styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

type State = {
	modalState: boolean;
	currentPage: number;
	headerName: string;
	loading?: boolean;
};

type Props = State &
	WithStyles<typeof styles> &
	RouteComponentProps<any> &
	PageData & {
		logo: Logo;
		withFinishedFooter: boolean;
		renderHeader: (props: { closeModal: () => void; headerAction: () => void }) => ReactElement;
		headerAction: () => void;
		isNHPT: boolean;
	};

type PageData = {
	whenAndWhereComponent: ReactElement;
	newEmployeeInfoComponent: ReactElement;
	getIntroducedComponent?: ReactElement;
	showGetIntroduced?: boolean;
	tasksComponent?: ReactElement;
	showTasks?: boolean;
	finishedComponent: ReactElement;
};

const privacyLink = 'https://www.bamboohr.com/privacy-policy';
const tosLink = 'https://www.bamboohr.com/terms-of-service';
export const PREVIEWER_MODAL_ID = 'PreviewerModal';

class PreviewerComponent extends PureComponent<Props, State> {
	pages: Array<any>;

	currentPage: number;

	constructor(props: Props) {
		super(props);

		const {
			loading = true,
			modalState,
			headerName,
			whenAndWhereComponent,
			newEmployeeInfoComponent,
			getIntroducedComponent,
			tasksComponent,
			finishedComponent,
			showGetIntroduced,
			showTasks,
		} = props;

		this.pages = this._populatePages({
			whenAndWhereComponent,
			newEmployeeInfoComponent,
			getIntroducedComponent,
			showGetIntroduced,
			tasksComponent,
			showTasks,
			finishedComponent,
		});

		let startingPage = 0;
		this.pages.forEach((page, ind) => {
			page.index = ind;
			if (location.hash.includes(page.route)) {
				startingPage = ind;
			}
		});

		this.state = {
			loading,
			modalState,
			currentPage: startingPage,
			headerName,
		};

		this._onRequestClose = this._onRequestClose.bind(this);
		this._nextPage = this._nextPage.bind(this);
		this._prevPage = this._prevPage.bind(this);
		this._changePage = this._changePage.bind(this);
		this._getPageNames = this._getPageNames.bind(this);
		this._resetPreview = this._resetPreview.bind(this);
	}

	_populatePages(pageData: PageData) {
		const pages = [];
		const {
			whenAndWhereComponent,
			newEmployeeInfoComponent,
			getIntroducedComponent,
			showGetIntroduced,
			tasksComponent,
			showTasks,
			finishedComponent,
		} = pageData;

		if (whenAndWhereComponent) {
			pages.push({
				name: $.__('When & Where'),
				route: 'when_and_where',
			});
		}
		if (newEmployeeInfoComponent) {
			pages.push({
				name: $.__('New Employee Info'),
				route: 'new_employee_info',
			});
		}
		if (getIntroducedComponent && showGetIntroduced) {
			pages.push({
				name: $.__('Get Introduced'),
				route: 'get_introduced',
			});
		}
		if (tasksComponent && showTasks) {
			pages.push({
				name: $.__('Tasks to Complete'),
				route: 'tasks_to_complete',
			});
		}
		if (finishedComponent) {
			pages.push({
				name: $.__('Finished'),
				route: 'finished',
			});
		}

		return pages;
	}

	_onRequestClose(): void {
		this.setState({
			modalState: false,
			loading: true,
			currentPage: 0,
		});

		window.previewUser = null;

		const { history } = this.props;

		history.replace({
			hash: '',
			search: window.location.search,
		});
	}

	_resetPreview(): void {
		window.ASSUMED_USER = undefined;
		this.setState({
			currentPage: 0,
		});
	}

	_nextPage() {
		let { currentPage } = this.state;
		this._changePage(++currentPage);
	}

	_prevPage() {
		let { currentPage } = this.state;
		this._changePage(--currentPage);
	}

	_changePage(page: number): void {
		if (page > -1 && page < this.pages.length) {
			this.setState({ currentPage: page });
			const { history } = this.props;
			history.push({
				hash: `preview/${this.pages[page].route}`,
				search: window.location.search,
			});
		}
	}

	_getPageNames(): Array<string> {
		return this.pages.map((page) => page.name.replace(/([A-Z])/g, ' $1').trim());
	}

	componentDidUpdate(prevProps: Readonly<Props>) {
		const {
			whenAndWhereComponent,
			newEmployeeInfoComponent,
			getIntroducedComponent,
			showGetIntroduced,
			tasksComponent,
			showTasks,
			finishedComponent,
		} = this.props;

		this.pages = this._populatePages({
			whenAndWhereComponent,
			newEmployeeInfoComponent,
			getIntroducedComponent,
			showGetIntroduced,
			tasksComponent,
			showTasks,
			finishedComponent,
		});

		let activePage = 0;
		this.pages.forEach((page, ind) => {
			page.index = ind;
			if (location.hash.includes(page.route)) {
				activePage = ind;
			}
		});

		const { modalState: prevModalOpen, loading: prevIsLoading } = prevProps;

		const { modalState: modalOpen, loading: isLoading } = this.props;

		let modalStatus = prevModalOpen;
		let loading = prevIsLoading;
		if (modalOpen !== prevModalOpen) {
			modalStatus = modalOpen;
		}

		if (isLoading !== prevIsLoading) {
			loading = isLoading;
		}

		window.ASSUMED_USER = modalStatus ? 1 : undefined;

		this.setState({
			loading,
			modalState: modalStatus,
			currentPage: activePage,
		});
	}

	render(): ReactElement {
		const {
			classes,
			isNHPT = false,
			logo,
			whenAndWhereComponent,
			newEmployeeInfoComponent,
			getIntroducedComponent,
			tasksComponent,
			finishedComponent,
			headerAction,
			renderHeader,
		} = this.props;

		const { modalState, currentPage, loading = false } = this.state;

		const headerProps = {
			closeModal: this._onRequestClose,
			headerAction,
		};
		const header = renderHeader(headerProps);
		const footer = (
			<PreviewerFooter
				currentPage={currentPage}
				nextPageAction={this._nextPage}
				previousPageAction={this._prevPage}
				totalPages={this.pages.length}
			/>
		);

		const onLastPage = currentPage === this.pages.length - 1;

		return (
			<FullScreenModal
				id={PREVIEWER_MODAL_ID}
				isLoading={loading}
				isOpen={modalState}
				onCloseComplete={this._resetPreview}
				onOpenComplete={() => {
					window.previewUser = true;
					window.closeMessage();
				}}
				onRequestClose={this._onRequestClose}
			>
				{ifFeature(
					'encore',
					// Encore Component
					<div className={classes.previewerContent}>
						<FullScreenModal.Body noPadding={true} renderFooter={footer} renderHeader={header}>
							<StyledBox
								backgroundColor='neutral-forced-white'
								height={onLastPage ? undefined : '100%'}
								padding='0 40px'>
								<StyledBox backgroundColor='neutral-extra-extra-weak' borderRadius='large' height='100%' overflow='auto'>
									<StyledBox padding='32px 40px'>
										{!location.hash.includes('finished') && (
											<HeaderNavigation currentPage={currentPage} pageNames={this._getPageNames()} />
										)}
										<div className={`MainPreviewerBody ${classes.containerWrapper}`}>
											<div className={classes.container}>
												<HashRouter basename='/preview' hashType='noslash' key={`${currentPage}_${loading}`}>
													<Switch>
														<Route path='/when_and_where*'>
															<Card hasBottomGradient={false} size={CardSize.CUSTOM}>
																<CardContent>
																	<div>{whenAndWhereComponent}</div>
																</CardContent>
															</Card>
														</Route>
														<Route path='/new_employee_info*'>
															<div className={classes.contentSpacer}>{newEmployeeInfoComponent}</div>
														</Route>
														<Route path='/get_introduced*'>
															<Card hasBottomGradient={false} size={CardSize.CUSTOM}>
																<CardContent>
																	<div className={classes.contentSpacer}>{getIntroducedComponent}</div>
																</CardContent>
															</Card>
														</Route>
														<Route path='/tasks_to_complete*'>
															<Card hasBottomGradient={false} size={CardSize.CUSTOM}>
																<CardContent>
																	<div className={classes.contentSpacer}>{tasksComponent}</div>
																</CardContent>
															</Card>
														</Route>
														<Route path='/finished*'>{finishedComponent}</Route>
														<Route>
															<Card hasBottomGradient={false} size={CardSize.CUSTOM}>
																<CardContent>
																	<div className={classes.contentSpacer}>{whenAndWhereComponent}</div>
																</CardContent>
															</Card>
														</Route>
													</Switch>
												</HashRouter>
											</div>
										</div>
									</StyledBox>
								</StyledBox>
							</StyledBox>
							{onLastPage && (
								<StyledBox backgroundColor='neutral-forced-white' borderRadius='medium' padding='20px 40px 40px 40px'>
									<Flex justifyContent='space-between'>
										<BodyText size='extra-small'>
											<Link color='muted' href={privacyLink} rel='noopener noreferrer' target='_blank'>
												Privacy Policy
											</Link>{' '}
											·{' '}
											<Link color='muted' href={tosLink} rel='noopener noreferrer' target='_blank'>
												Terms of Service
											</Link>{' '}
											· ©2008–2020 BambooHR All rights reserved.
										</BodyText>
										<div>
											<Link color='muted' href='https://www.bamboohr.com' rel='noopener external nofollow noreferrer' target='_blank'>
												<Svg height='17px' image='bamboohr-logo' width='115px' />
											</Link>
										</div>
									</Flex>
								</StyledBox>
							)}
						</FullScreenModal.Body>
					</div>,
					// Jade Component
					<div className={classes.previewerContent}>
						<FullScreenModal.Body renderFooter={footer} renderHeader={header}>
							<FullScreenModal.Constraint
								spacingOverrides={{
									bottom: FullScreenModal.Constraint.Spacing.NONE,
									left: FullScreenModal.Constraint.Spacing.NONE,
									right: FullScreenModal.Constraint.Spacing.NONE,
								}}
							>
								<div className={`LogoWrapper ${classes.header}`}>
									<div className={classes.subHeader}>
										<div className={`${classes.logoContainer} ${classes.contentSpacer}`}>
											<img
												alt={logo.alt}
												className={logo.classes}
												src={logo.logoSrc}
												style={{ maxHeight: '60px', maxWidth: '300px', height: 'auto', width: `${logo.width}px` }}
											/>
											<div className={classes.spacer}> </div>
										</div>
									</div>

									{!location.hash.includes('finished') && (
										<HeaderNavigation currentPage={currentPage} pageNames={this._getPageNames()} />
									)}
								</div>
								<div className={`MainPreviewerBody ${classes.containerWrapper}`}>
									<div className={classes.container}>
										<HashRouter basename='/preview' hashType='noslash' key={`${currentPage}_${loading}`}>
											<Switch>
												<Route path='/when_and_where*'>
													<Card hasBottomGradient={false} size={CardSize.CUSTOM}>
														<CardContent>
															<div className={classes.contentSpacer}>{whenAndWhereComponent}</div>
														</CardContent>
													</Card>
												</Route>
												<Route path='/new_employee_info*'>
													{ifFeature(
														'encore',
														<div className={classes.contentSpacer}>{newEmployeeInfoComponent}</div>,
														<Card hasBottomGradient={false} size={CardSize.CUSTOM}>
															<CardContent>
																<div className={classes.contentSpacer}>{newEmployeeInfoComponent}</div>
															</CardContent>
														</Card>
													)}
												</Route>
												<Route path='/get_introduced*'>
													<Card hasBottomGradient={false} size={CardSize.CUSTOM}>
														<CardContent>
															<div className={classes.contentSpacer}>{getIntroducedComponent}</div>
														</CardContent>
													</Card>
												</Route>
												<Route path='/tasks_to_complete*'>
													<Card hasBottomGradient={false} size={CardSize.CUSTOM}>
														<CardContent>
															<div className={classes.contentSpacer}>{tasksComponent}</div>
														</CardContent>
													</Card>
												</Route>
												<Route path='/finished*'>{finishedComponent}</Route>
												<Route>
													<Card hasBottomGradient={false} size={CardSize.CUSTOM}>
														<CardContent>
															<div className={classes.contentSpacer}>{whenAndWhereComponent}</div>
														</CardContent>
													</Card>
												</Route>
											</Switch>
										</HashRouter>
									</div>
								</div>
								{onLastPage && (
									<div
										className={`BasicPreviewerFooter ${classes.baseFooter} ${classes.baseFooter}${
											isNHPT ? '--templatePreviewer' : ''
										}`}
									>
										<hr className={classes.footerDivider} />
										<div className={classes.baseFooterContent}>
											<span>
												<a href={privacyLink} rel='noopener noreferrer' target='_blank'>
													Privacy Policy
												</a>{' '}
												·{' '}
												<a href={tosLink} rel='noopener noreferrer' target='_blank'>
													Terms of Service
												</a>{' '}
												· ©2008–2020 BambooHR All rights reserved.
											</span>
											<div className={classes.baseFooterLogo}>
												<a href='https://www.bamboohr.com' rel='noopener external nofollow noreferrer' target='_blank'>
													<Icon name='fab-bamboohr-logo-115x17' />
												</a>
											</div>
										</div>
									</div>
								)}
							</FullScreenModal.Constraint>
						</FullScreenModal.Body>
					</div>
				)}
			</FullScreenModal>
		);
	}
}

const Previewer = withRouter(withStyles(styles)(PreviewerComponent));

export { Previewer };
